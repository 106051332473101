.error-message {
    text-align: center;
    color: #545cd8;
}

.readonly {
    pointer-events: none;
}

.propose-content-title label.request-content-button-disabled {
    color: gray;
}

.offer-content-popup {
    .rodal-dialog {
        top: 25%;
    }
}

.about-knowledge-popup {
    .rodal-dialog {
        width: 641px !important;
        top: 15%;
        min-height: 647px;
    }

    p.text-desc {
        font-size: 16px;
        font-family: 'SegoeUI';
        font-weight: 400;
        color: #000000;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-height: 1.5em;
        max-height: 3em;
    }

    .knowledge-library-records a.open-valuation-product {
        color: #535bd6;
        font-size: 14px;
        font-style: italic;
        font-family: 'SegoeUI';
        font-weight: 400;
        text-decoration: none;
        cursor: pointer;
    }

    .knowledge-library-records .col-md-3 {
        max-width: 358px;
        border: 1px solid #e2e2e2;
        margin: 0 13px 30px 13px;
        border-radius: 0.25rem;
        padding: 13px 13px 7px 19px;
    }

    p.date-author {
        margin-bottom: 0;
        font-size: 14px;
        color: #7b7b7b;
        font-family: 'SegoeUI';
        font-weight: 400;
    }

    .modal-wrapper {
        display: flex;
        align-items: center;
    }

    .badge-button-wrapper {
        display: flex;
        align-items: center;
        margin-top: -27px;
    }

    .badge-collections {
        width: 65%;
    }

    .save-extra .save-my-shelf-btn span {
        display: block;
        line-height: 16px;
        font-size: 14px !important;
    }

    .save-extra {
        margin-left: auto;
        width: 35%;
    }

    .save-my-shelf-btn {
        border: 1px solid #888f96 !important;
        font-size: 14px !important;
        padding: 1px 10px 5px 6px !important;
        text-align: right;
        max-width: 100%;
        width: 100%;
        background: #535bd6 !important;
        margin-top: 0px;
        line-height: 17px;
    }

    .btn-outer-line {
        background: #535bd6;
        padding: 5px;
        border-radius: 6px;
        max-width: 88px;
        width: 100%;
        height: 50px;
        border-radius: 0.25rem;
        margin-left: auto;
        margin-top: -2px;
    }

    .product-badge {
        margin-top: 34px;
        background: transparent !important;
        color: #000000;
        border: 1px solid #000000;
        font-size: 8.6px;
        padding: 3px 6px 4.42px 6.5px;
        margin-right: 5px;
        margin-top: 23px;
    }

    .about-knowledge-body.modal-body .save-extra.remove {
        padding-top: 27px;
    }

    .btn-outer-line.no-border {
        max-width: 80%;
        padding-right: 0;
        background: transparent !important;
        padding-top: 0;
        margin-top: -2px;
    }

    .btn-outer-line.no-border button.save-my-shelf-btn.btn.btn-secondary {
        background: transparent !important;
        color: #535bd6 !important;
        border: 0 !important;
        padding: 0 !important;
    }

    .knowledgeLibrary-detailsection .body_inner_content {
        padding: 29px 21px 21px 21px;
    }

    h5.modal-title {
        text-transform: none !important;
        font-size: 18px !important;
        font-family: 'SegoeUI-Semibold' !important;
        display: inline-block;
        color: #000000;
    }

    .about-knowledge-body.modal-body label {
        color: #000000;
        font-size: 15px;
        font-family: 'SegoeUI-Bold';
    }

    .about-knowledge-body-p {
        font-family: 'SegoeUI-Semibold';
        font-size: 15px !important;
        color: #000000;
        line-height: 20px;
        padding-top: 3px;
        padding-bottom: 8px;
    }

    .about-knowledge-body.modal-body .knowledge-library-records {
        margin-top: 58px;
    }

    .about-knowledge-body.modal-body {
        padding-left: 25px;
        padding-right: 35px;
    }

    .modal-content {
        border: 0 !important;
    }

    .knowledge-library-records .col-md-12 {
        max-width: 358px;
        margin: 0 auto;
        height: 199px;
        border: 1px solid #e2e2e2;
        padding: 11px 13px 4px 19px;
        border-radius: 0.25rem;
    }

    .product-name-image-wrapper h6 {
        position: relative;
        font-size: 18px;
        margin-bottom: 0;
    }

    .about-knowledge-body .modal-header {
        padding: 18px 19px;
    }

    .type-pdf-arrow {
        position: relative;
    }

    .pdf-image {
        margin-left: auto;
        color: #545cd8;
        height: 25px;
        width: 20px;
    }

    label.type-of-content {
        position: absolute;
        right: -116px;
        top: -63px;
        font-size: 16px !important;
        font-family: 'SegoeUI' !important;
        font-weight: 400 !important;
    }

    label.title-point-label {
        position: absolute;
        left: -76px;
        top: -63px;
        font-size: 16px !important;
        font-family: 'SegoeUI' !important;
        font-weight: 400 !important;
    }

    .title-arrow {
        position: relative;
    }

    img.value-arrow {
        position: absolute;
        left: -47px;
        top: -45px;
        height: auto;
        width: auto;
    }

    img.type-arrow {
        position: absolute;
        right: -47px;
        top: -45px;
    }

    .posting-arrow {
        position: relative;
    }

    label.type-of-content {
        font-size: 16px !important;
        font-family: 'SegoeUI' !important;
        font-weight: 400 !important;
    }

    label.posting-arrow-content {
        position: absolute;
        left: -123px;
        top: 32px;
        font-size: 16px !important;
        font-family: 'SegoeUI' !important;
        font-weight: 400 !important;
    }

    img.posting-arrow-img {
        position: absolute;
        left: -51px;
        top: -9px;
    }

    .tag-arrow-wrapper {
        position: relative;
    }

    label.tag-arrow-content {
        top: 23px;
        position: absolute;
        left: -116px;
        font-size: 16px !important;
        font-family: 'SegoeUI' !important;
        font-weight: 400 !important;
    }

    img.tag-arrow {
        position: absolute;
        left: -53px;
        top: -17px;
    }

    .remove-arrow-wrapper {
        position: relative;
    }

    label.remove-arrow-content {
        position: absolute;
        right: -93px;
        top: 51px;
        width: 184px;
        font-family: 'SegoeUI' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
    }

    img.remove-arrow {
        position: absolute;
        right: -30px;
        top: 15px;
    }

    .dec-arrow-wrapper {
        position: relative;
    }

    label.dec-arrow-content {
        position: absolute;
        right: -116px;
        top: -18px;
        font-family: 'SegoeUI' !important;
        font-weight: 400 !important;
        font-size: 16px !important;
    }

    img.dec-arrow-img {
        position: absolute;
        right: -32px;
        top: -32px;
    }

    .about-knowledge-body.modal-body .badge-collections {
        margin-top: -15px;
    }

    .modal-header {
        padding: 18px 16px 18px 20px;
    }

    .records-data {
        position: relative;
    }

    .close-about-knowledge,
    .close-about-knowledge:hover {
        margin-top: 50px !important;
        margin-right: 20px !important;
        font-size: 14px !important;
        font-family: 'SegoeUI-Semibold' !important;
        width: 63px !important;
        height: 29px !important;
    }

    .cancel-btn,
    .cancel-btn:hover {
        font-family: 'SegoeUI-Semibold';
        font-size: 15px !important;
        color: #919191;
        width: 70px;
        height: 29px;
        border: 1px solid #919191;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .close-about-knowledge:not(:disabled):not(.disabled):active,
    .close-about-knowledge:not(:disabled):not(.disabled).active,
    .show > .close-about-knowledge.dropdown-toggle {
        color: #919191 !important;
        background-color: transparent !important;
        border-color: #919191 !important;
    }

    button.close {
        padding: 5px 17px !important;
    }

    button.cancel-btn.close-about-knowledge.btn.btn-secondary {
        margin-top: 50px !important;
        margin-right: 20px !important;
        margin-bottom: 20px;
        font-size: 14px !important;
        font-family: 'SegoeUI-Semibold' !important;
        width: 63px !important;
        height: 29px !important;
        border-radius: 3px;
    }

    button.cancel-btn.btn.btn-secondary {
        margin-left: auto;
        margin-right: 6px;
        margin-top: 21px;
    }

    @media (max-width: 650px) {
        max-width: 95% !important;
        width: 100%;
        min-height: auto;

        label.type-of-content,
        label.dec-arrow-content,
        label.title-point-label,
        label.posting-arrow-content,
        label.tag-arrow-content,
        label.remove-arrow-content {
            font-size: 12px !important;
        }

        .cancel-btn {
            margin-bottom: 25px;
        }

        label.type-of-content {
            right: -49px;
            top: -60px;
            font-size: 10px !important;
        }

        img.type-arrow {
            right: -24px;
            top: -34px;
            width: 26px;
            height: 23px;
        }

        img.value-arrow {
            position: absolute;
            left: -22px;
            top: -34px;
            height: 23px;
            width: 23px;
        }

        label.title-point-label {
            left: -32px;
            top: -47px;
        }

        img.remove-arrow {
            right: 15px;
            top: 15px;
            height: 23px;
            width: 23px;
        }

        label.remove-arrow-content {
            right: -48px;
            top: 38px;
            width: 159px;
        }

        img.dec-arrow-img {
            right: -15px;
            top: -41px;
            height: 21px;
            width: 24px;
        }

        label.tag-arrow-content {
            left: -75px;
        }

        label.dec-arrow-content {
            right: -52px;
            font-size: 10px !important;
        }

        img.posting-arrow-img {
            position: absolute;
            left: -24px;
            top: -16px;
            width: 23px;
            height: 23px;
        }

        label.posting-arrow-content {
            left: -44px;
            width: 16%;
            top: 9px;
        }

        img.tag-arrow {
            position: absolute;
            left: -27px;
            top: -17px;
            width: 23px;
            height: 23px;
        }

        label.tag-arrow-content {
            left: -41px;
            top: 10px;
        }

        .knowledge-library-records .col-md-12 {
            max-width: 320px;
            height: 200px;
        }

        .product-badge {
            margin-top: 0;
        }

        .badge-collections {
            width: 50%;
            margin-top: 6px !important;
        }

        h5.modal-title {
            font-size: 16px !important;
        }
    }

    @media (max-width: 576px) {
        .about-knowledge-popup {
            label.type-of-content,
            label.dec-arrow-content,
            label.title-point-label,
            label.posting-arrow-content,
            label.tag-arrow-content,
            label.remove-arrow-content {
                font-size: 10px !important;
            }

            label.type-of-content {
                right: -49px;
                top: -60px;
                font-size: 10px !important;
            }

            img.type-arrow {
                right: -24px;
                top: -34px;
                width: 26px;
                height: 23px;
            }

            img.value-arrow {
                position: absolute;
                left: -22px;
                top: -34px;
                height: 23px;
                width: 23px;
            }

            label.title-point-label {
                left: -32px;
                top: -47px;
            }

            img.remove-arrow {
                right: 15px;
                top: 15px;
                height: 23px;
                width: 23px;
            }

            label.remove-arrow-content {
                right: -48px;
                top: 38px;
                width: 159px;
            }

            img.dec-arrow-img {
                right: -15px;
                top: -41px;
                height: 21px;
                width: 24px;
            }

            label.tag-arrow-content {
                left: -75px;
            }

            label.dec-arrow-content {
                right: -52px;
                font-size: 10px !important;
            }

            img.posting-arrow-img {
                position: absolute;
                left: -24px;
                top: -16px;
                width: 23px;
                height: 23px;
            }

            label.posting-arrow-content {
                left: -44px;
                width: 16%;
                top: 9px;
            }

            img.tag-arrow {
                position: absolute;
                left: -27px;
                top: -17px;
                width: 23px;
                height: 23px;
            }

            label.tag-arrow-content {
                left: -41px;
                top: 10px;
            }

            .knowledge-library-records .col-md-12 {
                max-width: 320px;
                height: 200px;
            }

            .product-badge {
                margin-top: 0;
            }

            .badge-collections {
                width: 50%;
                margin-top: 6px !important;
            }
        }
    }

    @media (max-width: 414px) {
        .about-knowledge-popup {
            .knowledge-library-records {
                margin-top: 30px;
            }

            .knowledge-library-records {
                margin-top: 30px;
            }

            .knowledge-library-records .col-md-12 {
                max-width: 240px;
                height: auto;
            }

            .about-knowledge-body.modal-body .knowledge-library-records {
                margin-top: 30px;
            }

            label.posting-arrow-content {
                left: -36px;
            }

            label.tag-arrow-content {
                left: -36px;
                top: 10px;
            }

            label.type-of-content {
                right: -36px !important;
                top: -58px !important;
            }

            img.remove-arrow {
                right: 0px;
                top: -10px;
                height: 23px;
                width: 23px;
            }

            img.dec-arrow-img {
                right: -12px;
                top: -41px;
                height: 21px;
                width: 24px;
            }

            label.dec-arrow-content {
                right: -38px !important;
            }

            label.remove-arrow-content {
                right: -39px;
                top: 14px;
                width: 140px;
            }

            p.text-desc {
                font-size: 15px;
                line-height: 21.68px;
            }

            .btn-outer-line.no-border {
                max-width: 100%;
            }

            .save-extra {
                margin-left: auto;
                width: 50%;
            }

            .badge-button-wrapper {
                display: flex;
                align-items: flex-end;
                margin-top: 0;
            }

            .badge-collections {
                margin-top: 10px !important;
            }

            .btn-outer-line.no-border {
                padding-top: 23px !important;
            }

            button.cancel-btn.close-about-knowledge.btn.btn-secondary {
                margin-top: 30px !important;
            }
        }
    }
}

.kb-lib-bread-crumb-wrapper {
    display: grid;
    grid-template-columns: max-content 1fr;

    .about-kb-lib-title label,
    .propose-content-title label {
        cursor: pointer;
    }

    .kb-lib-title-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-items: end;
    }

    .about-kb-lib-title {
        color: #535bd6;
        text-transform: none !important;
        font-size: 20px;
        font-family: 'SegoeUI-Semibold';
        display: inline-block;
        font-style: italic;
    }

    .propose-content-title {
        display: flex;
        flex-direction: column;
        color: #535bd6;
        text-transform: none !important;
        font-size: 18px;
        font-family: 'SegoeUI-Semibold';
        font-style: italic;

        .place-left .arrow {
            background: transparent;
            transform: rotate(90deg);
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;

            border-bottom: 10px solid #cdcdcd;
            right: -15px !important;
        }
    }

    @media (max-width: 1800px) {
        .kb-lib-title-wrapper {
            margin-right: 0;
        }
    }

    @media (max-width: 1550px) {
        .kb-lib-title-wrapper {
            padding: 6px 0 15px 0;
            margin-left: 20px;
        }
    }

    @media (max-width: 576px) {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: minmax(min-content, 1fr) min-content;

        .kb-lib-title-wrapper {
            margin-left: 0;
            grid-row-start: 2;
        }
    }
}

.kb-content-library {
    .no-content-label {
        color: #888f96;
        font-size: 13px !important;
        font-family: 'SegoeUI-Semibold';
        line-height: 17.5px;
        vertical-align: middle;
        background-color: #f7f7f7;
        padding: 12px 15px;
    }

    .knowledgeLibraryMainSSection {
        overflow: hidden;
    }

    .sortByDate.browseBy {
        margin-right: 3px;

        select {
            padding-right: 25px !important;
        }
    }

    .info-tooltip {
        margin-right: 5px;
    }

    .sortByDate {
        display: flex;
        align-items: center;
        position: relative;
    }

    .sortByDate .customerStyle select {
        background: transparent;
        padding: 1px 15px 3px 12px;
        border: 0;
        border-radius: 0;
        height: 30px !important;
        width: 113px !important;
    }

    .sortByDate label {
        color: #525a64;
        font-size: 13px !important;
        font-family: 'SegoeUI-Semibold';
        line-height: 17.5px;
        vertical-align: middle;
    }

    .sortByDate .customerStyle {
        background-position: calc(100% - 10px);
        background-repeat: no-repeat;
        border: 1px solid #dce0e4 !important;
        outline: 0;
        height: 30px !important;
        width: 113px !important;
        border-radius: 0.25rem;
        overflow: hidden;
        cursor: pointer;
        margin-left: 13px;
    }

    .allTopics.customerStyle {
        background-position: calc(100% - 10px);
        background-repeat: no-repeat;
        border: 1px solid #dce0e4 !important;
        outline: 0;
        height: 30px !important;
        width: 179.99px !important;
        border-radius: 0.25rem;
        overflow: hidden;
        cursor: pointer;
        margin-left: 13px;
    }

    .allTopics.customerStyle select {
        width: 100% !important;
    }

    .browseHistorysearchWrapper label {
        margin-bottom: 1px;
    }

    .browseHistorysearchWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 23px 12px 22px;
        border-bottom: 1px solid #e5e5e5;
    }

    img.lineTwo {
        padding-left: 4px;
    }

    .borderOne.searchBoxLine {
        padding-left: 2px;
    }

    .browseTitle {
        display: flex;
        text-transform: uppercase !important;
        font-size: 14px;
        font-family: 'SegoeUI-Bold';
        margin-bottom: 0;
        color: #000000;
    }

    .browseTitle label {
        margin-bottom: -2px;
        margin-right: 5px;
    }

    .sortByDate .customerStyle {
        margin-right: 7px;
    }

    .customerStyle.alltypes {
        margin-left: 3px !important;
    }

    .info-svg {
        margin-left: 0;
        margin-right: 9px;
    }

    .sortByDate.browseBy {
        padding-left: 5px;
    }

    .clearResultBtn,
    .clearResultBtn:hover {
        min-width: 94px;
        background: #535bd6 !important;
        border-color: #535bd6 !important;
        width: 110px !important;
        height: 30px !important;
        padding: 2px 5px 3px 5px;
        margin-left: 3px;
    }

    .clearResultBtn:not(:disabled):not(.disabled):active:focus,
    .clearResultBtn:not(:disabled):not(.disabled).active:focus,
    .show > .clearResultBtn.dropdown-toggle:focus {
        box-shadow: none !important;
        background: #535bd6 !important;
        border-color: #535bd6 !important;
        color: #ffffff !important;
    }

    .searchBox input {
        width: 232px !important;
        padding: 2px 15px 3px 12px;
        padding-right: 35px;
        height: 30px !important;
        border: 1px solid #dce0e4 !important;
        outline: 0;
        color: #888f96;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
        border-radius: 0.25rem;
    }

    .searchBox {
        position: relative;
        margin-left: 11px;
    }

    .clearResultBtn {
        position: relative;
    }

    .searchBox img {
        position: absolute;
        right: 11px;
        top: 6px;
    }

    svg.svg-inline--fa.fa-info-circle.fa-w-16.input-group-text {
        padding: 0;
        border: 0;
        color: #535bd6;
        outline: 0;
        background: transparent;
        margin-left: 7px;
    }

    .popularity {
        padding-left: 5px;
    }

    .knowledgeLibrary-detailsection .body_inner_content {
        padding: 30px 21px 28px 21px;
    }

    .shelf-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .checkbox-container {
        display: flex;
        position: relative;
        padding-left: 28px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        line-height: 20px;
        color: #888f96;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
        text-transform: capitalize;
        align-items: center;

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        input:checked ~ .checkmark {
            background-color: #fff;
        }

        input:disabled ~ .checkmark:after {
            border: solid #949494;
            border-width: 0 3px 3px 0;
        }

        input:disabled ~ .checkmark {
            background-color: #f0f4f7;
        }

        input:checked ~ .checkmark:after {
            display: block;
        }

        .checkmark:after {
            left: 6px;
            top: 0px;
            width: 7px;
            height: 14px;
            border: solid #535bd6;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px !important;
        border: 1px solid #dce0e4;
        border-radius: 0.25rem;
    }

    @media (max-width: 1903px) {
        .responsiveClass {
            padding-bottom: 4px;
            background: #fff;
            background-color: #fff;
            box-shadow: 0 4px 21px 8px #12162d0d;
            margin-bottom: 30px;
            border-radius: 0.25rem;
            padding-left: 20px;
            padding-right: 20px;
        }

        .browseHistorysearchWrapper {
            width: auto !important;
            padding: 12px 0px 12px 0px;

            .sortByDate {
                width: auto !important;

                .customerStyle {
                    width: auto !important;
                    max-width: 100% !important;
                    select {
                        padding-right: 20px !important;
                    }
                }

                label {
                    margin-right: 3px !important;
                }
            }
            .sortByDate.browseBy {
                width: 25% !important;

                .customerStyle {
                    width: 100% !important;
                    max-width: 100% !important;
                }
            }

            .searchBox {
                margin-left: 6px !important;
                margin-right: 5px;
            }

            .info-tooltip {
                margin-left: -6px;
            }
        }

        .overFlowClass {
            overflow-x: auto;
        }

        .knowledgeLibraryMainSSection {
            padding-bottom: 0px;
            box-shadow: none;
        }

        .knowledgeLibraryMainSSection {
            position: relative;
        }

        .knowledgeLibraryMainSSection::-webkit-scrollbar {
            height: 6px;
            background-color: #c1c1c1;
            border-radius: 10px;
        }

        .body_white_box.knowledgeLibraryMainSSection {
            padding-bottom: 0px;
        }

        .knowledgeLibraryMainSSection::-webkit-scrollbar {
            height: 6px;
            background-color: #e5e5e5;
            border-radius: 10px;
        }

        .knowledgeLibraryMainSSection::-webkit-scrollbar {
            height: 6px;
            background-color: #e5e5e5;
            border-radius: 10px;
        }

        .knowledgeLibraryMainSSection::-webkit-scrollbar-thumb {
            background-color: #a0a0a0 !important;
            height: 6px;
            width: 6px;
            border-radius: 5px;
        }

        .dataTable {
            height: auto;
            padding: 0px 0px 0px 0px;
        }
    }

    @media (max-width: 1530px) {
        .browseHistorysearchWrapper {
            .sortByDate {
                max-width: 22%;
                .customerStyle {
                    margin-left: 0px;

                    select {
                        margin-left: 0;
                    }
                }
            }
            .sortByDate.browseBy {
                .customerStyle {
                    width: 80% !important;
                    max-width: 80% !important;
                    min-width: 40%;
                    margin-right: 0 !important;
                }
                select {
                    margin-left: -5px;
                }
            }

            .searchBox {
                margin-left: 0px !important;
            }
            .browseTitle {
                max-width: 10%;
            }
        }
    }

    @media (max-width: 1850px) {
        .sortByDate {
            width: 20%;
        }

        .searchBox {
            width: 13%;
        }
    }

    @media (max-width: 1800px) {
        .sortByDate .customerStyle select {
            width: 100% !important;
            max-width: 100% !important;
        }

        .allTopics.customerStyle,
        .sortByDate .customerStyle {
            width: 100% !important;
            max-width: 100% !important;
            margin-left: 3px;
        }

        .searchBox input {
            width: 100% !important;
            max-width: 100% !important;
            margin-left: 3px;
        }

        .clearResultBtn {
            max-width: 8% !important;
            width: 8% !important;
        }
    }

    @media (max-width: 1700px) {
        .sortByDate label {
            font-size: 11px !important;
            margin-right: 10px;
            line-height: 13px;
        }

        .allTopics.customerStyle,
        .sortByDate .customerStyle {
            width: 80% !important;
            max-width: 80% !important;
            margin-left: 3px;
        }

        .productBadge {
            margin-top: 0;
        }

        .badgeButtonwrapper {
            margin-top: 0;
            position: relative;
        }

        .saveExtra {
            width: 50%;
            margin-top: -10px;
        }
    }

    @media (max-width: 1359px) {
        .responsiveClass {
            padding-left: 17px;
            padding-right: 17px;
        }

        .browseHistorysearchWrapper {
            min-width: 1250px !important;
            overflow-x: auto;
        }

        .sortByDate {
            .customerStyle {
                margin-right: -10px;
                select {
                    padding-right: 20px;
                    margin-left: 0px !important;
                }
            }

            label {
                margin-right: 3px !important;
                margin-left: -20px !important;
            }
        }
        .sortByDate.browseBy {
            width: 20% !important;
            margin-right: -10px !important;
            margin-left: 5px;

            select {
                padding-right: 25px !important;
            }
        }
    }
    @media (max-width: 1600px) {
        .productNameImageWrapper h6 {
            font-size: 16px !important;
        }

        .date_author {
            font-size: 12px !important;
        }

        .text-desc {
            font-size: 14px !important;
        }

        .saveMySelfBtn {
            font-size: 12px !important;
            padding: 1px 7px 3px 3px !important;
        }

        .saveMySelfBtn span {
            font-size: 12px !important;
        }

        .btnOuterLine {
            height: 45px;
            max-width: 70px;
            padding: 3px !important;
        }

        .open-valuation-product {
            font-size: 12px !important;
        }

        .saveExtraText {
            font-size: 12px !important;
        }

        .browse_the_library_main {
            padding: 15px 15px 20px 20px;
        }
    }

    @media (max-width: 1359px) {
        .productNameImageWrapper h6 {
            font-size: 14px !important;
        }

        .date_author {
            font-size: 11px !important;
        }

        .text-desc {
            font-size: 12px !important;
        }

        .saveMySelfBtn {
            font-size: 12px !important;
            padding: 0px 10px 0px 3px !important;
        }

        .saveMySelfBtn span {
            font-size: 11px !important;
        }

        .btnOuterLine {
            height: 40px;
            max-width: 70px;
            padding: 3px !important;
        }

        .open-valuation-product {
            font-size: 11px !important;
        }

        .saveExtraText {
            font-size: 11px !important;
        }

        .browse_the_library_main {
            padding: 15px 15px 20px 20px;
        }
    }
}

.app-KnowledgeBaseContentManager .searchBox input::placeholder {
    color: #696a78;
}

.browse_the_library_main {
    padding: 30px 21px 30px 21px;
    overflow-x: auto;

    .file-icon {
        color: #545cd8;
        height: 25px;
        width: 20px;
    }

    .associate-members-table .body_white_box.associate_member .modal-body p {
        margin-top: 0.5em;
    }

    .body_white_box {
        margin-bottom: 0;
    }

    @media (max-width: 840px) {
        margin: 30px 0px 30px 0px;
    }

    .browse_the_library_wrapper {
        padding-bottom: 15px;

        .browse_the_library_item {
            display: grid;
            grid-auto-flow: column;
            justify-content: start;
            column-gap: 15px;
        }

        :first-child,
        :last-child {
            .content-tile {
                margin-left: 0px;
            }
        }

        .item_lib {
            border: 1px solid #dce0e4;
            padding: 15px 13px 9px 19px;
            border-radius: 0.25rem;
            margin-bottom: 30px;
            width: 358px !important;
            margin-left: 15px;
            margin-right: 9px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .item_lib.item_even {
            margin-bottom: 0px;
        }

        @media (max-width: 1359px) {
            .item_lib {
                padding: 10px 10px 9px 10px;
                margin-bottom: 15px;
                width: 290px !important;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
    }
}

.browse_the_library_main::-webkit-scrollbar {
    height: 6px;
    background-color: #e5e5e5;
    border-radius: 10px;
}

.browse_the_library_main::-webkit-scrollbar-thumb {
    background-color: #a0a0a0 !important;
    height: 6px;
    width: 6px;
    border-radius: 5px;
}

.overFlowClass::-webkit-scrollbar {
    height: 6px;
    background-color: #e5e5e5;
    border-radius: 10px;
}

.overFlowClass::-webkit-scrollbar-thumb {
    background-color: #a0a0a0 !important;
    height: 6px;
    width: 6px;
    border-radius: 5px;
}

.badgeCollections.badgewrapper span {
    font-family: 'SegoeUI-Bold';
    display: inline-block;
}

.myShelfSection {
    position: relative;
    padding-bottom: 1px;

    .info-svg {
        margin-left: 7px;
    }

    .tooltip-icon {
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        font-style: normal;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 1.42857143;
        text-align: left;
        text-align: start;
        text-shadow: none;
        text-transform: none;
        white-space: normal;
        word-break: normal;
        word-spacing: normal;
        word-wrap: normal;
        font-size: 12px;
    }

    .body_header_title {
        padding: 19px 23px 18.91px 22px;
    }

    .body_inner_content {
        padding: 30px 21px 30px;
    }
}
