.app-chat-area {
    .num-of-likes {
        min-width: 60px;
        text-overflow: ellipsis;
        width: 100% !important;
        max-width: 200px !important;
    }

    .search-field.form-control {
        min-width: 60px;
        text-overflow: ellipsis;
        width: 100% !important;
        max-width: 145px !important;
    }

    .search-box {
        display: flex;
        flex-direction: row;
        position: relative;
        color: #757575 !important;
        font-size: 14px !important;
        font-family: 'SegoeUI-Semibold' !important;
        font-weight: 400 !important;
    }

    .search-box-row {
        align-items: center;
    }

    .search-box img {
        position: absolute;
        right: 11px;
        top: 6px;
    }

    .search-field {
        width: auto;
        height: 30px;
        margin-left: 8px;
        box-shadow: none;
        border: 1px solid #dce0e4 !important;
    }

    .form-control {
        padding: 2px 15px 3px 12px !important;
        color: #757575 !important;
        font-size: 14px !important;
        font-family: 'SegoeUI-Semibold' !important;
        font-weight: 400 !important;
    }

    img {
        vertical-align: middle;
        border-style: none;
    }

    .search-btn-wrapper {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding-left: 5px;
        margin-top: 5px;
    }

    .practice-title-searchbar-wrapper {
        border-bottom: 1px solid #e5e5e5;
        padding: 11.16px 19px 11.16px 19px !important;
        justify-content: space-between;
    }

    .search-box input {
        padding: 2px 15px 3px 12px;
        height: 30px !important;
        border: 1px solid #dce0e4 !important;
        outline: 0;
        color: #888f96 !important;
        font-size: 14px !important;
        font-family: 'SegoeUI-Semibold';
        border-radius: 0.25rem;
        font-weight: 400 !important;
    }

    .chat-right-side {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .data-chat {
        width: 100%;
        display: flex;
    }

    .chat-wrapper-openclose-reply {
        width: 100%;
        max-width: 100%;
    }

    .app-inner-layout__bottom-pane {
        border-bottom-right-radius: 6px !important;
    }

    >h4 {
        text-transform: uppercase;
    }

    @media screen and (max-width: 992px),
    (max-height: 550px) {
        min-height: 400px;

        .app-inner-layout__wrapper .app-inner-layout__content {
            display: block !important;
        }

        .chat-card {
            position: relative;
            display: flex;
            order: 2;
        }

        .app-inner-layout__top-pane.practice-title-searchbar-wrapper {
            display: block !important;
            padding-bottom: 15px !important;
        }

        .app-inner-layout__top-pane.practice-title-searchbar-wrapper .pane-left {
            padding: 8px 0 0px 5px;
        }

        .data-chat {
            .app-inner-layout__bottom-pane.d-block.text-center {
                max-width: 100% !important;
                width: 100% !important;
                z-index: 11;
                background-color: #ffffff;
            }

            .chat-wrapper {
                height: 60vh;
            }
        }
    }

    @media (max-width: 1070px) {
        min-height: 230px;

        .search-box {
            input {
                width: 100% !important;
            }
        }
    }
}

@media (max-width: 1600px) {
    .app-chat-area {

        .practice-title-searchbar-wrapper {
            padding-block: 8px !important
        }
    }
}