.marketplace-opportunity-documents {
    margin-top: 15px;

    .document-name-column {
        min-width: 250px;
    }

    .document-date-posted-column {
        min-width: 100px;
    }

    .opportunity-document-name {
        color: #545cd8;
        text-decoration: underline;
        cursor: pointer;
    }

    .highlighted-dark {
        animation: highlight-dark 2s linear;
    }

    .highlighted {
        animation: highlight 2s linear;
    }

    @keyframes highlight-dark {
        from {
            background-color: #e6e8ff;
        }
        to {
            background-color: #f7f7f7;
        }
    }

    @keyframes highlight {
        from {
            background-color: #e6e8ff;
        }
        to {
            background-color: white;
        }
    }
}
