.app-messages {
    padding: 0 0 0 15px;
    width: 100%;
    max-height: 100vh;
    overflow-y: scroll;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .person-name-placeholder {
        height: 1em;
    }

    .avatar-icon {
        border: transparent solid 3px !important;
    }

    .hightlighted-message {
        background-color: #e6e8ff !important;
    }

    .fit-content {
        flex-basis: fit-content;
    }

    .like-block {
        min-width: 45px !important;
        height: 26px !important;
        text-align: center;
        margin-left: 10px;
        display: flex;
        flex-direction: row-reverse;
        top: -30px;
        cursor: pointer;
        background-color: white;
        border: 1px solid;
        border-radius: 5rem;
        align-items: center;
        justify-content: center;
        position: absolute;
    }

    .left-offset {
        left: -72px;
    }

    .right-offset {
        right: -62px;
    }

    .gift-card-wrapper {
        padding: 0.5em;
    }

    .attached-file-wrapper {
        padding-block: 18px !important;
        padding-inline: 17px !important;
        margin-top: 5px;
        background: #e1e1e1 !important;
    }

    .attachments-wrapper {
        .attachments:not(:first-child) {
            margin-top: 15px;
        }

        .content-tile {
            background-color: #fff;
            margin: 0 0 5px 0;

            @media (max-width: 560px) {
                max-width: 32vw;

                .badgeButtonwrapper {
                    flex-direction: column;
                    align-items: flex-start;
                }

                .saveExtra {
                    position: relative;
                    margin-top: 1em;
                    width: 100%;

                    .btnOuterLine {
                        margin-left: 0;
                        height: auto;
                        width: 100% !important;
                    }
                }
            }
        }
    }

    .attachments {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .attached-file-owner {
        @extend .attached-file;
        align-self: end;
    }

    .attached-file {
        background: #f4f4f4;
        display: flex;
        justify-content: space-between;
        max-width: 300px;
        width: 100%;
        text-align: left;
        font-size: 14px;
        font-family: 'SegoeUI';
        font-weight: 400;
        color: #272727;
        align-items: center;
        cursor: pointer;

        .file-icon {
            color: #545cd8;
            height: 31px;
            width: 28px;
        }

        .file-name-container span.file-size {
            display: block;
            color: #7b7b7b !important;
            padding-top: 4px;
        }

        .file-name-container {
            margin-top: -4px;
            margin-inline-start: 5px;
            margin-inline-end: 0.75em;
        }

        .file-name {
            max-width: 190px;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            overflow: hidden;
            line-break: anywhere;
        }

        .download-file {
            padding-left: 3px;
            padding-top: 4px;
        }

        .file-icon-container {
            padding-right: 8px;
        }
    }

    .overflow-container {
        overflow-y: auto;
    }

    .overflow-container::-webkit-scrollbar {
        width: 6px;
        background-color: #c1c1c157;
    }

    .overflow-container::-webkit-scrollbar-thumb {
        background-color: #a0a0a0;
        border-radius: 5px;
    }

    .message-box {
        display: flex;
        clear: both;
        padding: 13px 10px 13px 15px !important;
        margin-right: 5px;
        overflow-wrap: anywhere;
    }

    .message-block {
        display: flex;
        flex-direction: column;
    }

    .number-of-likes {
        font-size: 12px;
        padding-left: 5px;
    }

    .like-message-block {
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    .support-context-block {
        flex-direction: column;
    }

    .support-context-label {
        margin-bottom: 10px;
    }

    .chat-person-name {
        font-size: 14px;
        font-family: 'SegoeUI-Semibold';
        color: #000000;
        margin-bottom: 7px;
    }

    .three-dot-drop-down {
        position: absolute;
        right: -11px;
        top: 0px;
        cursor: pointer;
    }

    .chat-box {
        background: #f4f4f4;
        padding: 13px 20px !important;
        border-radius: 15px;
        display: flex;
        font-size: 14px;
        white-space: inherit;
        display: flex;
        flex-direction: column;
        max-width: 100%;

        p {
            margin-bottom: 0;
        }
    }

    .chat-box a:visited {
        color: #545cd8;
    }

    .chat-box a:hover {
        color: #000;
    }

    .chat-box-left {
        border-top-left-radius: 0;
        margin-right: 35px;
    }

    .chat-box-right {
        border-top-right-radius: 0;
        margin-right: 12px;
        margin-left: 42px;
    }

    .chat-box.chat-box-left p,
    .chat-box.chat-box-right p {
        margin-bottom: 3px;
    }

    .chat-box.chat-box-left ul,
    .chat-box.chat-box-right ul {
        margin: 0px;
        margin-bottom: 3px;
        padding: 0px 0px 0px 15px;
    }

    .chat-box.chat-box-left ol,
    .chat-box.chat-box-right ol {
        margin: 0px;
        margin-bottom: 3px;
        padding: 0px 0px 0px 15px;
    }

    .under-message {
        display: flex;
        margin: 8px 13px 0 8px;
        font-size: 14px;
        font-family: 'SegoeUI';
        color: #7b7b7b;
        font-weight: 400;
    }

    .sender-message {
        flex-direction: row-reverse;
    }

    .member-icon {
        width: 38px;
        height: 38px;
        border-radius: 19px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .member-initials {
        font-family: 'SegoeUI-Semibold';
    }

    .center-position {
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .scroll-bottom {
        width: 175px;
        height: 30px;
        border-radius: 25px;
        background: #535bd6;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 15px;
        color: white;
        box-shadow: 0px 3px 8px 1px #888888;
        cursor: pointer;
        z-index: 100;

        .scroll-bottom-text {
            font-size: 14px;
            font-family: 'SegoeUI-Bold';
            margin-right: 8px;
        }
    }

    .edit-button {
        place-self: center;
        padding-left: 1em;
    }

    .flex-row-container {
        display: flex;
    }
}

.app-messages::-webkit-scrollbar {
    display: none;
}

@media screen and (max-width: 992px),
(max-height: 550px) {
    .app-messages {
        height: 65vh;
        display: inline-table !important;
    }
}