.snapshot-popup {
    .rodal-dialog {
        max-width: 500px;
        top: 20%;
    }

    .react-bootstrap-table {
        max-height: 20rem;
    }

    .buttons-wrapper{
        display: flex;
        gap: 1rem;
    }

    .comment-input {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 1rem;
        margin: 0 1rem;
        align-items: center;

        .btn {
            white-space: nowrap;
        }
    }

    .react-bootstrap-table {
        width: 100%;
        padding: 1rem;
    }
}
