.associate-members-popup {
    .invalid-feedback {
        display: none !important;
    }

    .modal-header {
        background: #f8f9fa;
        height: 63px;
        padding: 19px 0 0 23px;
        text-transform: capitalize;
    }

    .modal-body {
        padding-top: 17px;
        padding-bottom: 0;

        p {
            font-size: 16px;
            font-family: 'SegoeUI-Semibold';
            margin-bottom: 7px;
            line-height: 20px;
            color: #000000;
        }

        p:nth-child(2) {
            padding-top: 5px;
        }

        h6 {
            font-size: 15px;
            font-family: 'SegoeUI-Bold';
            color: #000000;
            padding-top: 7px;
            padding-bottom: 0px;

            &.associate-popup {
                font-size: 17px;
            }
        }

        .col-md-4 input {
            max-width: 270px;
            height: 30px;
            border: 1px solid #dce0e4;
            padding: 13px 15px 14px;
            outline: none;
            color: #888f96;
            font-size: 13px;
            font-family: 'SegoeUI-Semibold';
        }
    }

    .modal-footer {
        background: transparent;
        margin: 0 auto;
        border-top: none !important;
        padding: 0px 0 17px 0;
        width: 100%;

        .footer-wrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding-inline: 20px;
        }

        .member-button-panel {
            display: inline-flex;
            gap: 10px;
        }

        button.btn.btn-primary {
            width: auto;
            height: 29px;
            padding: 0 12px 2px;
            border-radius: 0.25rem;
            cursor: pointer;
        }

        button.btn.btn-secondary {
            width: 71px;
            height: 29px;
            padding: 0 5px 2px 5px;
            border-radius: 0.25rem;
        }
    }

    .feature_checkbox .modal-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 1rem;
        border-top: 1px solid #dee2e6;
        border-bottom-right-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
        background: transparent !important;
    }

    .associate_form {
        border-bottom: 1px solid #dee2e6;

        label {
            font-size: 13px;
            font-family: 'SegoeUI-Semibold';
            color: #525a64 !important;
        }

        .row {
            margin-bottom: -3px;
        }

        .col-md-4 {
            padding-bottom: 5px;
        }

        .col-md-4:nth-child(1) {
            padding-right: 4px;
        }

        .col-md-4:nth-child(2) {
            padding-right: 0px;
            padding-left: 12px;
        }
    }

    .role-dropdown {
        .col-md-4:nth-child(1) {
            padding-right: 4px;
        }

        .col-md-4:nth-child(2) {
            padding-right: 0px;
            padding-left: 12px;
        }

        .col-md-4:nth-child(3) {
            padding-right: 0px;
            padding-left: 6px;
        }
    }

    .feature_checkbox.practice1 .col-md-4 {
        padding-right: 0;
    }

    .feature_checkbox.practice1,
    .feature_checkbox.practice2,
    .feature_checkbox.practice3 {
        padding-top: 12.5px;
    }

    .disabled-paragraph {
        font-size: 13px !important;
        color: #888f96 !important;
    }

    .feature_checkbox {
        padding-top: 7px;

        .modal-footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 1rem;
            border-top: 1px solid #dee2e6;
            border-bottom-right-radius: 0.3rem;
            border-bottom-left-radius: 0.3rem;
            background: transparent !important;
        }

        .input-group-text {
            padding: 0 !important;
            color: #535bd6;
            border: 0 !important;
            margin-left: 5px;
        }

        .col-md-6 .form-check {
            line-height: 25px !important;
            padding: 10px 0 0 20px;
        }
    }

    .practices-list {
        display: flex;
        margin-right: -15px;
        margin-left: -15px;
        margin-bottom: 10px;
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;
    }

    .delete-dialog {
        top: 25%;

        .rodal-dialog.rodal-dialog {
            width: 400px !important;
        }

        .body {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        .header {
            padding-top: 30px !important;
            width: 75%;
            align-self: center;
            text-align: center;
        }
    }

    .section-body {
        display: flex;
        position: relative;
        padding-left: 28px;
        margin-bottom: 12px;
        font-size: 22px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        line-height: 20px;
        color: #888f96;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
        text-transform: capitalize;
        align-items: baseline;

        .info-tooltip {
            cursor: pointer;
        }

        label {
            margin-bottom: 0;
        }

        .access-right-label {
            cursor: pointer;
        }

        .info-tooltip {
            margin-bottom: 0;

            li {
                margin-left: -20px;
            }
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .checkmark {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            border: 1px solid #dce0e4;
            border-radius: 0.25rem;
            background: #fff;
        }

        .checkmark:after {
            content: '';
            position: absolute;
            display: none;
        }

        input:disabled ~ .checkmark:after {
            border: solid #949494;
            border-width: 0 3px 3px 0;
        }

        input:disabled ~ .checkmark {
            background-color: #f0f4f7;
        }

        input:checked ~ .checkmark:after {
            display: block;
        }

        .checkmark:after {
            left: 6px;
            top: 0px;
            width: 7px;
            height: 14px;
            border: solid #535bd6;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    .section-body.pmg-access-rights {
        width: max-content;
    }

    .customerStyle {
        background: url('../../../assets/images/arrow.png');
        background-position: calc(100% - 10px);
        background-repeat: no-repeat;
        border: 1px solid #dce0e4 !important;
        outline: 0;
        max-width: 270px;

        border-radius: 0.25rem;
        height: 30px;
        overflow: hidden;
        cursor: pointer;

        select {
            background: transparent;
            overflow: hidden;
            position: relative;
            padding: 4px 10px 4px 10px;
            color: #888f96;
            font-size: 13px;
            font-family: 'SegoeUI-Semibold';
            outline: 0;
            border: none;
            box-shadow: none;
            background-color: transparent;
            background-image: none;
            height: 30px;
            border-radius: 0.25rem;
            cursor: pointer;
            width: 100%;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            text-overflow: '';
            text-indent: 0.01px;
            text-overflow: '';
        }

        .form-control.is-invalid,
        .form-select.is-invalid {
            background-color: #fff;
            height: 28px;
        }

        .form-control:disabled,
        .form-select:disabled {
            background-color: #e9ecef;
        }

        select:focus {
            outline: none;
        }

        .section-body.pmg-access-rights {
            width: max-content;
        }

        .discount-code-label {
            font-size: 13px;
            text-transform: capitalize;
            font-family: 'SegoeUI-Semibold';
            line-height: 20px;
            color: #888f96;
            margin-bottom: -1rem;
        }
    }

    .form-control.is-invalid,
    .form-select.is-invalid,
    .was-validated .form-control:invalid,
    .was-validated .form-select:invalid {
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: URL("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: center right 5px;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .section-container {
        .section-header {
            font-family: 'SegoeUI-Bold';
            font-size: 17px;
            margin: 5px 0;
        }

        .section-sub-header {
            font-family: 'SegoeUI-SemiBold';
            font-size: 15px;
            margin: 5px 0 7px 10px;
        }
    }

    @media (max-width: 1280px) {
        .modal-footer {
            margin-right: 17px;
        }
    }

    @media (max-width: 991px) {
        .rodal-dialog {
            top: 7% !important;
            left: 0 !important;
        }

        .modal-footer {
            padding: 0 0 20px 0;

            .footer-wrapper {
                padding-left: 15px !important;
                padding-right: 25px !important;
            }
        }

        .associate_form {
            .col-md-4 {
                padding-bottom: 0px;
            }

            .col-md-4:nth-child(1) {
                padding-right: 15px;
            }

            .col-md-4:nth-child(2) {
                padding-right: 15px;
                padding-left: 15px;
            }
        }

        .role-dropdown {
            .col-md-4:nth-child(1) {
                padding-right: 15px;
            }

            .col-md-4:nth-child(2) {
                padding-right: 15px;
                padding-left: 15px;
            }

            .col-md-4:nth-child(3) {
                padding-right: 15px;
                padding-left: 15px;
            }
        }
    }

    @media (max-width: 767px) {
        .modal-body .col-md-4 input,
        .customerStyle {
            max-width: 100%;
        }

        .associate_form {
            padding-bottom: 10px;
        }

        .practices-list {
            flex-wrap: wrap !important;
            overflow: hidden;
        }
    }

    @media (max-width: 577px) {
        .modal-footer {
            justify-content: flex-end;
        }
    }
}

.confirm-removing-representative-access-right-pop-up {
    .modal-header {
        height: auto;
        padding: 19px 23px 19px 23px;
    }

    .modal-footer {
        padding: 12px 20px 17px 20px;
        .btn-cancel {
            width: auto !important;
        }
    }

    @media (max-width: 540px) {
        .modal-footer {
            .btn {
                height: auto !important;
            }
        }
    }
}
