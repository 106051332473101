.add-project-variable-popup {
    .rodal-dialog {
        top: 20%;
        max-width: 350px;

        .options-list {
            max-height: 400px;
            overflow-y: auto;
        }

        .options-list::-webkit-scrollbar {
            cursor: pointer;
            height: 6px;
            width: 4px;
            background-color: #e5e5e5;
            border-radius: 10px;
        }
        .options-list::-webkit-scrollbar-thumb {
            background-color: #a0a0a0 !important;
            height: 4px;
            width: 3px;
            border-radius: 5px;
        }
    }
    .buttons-wrapper {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
    }

    .variable-name-form {
        display: flex;
        gap: 1rem;
        align-items: center;
        margin-bottom: 1rem;

        .form-group {
            margin-bottom: 0;
            width: 100%;
        }

        label {
            margin-bottom: 0;
        }
    }

    .add-new-option-form-row {
        .form-group {
            width: 100%;
        }

        .btn {
            min-width: 66px;
        }
    }

    .rodal h5.modal-title {
        color: black !important;
        margin-bottom: 0;
    }

    .btn-cancel {
        background-color: #fff;
        color: #919191;
        border-color: #919191;
    }

    .project-variable-tile {
        font-family: 'SegoeUI-Semibold';
        margin-top: 8px;
        margin-bottom: 8px;
        font-size: 14px;
        color: #888f96;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .project-variable-name {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .delete-button {
            padding: 0 0.5rem;
            cursor: pointer;
        }

        .project-variable-info {
            display: flex;
            gap: 0.5rem;
            max-width: 90%;
        }
    }
}
