@import '../Associates/AssociateMembersTable.scss';

.announcement-modal .associate_form textarea.form-control {
    min-height: 120px;
}

.associate_member .announcement-modal .delete-announcement-btn {
    min-width: 165px !important;
    margin-right: 10px;
}

.body_white_box.associate_member
    .announcement-modal
    .modal-body
    .col-md-3
    input {
    max-width: 270px;
    height: 30px;
    border: 1px solid #dce0e4;
    padding: 13px 15px 14px;
    outline: none;
    color: #888f96;
    font-size: 13px;
    font-family: 'SegoeUI-Semibold';
}

.body_white_box.associate_member .announcement-modal .modal-body .col textarea {
    border: 1px solid #dce0e4;
    padding: 13px 15px 14px;
    outline: none;
    color: #888f96;
    font-size: 13px;
    font-family: 'SegoeUI-Semibold';
}

.announcement_form .col-md-3 {
    flex: 0 0 21%;
    min-width: 21%;
}

.announcement_form .col-md-2 {
    flex: 0 0 16.3%;
    min-width: 16.3%;
}

.body_white_box.associate_member
    .announcement-modal
    .modal-body
    .col-md-5
    input {
    max-width: 500px;
    height: 30px;
    border: 1px solid #dce0e4;
    padding: 13px 15px 14px;
    outline: none;
    color: #888f96;
    font-size: 13px;
    font-family: 'SegoeUI-Semibold';
}
