.data-form-stage-name-editor {
    .data-form-stage-name-editor,
    .data-form-stage-name-view {
        display: flex;
        gap: 0.5rem;
        width: fit-content;

        svg {
            cursor: pointer;
        }
    }

    .data-form-stage-name-editor {
        align-items: center;

        input {
            min-width: 300px;
        }
    }

    .data-form-stage-name-view {
        align-items: flex-end;
    }
}
