.active-subscriptions-paginated-table {
    .referenced-text {
        color: #545cd8;
    }

    .info-button {
        width: 100%;
        max-width: 29px;
        height: 29px;
        padding: 0;
    }

    .overdue-payment {
        background-color: #fadaec;
    }

    .react-bootstrap-table {
        overflow: auto;
    }

    .table thead tr {
        background-color: #dce0e4;
        color: #525a64;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
    }

    .table thead th {
        cursor: pointer;
    }

    .table tbody td {
        color: #888f96;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
    }

    .react-bootstrap-table {
        margin-bottom: 1em;
        .table {
            margin: 0;
        }

        .currency {
            display: flex;
            justify-content: flex-end;
        }
    }

    .react-bootstrap-table::-webkit-scrollbar {
        cursor: pointer;
        height: 6px;
        width: 4px;
        background-color: #e5e5e5;
        border-radius: 10px;
    }

    .react-bootstrap-table::-webkit-scrollbar-thumb {
        background-color: #a0a0a0 !important;
        height: 4px;
        width: 3px;
        border-radius: 5px;
    }

    .filters-bar {
        color: #888f96 !important;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
        margin-bottom: 5px;

        .form-group {
            display: flex;
            flex-wrap: wrap;
        }

        .filter-enabled {
            .rw-dropdownlist-search {
                display: block !important;
            }
        }

        .input-group-text {
            border-radius: 5px 0px 0px 5px;
            border: none !important;
            border-right: 1px solid #ced4da !important;

            input {
                max-width: 270px;
            }
        }

        .small-border {
            border: 1px solid #ced4da;
            border-radius: 5px;
            height: 30px;
            align-items: center;
            width: 125px;
        }

        .filter-item {
            display: flex;
            align-items: center;
            margin-top: 0.5em;

            label {
                margin: 0;
            }

            label + div {
                margin-left: 0.5em;
            }
        }

        .rw-state-focus .rw-widget-picker {
            border-color: #ccc !important;
        }

        .rw-dropdown-list {
            width: 150px;
        }

        .rw-dropdown-list-value {
            color: #888f96 !important;
        }

        .rw-list-option {
            color: #888f96;
        }

        .line-separator {
            margin: 0 1.5em;
            border: 1px solid #888f9645;
            height: 30px;
            margin-top: 0.5em;
        }

        .customerStyle {
            background: url('../../assets/images/arrow.png') !important;
            background-position: calc(100% - 10px) !important;
            background-repeat: no-repeat !important;
            background-color: transparent;
            min-width: 150px;
            select {
                padding: 2px 20px 3px 13px;
            }
        }
    }

    .filters-bar::-webkit-scrollbar {
        height: 6px;
        background-color: #e5e5e5;
        border-radius: 10px;
    }

    .filters-bar::-webkit-scrollbar-thumb {
        background-color: #a0a0a0 !important;
        height: 4px;
        width: 3px;
        border-radius: 5px;
    }

    @media (max-width: 1405px) {
        .line-separator {
            display: none;
        }
        .filters-bar .form-group {
            flex-direction: column;
            align-content: space-between;
        }
        .filters-bar .filter-item {
            justify-content: space-between;
        }
    }

    @media (max-width: 1250px) {
        .line-separator {
            display: inherit;
        }
        .filters-bar .form-group {
            flex-direction: inherit;
            align-content: inherit;
        }
        .filters-bar .filter-item {
            justify-content: inherit;
        }
    }

    @media (max-width: 1190px) {
        .line-separator {
            display: none;
        }
        .filters-bar .form-group {
            flex-direction: column;
            align-content: space-between;
        }
        .filters-bar .filter-item {
            justify-content: space-between;
        }
    }
}
