.app-share-knowledgebase-content {
    .share-content-header h5 {
        text-transform: none !important;
        font-size: 18px !important;
        font-family: 'SegoeUI-Semibold' !important;
        display: inline-block;
        color: #000000;
    }

    .action-buttons-wrapper {
        display: flex;
        justify-content: flex-end;

        button {
            background: #535bd6 !important;
            border-color: #535bd6 !important;
            padding: 2px 5px 3px 5px;
        }
    }
}
