.cropping-tool-popup {
    .rodal-dialog {
        top: 20%;
    }

    .cropped-image-container {
        display: flex;
        justify-content: center;

        .cropped-image {
            max-width: 100%;
            object-fit: contain;
        }
    }

    .buttons-wrapper {
        margin-top: 1rem;
        text-align: right;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .cancel-btn {
            color: #919191;
            border: 1px solid #919191;
            max-width: 70px !important;
            height: 29px;
            padding: 0px 3px 1px 3px;
            font-size: 14px;
            background-color: transparent;
            border-radius: 0.25rem;
            cursor: pointer;
            font-size: 14px !important;
            width: 100%;
        }

        .reset-btn {
            color: #919191;
            border: 1px solid #919191;
            max-width: 90px !important;
            height: 29px;
            padding: 0px 3px 1px 3px;
            font-size: 14px;
            background-color: transparent;
            border-radius: 0.25rem;
            cursor: pointer;
            font-size: 14px !important;
            width: 100%;
        }

        .apply-result-btn {
            color: #ffffff;
            border: 0;
            width: 95px;
            height: 29px;
            padding: 0px 3px 1px 3px;
            background-color: #535bd6;
            border-radius: 0.25rem;
            cursor: pointer;
            font-size: 14px !important;
        }

        & > *:not(:first-child) {
            margin-left: 1rem;
        }
    }
}
