.doc-decline {
    h1 {
        color: #495057;
        text-transform: none;
        margin: 0.75rem 0;
        display: block;
        text-align: center;
        position: relative;
        padding: 0;
        font-size: 2rem;
    }

    p {
        color: #adb5bd;
        float: none;
        margin: 0;
        text-align: center;
        position: relative;
        padding: 0;
    }

    button {
        margin-top: 1rem;
    }
}

.swal2-confirm {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.375rem 0.75rem;
    font-size: .88rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s, background-color .15s, border-color .15s, box-shadow .15s !important;
    color: #fff;
    background-color: #545cd8;
    border: 1px solid #545cd8;
}