.blink_me {
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    from { 
        opacity: 1.0; 
    }
    50% { 
        opacity: 0.2; 
    }
    to { 
        opacity: 1.0; 
    }
}

.renewal-date-nf {
    position: absolute;
    top: -15px;
    right: -15px;
}