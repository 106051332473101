.payment-modal-popup {
    .rodal-dialog {
        max-width: 600px !important;
    }

    h1 {
        color: #32325d;
        font-weight: 400;
        line-height: 50px;
        font-size: 40px;
        margin: 20px 0;
        padding: 0;
    }

    .modal-footer.flex-footer {
        padding: 0 1rem 0rem 1rem;
        border: none;
        background-color: inherit;
    }

    @media (max-width: 575px) {
        .modal-footer {
            justify-content: flex-end !important;
        }
    }

    .default-image {
        float: right;
        max-height: 40px;
        margin-top: 5px;
    }

    .default-image {
        margin: 1rem !important;
        margin-block: 0.5em !important;
    }


    .buttons-row {
        margin-right: 0;
    }

    @media (max-width: 650px) {
        .default-div-image {
            .default-image {
                margin: 3px 0px 5px 0px;
            }
        }

        .wallet-text-ref {
            .info-tooltip {
                margin-right: 15px;
            }
        }

        input {
            margin-right: 5px;
        }

        .checkbox-buttons {
            display: flex;
            justify-content: flex-end;

            :nth-child(2) {
                margin-right: 0 !important;
            }
        }

        .payment-footer {
            flex-direction: column;
            align-items: normal;
        }
    }

    @media (max-width: 575px) {
        .modal-footer {
            justify-content: flex-end !important;
        }
    }

    h5 {
        font-size: 18px !important;
    }

    .span-tooltip {
        margin-right: 5px;
    }

}