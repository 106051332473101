.payment-modal-popup {
    .rodal-dialog {
        max-width: 500px !important;
    }

    h5 {
        font-size: 18px !important;
    }

    .StripeElement,
    input {
        display: block;
        margin: 10px 0 20px 0;
        max-width: 500px;
        font-size: 1em;
        border: 1px solid #dce0e4;
        border-radius: 0.25rem;
        height: 30px;
        padding: 7px 10px 7px 10px;
    }

    input:focus {
        border: 1px solid #dce0e4;
    }

    .buttons-content {
        margin-top: 20px;
    }

    .popup-header {
        color: black;
        font-weight: normal;
    }

    .rodal-dialog {
        top: 25%;

        @media only screen and (max-width: 767px) {
            top: 25% !important;
        }
    }

    button {
        white-space: nowrap;
        border: 0;
        display: inline-block;
        height: 30px;
        line-height: 28px;
        padding: 0 10px;
        color: #fff;
        border-radius: 4px;
        font-size: 15px;
        text-decoration: none;
        text-transform: capitalize;
    }

    .submit-button {
        margin-left: 10px;

        @media only screen and (max-width: 767px) {
            margin-left: 5px;
        }
    }

    .close-button {
        border: 1px solid;
        border-color: gray;
        background-color: white;
        color: gray;
    }
    .label {
        font-family: 'SegoeUI-Semibold';
        font-size: 14px;
    }

    .modal-footer {
        padding: 0 1rem 1rem 1rem;
        border: none;
        background-color: inherit;
    }

    @media (max-width: 575px) {
        .modal-footer {
            justify-content: flex-end !important;
        }
    }
}
