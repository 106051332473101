.requested-content-table {
    .react-bootstrap-table {
        max-height: 400px;
        overflow-y: auto;
    }

    .react-bootstrap-table::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #c1c1c157;
    }

    .react-bootstrap-table::-webkit-scrollbar-thumb {
        background-color: #a0a0a0;
        border-radius: 5px;
    }

    .likes-column {
        display: flex;
        align-items: stretch;

        .like-icon.active {
            color: #545cd8;
            cursor: pointer;
        }

        .like-icon.inactive {
            color: #888f96;
            cursor: pointer;
        }

        :not(:first-child) {
            margin-left: 5px;
        }

        label {
            margin-bottom: 0;
            font-size: 14px;
        }
    }

    .approve-actions {
        .approve-icon {
            color: #33bc75;
            cursor: pointer;
        }

        .reject-icon {
            color: #e92c59;
            cursor: pointer;
        }

        :not(:first-child) {
            margin-left: 10px;
        }
    }

    .react-bootstrap-table th:nth-child(1) {
        width: 35% !important;
    }

    .content-topic {
        color: #545cd8 !important;
        cursor: pointer;
    }
}
