.kb-content-grid {
    .pmg_table_data table th:nth-child(1) {
        width: 30% !important;
    }

    .pmg_table_data table th:nth-child(2) {
        width: 10% !important;
    }

    .pmg_table_data table th:nth-child(3) {
        width: 15% !important;
    }

    .pmg_table_data table th:nth-child(4) {
        width: 15% !important;
    }

    .pmg_table_data table th:nth-child(5) {
        width: 15% !important;
    }

    .pmg_table_data table th:nth-child(6) {
        width: 10% !important;
    }
}

.knowledge-base-content {
    .table input {
        font-family: 'Segoe UI', sans-serif;
    }

    .rodal .rodal-dialog {
        width: 500px !important;
    }

    .body_white_box {
        margin-bottom: 0;
    }

    .modal-body {
        padding: 17px 24px 17.61px 24px;
    }

    .link-button {
        border: 0;
        color: #545cd8 !important;
        text-decoration-line: underline;
        padding: 0;
        cursor: pointer;
        font-size: 13px !important;
        text-align: end;
        align-self: self-end;
    }

    .link-button-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .body_white_box.associate_member .modal-body .col-md-12 input {
        max-width: 100%;
        padding: 2px 11px 4px 10px !important;
        height: 30px;
        border: 1px solid #dce0e4;
        outline: none;
        color: #888f96;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold', sans-serif;
    }

    .body_white_box.associate_member .modal-body .col-md-6 input {
        max-width: 100%;
        padding: 2px 11px 4px 10px !important;
        height: 30px;
        border: 1px solid #dce0e4;
        outline: none;
        color: #888f96;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold', sans-serif;
    }

    .input-group-text {
        padding: 0 7px;
    }

    .body_white_box.associate_member .modal-body .col-md-12 textarea {
        width: 100%;
        resize: none;
        padding: 8px 10px;
        border-radius: 0.25rem;
        outline: 0;
        font-size: 13px !important;
        color: #888f96 !important;
        font-family: 'SegoeUI-Semibold', sans-serif !important;
        min-height: 60px;
    }

    .form-control:focus {
        &:invalid {
            border: 1px solid #dc3545 !important;
        }
    }

    .associate_form textarea.form-control {
        min-height: 0px;
    }

    .associate-members-table .body_white_box.associate_member .modal-body p {
        margin-top: 0.5em;
    }

    .delete-btn {
        color: #919191;
        height: 29px;
        border: 1px solid #919191;
        background-color: transparent;
        max-width: 150px;
        padding: 2px 5px 3px 5px;
        width: 100% !important;
        margin-right: 10px;
        font-size: 13px;
    }

    .footer-wrapper-container {
        padding-inline: 24px;
        padding-top: 20px;
        display: flex;
        justify-content: end;
        align-items: center;
        width: 100%;
    }

    .upload-file-input {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }

    .upload-file-group {
        padding: 0;
    }

    .choose-file-label {
        padding-inline: 10px;
        padding-block: 4px;
        cursor: pointer;
        margin-bottom: 0;
    }

    label.upload-now {
        color: #535bd6 !important;
        cursor: pointer;
        position: absolute;
        right: 18px;
        font-size: 13px !important;
        font-family: 'SegoeUI-Semibold' !important;
        padding-top: 2px;
        text-decoration: underline;
    }

    .preview-container {
        display: flex;
        justify-content: space-between;
    }

    .modal-body p:nth-child(2) {
        padding-top: 0 !important;
        margin-top: -0.5rem;
    }

    .modal-style {
        top: 1%;
    }
}