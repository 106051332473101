.purchase-history-paginated-talbe {
    .react-bootstrap-table {
        overflow: auto;
    }

    .react-bootstrap-table-pagination {
        flex-wrap: nowrap !important;
    }

    .link {
        cursor: pointer;
        color: #545cd8;
        text-decoration: underline;
    }

    .total-credits>div {
        margin-left: 10px;
    }

    tbody .selected {
        background-color: #e6e8ff;
    }

    thead {
        cursor: pointer;
    }

    .tooltip-content {
        opacity: 1;
    }

    .gift-card-tooltip {
        padding: 1.5em;
    }

    .purchase-history-nf {
        position: absolute;
        right: -1.5em;
        top: -1em;

        span {
            display: inline-block;
        }
    }


    .relative-container {
        position: relative;
        width: fit-content;
    }
}

.dropdown-label {
    font-family: 'SegoeUI-Bold';
}