.data-input-support-confirmation-popup {
    .buttons-wrapper {
        display: flex;
        justify-content: end;
        gap: 1rem;
    }

    .btn-secondary {
        border: 1px solid #919191;
        background: #fff;
        color: #919191;
    }
}
