.thank-you {
    .modal-footer {
        flex-direction: column;
    }

    .thank-you-rodal {
        .rodal-dialog {
            max-width: 710px;
        }
    }

    .btn-link:hover {
        color: #0056b3;
        text-decoration: underline !important;
    }
}