.contact-support-button {
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.25rem;

    .reference-text {
        color: #535bd6;
        text-decoration: underline;
    }

    svg {
        color: #535bd6;
    }

    button {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        svg {
            color: white;
        }
    }

    .direct-icon {
        display: inline-block;
        border-radius: 60px;
        padding: 8px;
        height: 26px;
        color: white;
        background-color: #535bd6;
        vertical-align: middle !important;
    }
}
