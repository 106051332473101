.data-table-templates {
    margin-top: 15px;

    .action-icons {
        font-size: 18px;
        cursor: pointer;
        display: flex;
        gap: 10px;
        justify-content: flex-end;

        .delete-button {
            color: #d32f2f;
        }
    }

    .data-table-view-item {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    table th:first-of-type {
        min-width: 340px;
    }

    .table-view-row {
        td:first-of-type {
            padding-left: 3.5rem;
        }
    }

    .action-column {
        width: 100px;
    }

    .added-on-date-column {
        width: 130px;
    }
}
