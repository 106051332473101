.data-table-view-host {
    .numeric-cell {
        align-items: center !important;
    }

    .bottom-button-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .tooltip-cell {
        align-items: center !important;
    }

    .MuiDataGrid-cellContent {
        padding-top: 0 !important;
    }

    .full-screen-button {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 5px;
        border: 1px solid;
        border-radius: 7px;
        align-self: center;
    }

    .editable-table {
        height: 50vh;
    }

    .numeric-cell.MuiDataGrid-cell--editing {
        padding: 10px 16px !important;
    }

    input {
        color: #888f96 !important;
    }

    .header-container {
        display: flex;
        justify-content: flex-end;
        gap: 2rem;
        align-items: center;
        margin-bottom: 1rem;

        .status {
            font-family: 'SegoeUI-Semibold';
        }

        .mark-as-complete {
            display: flex;
            align-items: center;
            gap: 0.25rem;

            .complete-checkbox {
                position: relative;
                cursor: pointer;
                margin: 0 !important;
            }
        }
        .chat,
        .full-size {
            svg {
                height: 20px;
                cursor: pointer;
            }
        }
    }

    .status-tool-bar {
        display: flex;
        justify-content: flex-end;
        gap: 2rem;
        padding-bottom: 1rem;
        align-items: baseline;

        .status {
            font-family: 'SegoeUI-Bold';
        }

        .table-view-progress-status-dropdown-wrapper {
            display: flex;
            align-items: baseline;
            gap: 0.5rem;
        }

        .table-view-progress-status-dropdown {
            max-width: 120px;
            font-family: 'SegoeUI-Bold';
        }

        .mark-as-complete-section {
            display: flex;
            align-items: baseline;
            gap: 0.5rem;
        }
    }

    .empty-answer-cell-alert {
        color: red;

        &::before {
            content: '????';
        }
    }

    .cell-notification-badge {
        top: 0 !important;
        min-width: 24px;
    }

    .custom-render-cell {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .MuiDataGrid-row {
        .disabled {
            background-color: #e9ecef;
        }
    }

    .MuiDataGrid-columnHeaderTitleContainerContent {
        width: 100%;
        justify-content: space-between;
    }

    .grid-column-name-wrapper{
        display: flex;
        flex-direction: row;
        white-space: nowrap;
        overflow: hidden;

        span {
            margin-right: 4px;
        }

    .grid-column-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
}

.data-table-view-host.full-screen {
    all: unset;
    padding: 1vw;
    position: fixed;
    top: 0;
    left: 0;
    width: 98vw;
    height: 98vh;
    z-index: 2147483647;
    background-color: white;
    overflow: auto;
    pointer-events: auto;

    .editable-table {
        height: 84vh !important;
    }
}
