.questionnaire-form {
    .unread-comments-badge {
        min-width: 28px;
        margin-left: 5px !important;
    }

    .lock-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .question,
    .feedback {
        input,
        textarea,
        select {
            font-size: 14px !important;
            color: #495057 !important;
            font-family: 'SegoeUI' !important;
        }
    }

    .question {
        .text-answer {
            white-space: normal;
        }
    }

    .expanded-text-with-badge {
        display: flex;
    }

    .visibility-swithcer {
        height: 22px;
        display: flex;

        div {
            margin-top: 2px;
        }
    }

    .numeric-input {
        display: flex;

        .form-group {
            width: 100%;
            margin-bottom: 0;
            display: flex;
        }
        input {
            margin: 10px 0 0 10px;
            height: 30px;
            border: 1px solid #dce0e4;
            padding: 13px 15px 14px;
            outline: none;
            color: #888f96;
            font-size: 13px;
            border-radius: 0.25rem;
            font-family: 'SegoeUI-Semibold';
        }
    }

    .select-input {
        margin-left: 10px;
    }

    .text-size-small {
        height: 37px;
    }

    .text-size-field {
        overflow: hidden;
        margin-top: 10px;
    }

    .text-size-medium {
        height: 62px;
    }

    .text-size-large {
        height: 130px;
    }

    .select-wrapper {
        display: flex;
    }

    .select-wrapper-readonly {
        pointer-events: none;
        display: flex;
    }

    .radio-button-wrapper {
        display: flex;
        gap: 3rem;
        margin-left: 10px;

        .clickable {
            cursor: pointer;
        }
        .radio-button-block {
            display: flex;
            align-items: flex-end;

            .form-check-input {
                margin-left: 0;
            }

            input[type='radio'] {
                accent-color: #535bd6;
                position: relative;
                left: 0;
                margin-right: 3px;
            }
            input[type='radio']:checked:after {
                display: none !important;
            }

            .radio-label {
                margin-bottom: 0;
            }
        }
    }

    table {
        td {
            vertical-align: top !important;
        }

        .completness-checkbox-header {
            max-width: 100px;
            width: 100px !important;
            text-wrap: balance;
        }

        .admin-checkbox-field {
            margin-top: 8px;
        }

        .display-question {
            text-wrap: balance;
            min-width: 200px;
        }

        .feedback {
            min-width: 150px;
            width: 20%;
            max-width: 400px;
        }

        .text-answer {
            display: flex;
            flex-direction: column;

            textarea {
                margin-left: 10px;
            }
        }

        .parent-display-name {
            text-transform: capitalize;
            white-space: 'nowrap';
            margin-right: '10px';
        }
    }

    .subquestions-list {
        margin-top: 1rem;

        li:not(:first-child) {
            margin-top: 1rem;
        }
    }

    .subquestions-visibility-list {
        margin-top: 1.5rem;

        li:not(:first-child) {
            margin-top: 0.5rem;
        }
    }

    .item-question-wrapper {
        display: flex;
        gap: 0.25rem;
    }
}
