.table-form-popup {
    .rodal-dialog {
        max-width: 100%;
        left: 0;
        width: 80% !important;
        top: 20%;
    }

    .editable-table {
        height: 20rem;
    }
}