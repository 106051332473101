.lanscape-map-zoom-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;

    .current-zoom {
        width: 25%;
    }

    .set-current-zoom {
        display: flex;
        align-items: center;
        justify-content: end;
        width: 50%;
    }

    .set-current-zoom-text {
        display: flex;
    }

    .set-current-zoom-input {
        margin-left: 20px;
        width: 20%;
    }
}