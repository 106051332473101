.gift-card-search-modal {
    .rodal-dialog {
        top: 25%;

        .modal-body {
            max-height: 500px;

            .recipients-choices-wrapper {
                overflow-y: auto;
                overflow-x: hidden;
                max-height: 418px;
            }

            .name-header-offset {
                margin-left: 27px;
            }

            .practice-header-offset {
                padding-left: 5px;
            }

            .choice-row {
                font-size: 14px;
                font-weight: normal;
            }

            .no-results-row {
                flex-direction: column;
                justify-content: center;

                .alert {
                    width: 90%;
                    height: 100px;
                    margin-top: 1.5rem;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            @media only screen and (max-width: 767px) {
                .header-row {
                    .name-header-offset {
                        margin-bottom: 0;
                    }

                    .practice-header-offset {
                        margin-top: 0;
                        margin-left: 37px;
                    }
                }
            }
        }
    }
}
