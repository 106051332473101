.subscriptions-host {
    background-color: #fff;
    box-shadow: 0 4px 21px 8px rgba(18, 22, 45, 0.05);

    .rw-widget-input.rw-widget-picker.rw-widget-container {
        span {
            margin-bottom: 0;
        }
    }

    .rw-picker-caret {
        display: flex;
    }

    th span {
        display: inline;
    }

    .practice-heading {
        margin-bottom: 0;
        color: #000000;
    }

    .practice-reports-tabs {
        margin-bottom: 0.2em;
    }

    .body_inner_content {
        padding-bottom: 0 !important;
    }
}