.buyer-marketplace{
    .user-onboarding-img {
        max-height: 200px !important;
        padding: 10px;
    }

    .onboarding-text {
        margin-top: 0 !important;
    }

    .card-header {
        color: black;
        font-size: 16px;
    font-weight: bold;
    }

    .table {
        white-space: nowrap;
    }

    .react-bootstrap-table {
        overflow: auto;
    }

    .react-bootstrap-table::-webkit-scrollbar {
        cursor: pointer;
        height: 6px;
        width: 4px;
        background-color: #e5e5e5;
        border-radius: 10px;
    }
    .react-bootstrap-table::-webkit-scrollbar-thumb {
        background-color: #a0a0a0 !important;
        height: 4px;
        width: 3px;
        border-radius: 5px;
    }
}