// Header Buttons

.header-btn-lg {
    padding: 0 0 0 ($layout-spacer-x);
    margin-left: ($layout-spacer-x);
    display: flex;
    align-items: center;
    position: relative;

    &::before {
        position: absolute;
        left: -1px;
        top: 50%;
        background: $border-color;
        width: 1px;
        height: 30px;
        margin-top: -15px;
        content: '';
    }

    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
        background: $gray-600;
    }
}
