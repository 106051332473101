body {
    margin: 0;
    font-family: 'SegoeUI';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

strong {
    font-family: 'SegoeUI-Bold';
}

ul {
    margin: 0;
}

.app-main__inner a {
    color: #545cd8;
    cursor: pointer;
}

.rw-i-caret-down:before {
    content: '\f105' !important;
    color: #545cd8;
    font-weight: 600;
}

button.rw-btn.rw-btn-select {
    transform: rotate(90deg);
}

input.form-control,
.rw-widget-input,
.rw-filter-input {
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid #ced4da;
}

.blue_btn,
.green_btn {
    display: inline-block;
    background-color: #535bd6 !important;
    color: #fff !important;
    font-size: 14px;
    text-decoration: none !important;
    font-family: 'SegoeUI-Bold';
    border-radius: 4px;
}

.blue_btn {
    padding: 14px 20px;
}
.green_btn {
    background-color: #3ac47d !important;
    margin-left: 20px;
}
.red_btn {
    background-color: #da0000 !important;
    color: #fff !important;
}

.main-title-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.react-bootstrap-table .table {
    border: 0px;
}

.pmg_table_data .RRT__panel {
    margin-top: 0rem;
}

button.btn {
    font-family: 'SegoeUI-Semibold';
    font-size: 14px;
}

.address_area.row .dynamic_data label {
    display: block;
    width: 100% !important;
}

.address_area.row .dynamic_data {
    margin-bottom: 10px !important;
}

.edit_membership_profile .dynamic_data {
    display: flex;
    align-items: center;
}

.edit_membership_profile .dynamic_data label {
    width: 42% !important;
    color: #525a64;
    font-family: 'Segoe UI';
    font-size: 13px;
    font-weight: 600;
}

.edit_membership_profile .dynamic_data input {
    width: 60%;
    border: 1px solid #dce0e4;
    font-size: 13px;
    font-weight: 600;
    color: #888f96;
    font-family: 'Segoe UI';
    box-shadow: none;
    outline: none;
    height: 30px;
}

.edit_membership_profile .address_area .dynamic_data {
    display: block;
}

.edit_membership_profile .dynamic_data {
    margin-bottom: 15px !important;
}

.edit_membership_profile .address_area input {
    width: 100%;
}

ul.step5list {
    padding: 0;
}

ul.step5list li {
    list-style: none;
    padding-left: 15px;
    margin-bottom: 12px;
    font-size: 16px;
    font-size: 16px;
    letter-spacing: 0;
    position: relative;
}

ul.step5list li:after {
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    content: '';
    background-color: #535bd6;
    left: 0;
    top: 6px;
}

.form-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.form-title span {
    font-size: 13px;
}

.form-title p {
    font-size: 13px;
    margin-bottom: 0;
}

.edit_affiliation_wrapper {
    text-align: center;
}

a.edit_affiliation {
    color: #545cd8 !important;
    font-size: 14px;
    text-decoration: none !important;
}

.affiliations_form-wrapper a.link_add {
    color: #535bd6 !important;
    font-family: 'Segoe UI';
    font-size: 14px;
    font-style: italic;
}

.form-submit-btn button.btn {
    border: 1px solid #919191;
    font-family: 'SegoeUI-Semibold';
    font-size: 14px;
    height: 30px;
    padding: 0px 15px;
    color: #919191;
    line-height: 28px;
}

.form-submit-btn.input-group {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #ced4da;
    padding-bottom: 15px;
}

button.btn.btn-submit {
    border-color: #ca9c30;
    background-color: #ca9c30;
    color: #fff;
}

.name_change {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 60%;
}

.vat_list_wrapper {
    display: flex;
    padding-right: 5px;
}

.vat_list_wrapper .date_tag {
    width: 17%;
}

.vat_list_wrapper .list-content {
    width: 83%;
}

.date_tag .d_span {
    display: inline-block;
    background-color: #535bd6;
    font-size: 11px;
    font-family: 'SegoeUI-Bold';
    color: #fff;
    padding: 4px 5px;
    text-transform: uppercase;
    border-radius: 5px;
}

.vet_value_content {
    max-height: 550px;
    overflow: auto;
}

.vet_value_content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

.vet_value_content::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
}

.vet_value_content::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.3);
}

.date_tag span.t_span {
    color: #7b7b7b;
    font-family: 'Segoe UI';
    font-size: 11px;
    display: block;
    margin-top: 3px;
}

.list-content label {
    display: block;
}

.list-content b {
    color: #000000;
    font-family: 'Segoe UI';
    font-size: 16px;
    font-weight: bold;
}

.list-content p,
.list-content a {
    color: #000000;
    font-family: 'Segoe UI';
    font-size: 14px;
    margin-bottom: 0px;
}

.list-content a {
    text-decoration: underline;
    color: #535bd6;
    font-style: italic;
}

.capvar_content a {
    font-family: 'Segoe UI';
    font-size: 16px;
    font-style: italic;
    margin-bottom: 0px;
    color: #535bd6 !important;
    text-decoration: underline;
}

.modal_body_radio [type='radio']:checked,
.modal-body [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
}

.modal_body_radio [type='radio']:checked + label,
.modal_body_radio [type='radio']:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #000;
    font-family: 'Segoe UI';
    font-weight: 600;
    font-size: 13px;
}

.modal_body_radio [type='radio']:checked + label:before,
.modal_body_radio [type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 2px;
    width: 18px;
    height: 18px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: #fff;
}

.modal_body_radio [type='radio']:checked + label:before {
    border: 1px solid #535bd6;
}

.modal_body_radio [type='radio']:checked + label:after,
.modal_body_radio [type='radio']:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #535bd6;
    position: absolute;
    top: 6px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

.modal_body_radio [type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

.modal_body_radio [type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.capvar_content .form-submit-btn.input-group {
    padding-bottom: 0;
    border: 0px;
    margin-top: 15px;
}

.capvar_content button.btn[type='submit'] {
    background-color: #535bd6;
    color: #fff;
    border: 1px solid #535bd6;
}

.tab_wrapper_modal .tab input:checked ~ .tab-content {
    padding: 15px 5px 15px 15px;
}

.tab-content-text_wrapper {
    max-height: 450px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 10px;
}

.tab-content-text_wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(160, 160, 160, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

.tab-content-text_wrapper::-webkit-scrollbar {
    width: 5px;
    background-color: #a0a0a0;
}

.tab-content-text_wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(160, 160, 160, 0.3);
    background-color: #a0a0a0;
}

a.black {
    text-decoration: none !important;
}

.black {
    color: #000 !important;
}

.call_us_txt label {
    font-family: 'SegoeUI-Bold';
    font-size: 16px !important;
    display: block;
    margin-bottom: 3px !important;
    color: #535bd6 !important;
}

.call_us_txt {
    max-width: 170px;
    padding-left: 14px;
    font-size: 13px;
    font-family: 'SegoeUI';
}

.Phone_no span {
    font-family: 'SegoeUI';
    font-size: 20px;
    position: relative;
    padding-left: 10px;
}

.Phone_no {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.top-call_wrapper {
    border-bottom: 1px solid #ececec;
    padding-bottom: 15px;
}

.Phone_no span:after {
    content: '';
    position: absolute;
    height: 25px;
    width: 1px;
    background-color: #c5c5c5;
    left: 0px;
}

.conatct-form-moda h6 {
    color: #535bd6;
    font-size: 18px;
    font-family: 'SegoeUI-Bold';
    margin-bottom: 0;
    padding: 15px 0px;
}

.conatct-form-moda .form-submit-btn {
    padding: 0;
    border: 0;
}

.conatct-form-moda .form-submit-btn button.btn[type='submit'] {
    background-color: #535bd6;
    border-color: #535bd6;
    color: #fff;
}

.edit_membership_profile button.btn.btn-submit[type='submit'] {
    background-color: #535bd6 !important;
    border-color: #535bd6 !important;
}

.edit_membership_profile .form-submit-btn.input-group {
    padding-bottom: 0;
    border-bottom: 0;
}

.tab-content-text_wrapper ul.ul_inner {
    padding: 0px;
    margin: 0px;
    margin-bottom: 15px;
}

.tab-content-text_wrapper ul.ul_inner li {
    list-style: none;
    padding-left: 20px;
    position: relative;
}

.tab-content-text_wrapper ul.ul_inner li:after {
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    content: '';
    background-color: #535bd6;
    left: 0;
    top: 11px;
}

.tab-content-text_wrapper h6 {
    color: #535bd6;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 0px;
    padding: 10px 0px;
}

.__react_component_tooltip.type-info.place-right:after {
    border-right-color: #cdcdcd !important;
}

.__react_component_tooltip.type-info.place-left:after {
    border-left-color: #cdcdcd !important;
}

.tab-content-text_wrapper p {
    margin-bottom: 25px;
}

.tab-content-text_wrapper p:last-child {
    margin-bottom: 10px;
}

ol.list_style {
    margin: 0;
    /* padding: 0; */
    padding-left: 15px;
}

.main-card .react-bootstrap-table-pagination .col-md-6:first-child {
    text-align: left;
}

@media (max-width: 1750px) {
    .pmg_table_data .col-6 {
        flex: inherit !important;
        max-width: 100%;
    }
}

@media (max-width: 1600px) {
    .cls-pmg-group-section .tab-label span:after {
        left: -55px;
    }
}

@media (max-width: 1470px) {
    .cls-pmg-group-section .tab-label span:after {
        left: -43px;
    }
}

@media (max-width: 1400px) {
    .cls-pmg-group-section .tab-label span:after {
        left: -36px;
    }
}

@media (max-width: 1366px) {
    .membership_profile_popup .rodal-dialog {
        left: 8%;
        width: 90% !important;
    }
}

@media (max-width: 1024px) {
    .pspg_popup_wrapper .rodal-dialog {
        max-width: 85%;
        left: 6% !important;
    }
}

@media (max-width: 991px) {
    .blue_btn,
    .green_btn {
        padding: 11px 10px;
    }
}

@media (max-width: 768px) {
    .pspg_popup_wrapper .rodal-dialog {
        max-width: 98%;
        left: 0% !important;
    }

    .membership_profile_popup .rodal-dialog {
        left: 0%;
    }
}

@media (max-width: 767px) {
    .main-title-content {
        display: block;
        margin-bottom: 20px;
    }

    .green_btn {
        margin-left: 10px;
    }

    .blue_btn,
    .green_btn {
        font-size: 12px;
    }

    .rodal-dialog {
        position: absolute;
        top: 15% !important;
        left: 0% !important;
        width: 98% !important;
    }

    .tab_top_content .group_status {
        margin-top: 10px;
    }

    .edit_membership_profile .dynamic_data {
        flex-wrap: wrap;
    }

    .edit_membership_profile .dynamic_data label,
    .edit_membership_profile .dynamic_data input {
        width: 100% !important;
    }

    .membership_profile_popup .modal-body,
    .rodal-slideTop-enter .modal-body {
        max-height: 400px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .form-wizard-content .no-results {
        padding: 0rem;
        padding-bottom: 16px;
    }

    .tab-content .list_style {
        padding: 5px;
    }

    .tab-content ul {
        padding: 0px;
        padding-left: 10px;
    }

    .vat_list_wrapper {
        flex-wrap: wrap;
    }

    .vat_list_wrapper .list-content {
        width: 100%;
    }

    .vat_list_wrapper .date_tag {
        width: 100px;
    }

    .vet_value_content {
        max-height: 400px;
    }

    .step_count {
        position: inherit !important;
    }
}

@media (max-width: 480px) {
    .login_footer {
        flex-direction: column-reverse;
    }

    .d-flex.top-call_wrapper {
        flex-wrap: wrap;
    }

    .d-flex.call_us_txt-wrapper,
    .call_us_txt {
        width: 100%;
        max-width: 100%;
    }

    .Phone_no {
        margin-top: 10px;
        margin-left: 30px;
    }

    .Phone_no span:after {
        display: none;
    }
}

@media (max-width: 411px) {
    .name_change button {
        margin-top: 7px;
    }
}

/* .rodal-dialog {
  top: 50%!important;
  left: 50%!important;
  right: inherit!important;
  transform: translate(-50%,-50%);
 
} */
.rodal-dialog input.form-control {
    height: 30px;
}

.rodal-dialog .rw-widget-container {
    height: 30px;
    min-height: 30px;
}

.rw-dropdownlist-search,
.rw-detect-autofill {
    display: none;
}

.rw-widget-picker {
    min-height: 30px !important;
}

.rw-state-focus .rw-widget-picker {
    border-color: #545cd8 !important;
}

.rw-list-option.rw-state-selected {
    background-color: #545cd8 !important;
    border-color: #545cd8 !important;
    color: #e0f3ff !important;
}

.rw-list-option.rw-state-selected {
    font-size: 1em !important;
}

.rw-list-option {
    padding-left: 0.5em !important;
}

.left-bullet-point ul li::marker {
    color: #525ad4;
}

.breadcrumb .breadcrumb-item.active {
    cursor: text;
}

.pmg_tab_wrapper ul li::marker {
    color: #545cd8 !important;
}

.tab-content .tooltip_list:after {
    background-color: #545cd8 !important;
}

span.profile_data a {
    text-decoration: underline;
}

span.rw-placeholder {
    color: #525a64;
    font-family: 'Segoe UI';
    font-size: 13px;
    font-weight: 600;
    opacity: 0.8;
}

.pmg-admin-main .green_btn {
    font-size: 13px;
    font-family: 'SegoeUI-SemiBold';
    padding: 8px 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    text-transform: none;
    border: none;
}

.mdl-remove-group input[type='radio'] {
    left: 20px !important;
    width: 19px;
    height: 19px;
}

.mdl-remove-group .form-group,
.mdl-remove-group .mb-3 {
    position: relative;
    margin-bottom: 15px;
    padding-left: 30px;
    display: flex;
    align-items: center;
}

.mdl-remove-group .modal-header {
    justify-content: center;
    background: transparent;
    border: 0;
    padding-top: 35px;
    padding-bottom: 0;
}

.mdl-remove-group .radio-label {
    font-size: 13px;
    color: #000;
    margin-bottom: 0;
    line-height: 15px;
    font-weight: 700;
}

.rodal.mdl-remove-group h5.modal-title {
    font-size: 24px;
}

.label-textarea {
    font-size: 13px;
    color: #525a64;
    display: block;
    font-family: 'SegoeUI-SemiBold';
}

.mdl-remove-group .text-area {
    width: 100%;
    height: 90px;
    border: 1px solid #dce0e4;
    padding: 5px 10px;
}

.mdl-remove-group .label-message {
    font-family: 'SegoeUI-SemiBold';
    font-size: 16px;
    margin: 5px 0 10px;
}

.mdl-remove-group .btn-cancel {
    height: 43px;
    width: 88px;
    background-color: #fff;
    color: #919191;
    border-color: #919191;
}

.mdl-remove-group .btn-remove {
    height: 43px;
    width: 100px;
    background-color: #535bd6;
    color: #fff;
}

.mdl-remove-group .modal-footer {
    background: #fff;
    border: 0;
    padding: 0px 15px 31px;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: 2px;
    left: 2px;
    position: relative;
    background-color: #535bd6;
    content: '';
    display: inline-block;
    visibility: visible;
    border: 2px solid #fff;
}

.pmg-admin-main .pagination li {
    margin-right: 11px;
}

.pmg-admin-main .page-item.active .page-link,
.pmg-admin-main .pagination .active.page-number .page-link {
    z-index: 1;
    color: #fff;
    background-color: #545cd8;
    border-color: #545cd8;
}

.pmg-admin-main .pagination li a {
    position: relative;
    display: block;
    padding: 0.66rem 1.1rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #636c78;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.cls-pmg-allocation .d-block.w-100 {
    display: flex !important;
}

.cls-groups-members-section button.btn.btn-primary.action_btn {
    margin-left: 10px;
    width: 100%;
    max-width: 29px;
    height: 29px;
    padding: 0 7px;
}

.cls-pmg-allocation button.btn.btn-primary.action_btn {
    margin-left: 10px;
    width: 100%;
    max-width: 29px;
    height: 29px;
    padding: 0;
}

.cls-pmg-allocation .react-bootstrap-table {
    max-height: 340px;
    overflow-y: auto;
    border: 1px solid #dfdfdf;
}

.cls-pmg-allocation thead {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 9;
}

.cls-pmg-allocation .section-title {
    padding: 17px 18px;
    border: 1px solid #dee2e6;
    border-width: 1px 1px 0px 1px;
}

.pmg-admin-main .without_title_content .pmg_table_data {
    padding-top: 20px;
}

.cls-pmg-allocation .section-title span {
    font-size: 18px;
}

.cls-pmg-allocation .table tr td {
    padding: 10px 15px !important;
}

.cls-pmg-group-section {
    max-height: 825px;
    overflow-y: auto;
    border: 1px solid #dfdfdf;
}

.cls-pmg-group-section .section-title {
    padding: 12px 18px;
    border: 1px solid #dee2e6;
    border-width: 1px 1px 0px 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cls-pmg-group-section .green_btn {
    margin: 0;
}

.cls-pmg-allocation .form-control {
    position: relative !important;
    transition: all 0.2s !important;
    /* height: 30px !important; */
    padding-top: 2px !important;
    font-size: 14px !important;
}

.cls-pmg-group-section .d-block.w-100 {
    display: flex !important;
}

.cls-pmg-group-section button.btn.btn-primary.action_btn {
    margin-left: 10px;
    width: 100%;
    max-width: 29px;
    height: 29px;
    padding: 0 7px;
}

.cls-pmg-group-section button.btn.btn-primary.action_btn:first-child {
    margin-left: 0;
}

.cls-pmg-group-section .section-title {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
}

.cls-pmg-group-section .cls-pmg-tabs {
    margin-top: 20px;
}

.cls-pmg-group-section .tab-label {
    margin: 0px 20px 0 20px;
    align-items: center;
}

.cls-pmg-group-section .section-title {
    padding: 12px 18px;
    border: 1px solid #dee2e6;
    border-width: 0px 0px 1px 0px;
    z-index: 99;
}

.cls-pmg-group-section .section-title span {
    font-size: 18px;
}

.cls-pmg-group-section thead tr th {
    white-space: nowrap;
}

.cls-pmg-group-section .tab input:checked ~ .tab-content {
    margin: 0 20px 0 20px;
    max-height: 365px;
    overflow-y: scroll;
    padding-top: 0;
    padding-left: 0;
}

.cls-pmg-group-section thead {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
}

.cls-pmg-group-section tbody tr {
    white-space: nowrap;
}

.cls-pmg-group-section .form-control {
    max-width: 135px;
    width: 100%;
    font-size: 14px;
}

.mdl-more-info-popup .form-control {
    max-width: 100%;
}

.cls-pmg-group-section .tab-label {
    padding: 2px 15px;
}

.cls-pmg-group-section .tab-label span:first-child {
    font-family: 'SegoeUI-Bold';
}

.cls-pmg-group-section .tab-label {
    font-family: 'SegoeUI';
    background: #dfdfdf;
}

.cls-pmg-group-section .tab-label span {
    position: relative;
}

.cls-pmg-group-section .tab-label span:after {
    position: absolute;
    content: '';
    width: 1px;
    height: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    left: -8px;
    top: 5px;
}

.cls-pmg-group-section .tab-label span:first-child::after {
    content: none;
}

.pmg-admin-main .membership_profile_popup h5.modal-title {
    text-transform: capitalize;
    margin-bottom: 0;
    color: #000;
}

.rodal.mdl-remove-group h5.modal-title {
    font-size: 24px;
    text-transform: none;
    color: #000;
    margin-bottom: 0;
}

.cls-pmg-placement-step2 .form-group {
    display: flex;
    justify-content: space-between;
}

.cls-pmg-placement-step2 .mb-3 {
    width: 100%;
    max-width: 100px;
}

.cls-pmg-placement-step2 .input-madicine {
    display: block;
}

.cls-pmg-placement-step2 .input-madicine .mb-3 {
    width: 100%;
    max-width: 100%;
}

.cls-groups-members-section tbody tr {
    line-height: 35px;
}

textarea.text-area::placeholder {
    font-size: 13px;
}

.cls-prts-info input.form-control.inp-cy-revenue {
    width: 30%;
    margin-left: auto;
}

.cls-prts-info input.form-control.inp-fulltime-dvms {
    width: 15%;
    margin-left: auto;
}

.rw-i-caret-up:before {
    content: '\f104' !important;
    color: #545cd8;
    font-weight: 600;
}

.rw-multiselect-input {
    display: none;
}

.breadcrumb-link {
    font-family: 'SegoeUI';
    font-size: 14px;
    color: #535bd6 !important;
    cursor: pointer;
}

.absolute-container {
    position: absolute;
}