.request-content-form {
    button.close {
        padding: 5px 13px 5px 10px;
    }

    .cancel-btn,
    .cancel-btn:hover {
        font-family: 'SegoeUI-Semibold';
        font-size: 15px !important;
        color: #919191;
        width: 70px;
        height: 29px;
        border: 1px solid #919191;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .description-text {
        font-family: 'SegoeUI-Semibold';
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 25px;
    }

    .form-control:focus {
        box-shadow: none;
    }

    .form-group input,
    .form-group textarea {
        border: 1px solid #dce0e4;
        color: #888f96;
        font-family: SegoeUI-Semibold, sans-serif;
        font-size: 13px;
    }

    .form-group textarea {
        resize: none;
        height: 200px;
    }

    .form-group textarea::-webkit-scrollbar {
        width: 6px;
        background-color: #c1c1c157;
    }

    .form-group textarea::-webkit-scrollbar-thumb {
        background-color: #a0a0a0;
        border-radius: 5px;
    }

    .loader-submit {
        height: 29px;
        width: 70px;
    }

    .buttons-wrapper {
        display: flex;
        justify-content: flex-end;

        :not(:first-child) {
            margin-left: 10px;
        }
    }
}
