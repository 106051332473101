.documents-request-form {
    .buttons-wrapper {
        display: flex;
        justify-content: flex-end;
    }

    .documents-request-form-header {
        gap: 2em;
    }

    .link-button {
        cursor: pointer;
        text-decoration: underline;
        color: #545cd8;
    }

    .financial-period-cell {
        white-space: nowrap;
    }

    .display-name-wrapper {
        font-family: 'SegoeUI-Semibold';
        display: flex;
        align-items: center;
        line-height: 1.25;
    }

    .expanded-text-with-badge {
        display: flex;
    }

    .unread-comments-badge {
        min-width: 23px;
        margin-left: 5px !important;
    }

    .table th:nth-child(1) {
        max-width: 100px;
        width: 100px !important;
        text-wrap: balance;
    }

    .lock-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
