.edit-discussion {
    height: 298px;

    .flex-container {
        display: flex;
    }

    svg.svg-inline--fa.fa-info-circle.fa-w-16.input-group-text {
        padding: 0 !important;
        border: 0 !important;
        color: #535bd6;
        outline: 0 !important;
        background: transparent !important;
        margin-bottom: 2px !important;
        margin-left: 1px !important;
        cursor: pointer;
    }

    .edit-detail {
        padding: 7px 30px 0px 30px !important;

        span {
            font-size: 13px !important;
            color: #888f96 !important;
            margin-bottom: 9px;
            font-family: 'SegoeUI-Semibold' !important;
        }

        .lastEditStatuswrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 21px;
        }

        label {
            margin-bottom: 0px !important;
        }

        .intro-text-wrapper {
            padding-top: 9px;
            padding-bottom: 14px;
        }

        .btn-wrapper {
            padding-top: 26px;
            padding-bottom: 7.35px;
        }
    }

    .author-input label {
        padding-left: 0px;
    }

    .author-input span {
        margin-left: 0px;
    }

    .intro-text-wrapper {
        padding-top: 0px !important;
        padding-bottom: 2px !important;

        label {
            padding-bottom: 6px !important;
        }

        .originally-based-col label {
            padding-bottom: 5px !important;
        }

        textarea {
            height: 42px;
            width: 100%;
            resize: none;
            padding: 6px 10px;
            border: 1px solid #dce0e4 !important;
            border-radius: 0.25rem;
            outline: 0;
            font-size: 13px !important;
            color: #888f96 !important;
            font-family: 'SegoeUI-Semibold' !important;
        }

        textarea::-webkit-scrollbar {
            width: 6px;
            background-color: #c1c1c157;
        }

        textarea::-webkit-scrollbar-thumb {
            background-color: #a0a0a0;
            border-radius: 5px;
        }

        .text-count {
            text-align: right;

            span {
                font-size: 13px !important;
                color: #888f96 !important;
                margin-bottom: 9px;
                font-family: 'SegoeUI-Semibold' !important;
            }
        }
    }

    .title-wrapper {
        align-items: start !important;
        padding-bottom: 2px !important;
    }

    .title-wrapper,
    .edited-post-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .edited-post-wrapper label {
        padding-bottom: 6px !important;
    }

    .tile-edit-btn-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 17px 31px 0 31px;

        h5 {
            text-transform: none !important;
            font-size: 20px !important;
            font-family: 'SegoeUI-Semibold' !important;
            display: inline-block;
            color: #000000;
        }

        label {
            padding-bottom: 5px;
        }

        .input-count-wrapper {
            width: 50%;
            margin-left: auto;

            .text-count {
                text-align: right;

                span {
                    margin-right: 23px;
                    font-size: 13px !important;
                    color: #888f96 !important;
                    font-family: 'SegoeUI-Semibold' !important;
                    text-align: right;
                }
            }
        }

        input {
            width: 100%;
            max-width: 90.7%;
            border: 1px solid #dce0e4 !important;
            border-radius: 0.25rem;
            outline: 0;
            padding: 2px 11px 4px 10px !important;
            height: 30px !important;
            font-size: 13px !important;
            color: #888f96 !important;
            font-family: 'SegoeUI-Semibold' !important;
        }
    }

    .form-group {
        margin-bottom: 0 !important;
    }

    .save-btn {
        background: #535bd6 !important;
        border-color: #535bd6 !important;
        color: #fff;
        height: 29px;
        padding: 2px 5px 3px 5px;
        width: 53px;
    }

    .edit-title-body.modal-body label {
        color: #525a64 !important;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
        padding-bottom: 3px;
    }

    .originally-based-col .input-group-text {
        padding: 0 7px;
        height: 30px;
        width: 33px;
        text-align: center;
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-right: 0 !important;
    }

    .author-input input[type='text'] {
        width: 100%;
    }

    .edit-title-body.modal-body input[type='text'] {
        width: 100%;
        border: 1px solid #dce0e4 !important;
        border-radius: 0.25rem;
        outline: 0;
        padding: 2px 11px 4px 10px !important;
        height: 30px !important;
        font-size: 13px !important;
        color: #888f96 !important;
        margin-bottom: 9px;
        font-family: 'SegoeUI-Semibold' !important;
    }

    .originally-based-col input[type='text'] {
        border-bottom-left-radius: 0 !important;
        border-top-left-radius: 0 !important;
        background: #f0f4f7;
    }

    .author-input {
        max-width: 213px !important;
        width: 100%;
    }

    .originally-based-col {
        max-width: 213px !important;
        width: 100%;
    }

    .intro-text-wrapper .introInput input[type='text'] {
        min-height: 61px !important;
    }

    .form-control:focus {
        box-shadow: none !important;
    }

    .form-control.is-invalid {
        border: 1px solid red !important;
        border-radius: 0.25rem;
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: URL("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: center right 5px;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .invalid-feedback {
        display: none !important;
    }

    .loader-submit .loader-inner>div {
        margin: 2px !important;
    }
}

@media (max-width: 514px) {
    .edit-discussion {
        height: auto;

        .edit-detail {
            padding: 7px 30px 17px 30px !important;
        }

        .tile-edit-btn-wrapper .input-count-wrapper .text-count span {
            margin-right: 0px;
        }

        .originally-based-col {
            padding-top: 0 !important;
        }

        .save-btn {
            margin-left: auto;
            width: 100%;
            max-width: 100%;
            margin-top: 6px;
        }

        .tile-edit-btn-wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            padding: 17px 31px 0 31px;
            flex-direction: column;

            h5 {
                width: 100% !important;
                font-size: 18px !important;
                line-height: 20px;
                margin-bottom: 10px;
            }

            .input-count-wrapper {
                width: 100%;
            }

            input {
                width: 100%;
                max-width: 100%;
            }
        }

        .intro-text-wrapper {
            textarea {
                height: 52px;
                width: 100%;
                resize: none;
                padding: 6px 10px;
                border: 1px solid #dce0e4 !important;
                border-radius: 0.25rem;
                outline: 0;
                font-size: 13px !important;
                color: #888f96 !important;
                font-family: 'SegoeUI-Semibold' !important;
                height: 60px !important;
            }
        }

        .title {
            width: 100% !important;
            max-width: 100% !important;
            padding-bottom: 15px;
        }

        .title-wrapper,
        .edited-post-wrapper {
            flex-direction: column;
        }

        .author-input {
            max-width: 100% !important;
            width: 100% !important;
        }
    }
}