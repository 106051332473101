.standardization-services-host {
    .rw-widget-input.rw-widget-picker.rw-widget-container {
        span {
            margin-bottom: 0;
        }
    }

    .tooltip-content {
        position: fixed;
    }

    .referenced-text {
        color: #545cd8;
        cursor: pointer;
    }

    .rw-picker-caret {
        display: flex;
    }

    th span {
        display: inline;
    }

    .complete-payment-button {
        margin: 0px 10px 0px 0px;
        white-space: nowrap;
        height: 35px;
    }

    .issue-refund-button {
        margin: 0px 10px 0px 0;
        white-space: nowrap;
        height: 35px;
    }

    .admin-notes-button {
        margin: 0px 10px 0px 10px;
        height: 35px;
    }

    .standardization-services-table {
        .react-bootstrap-table {
            overflow: auto;
        }

        .dvm-table-container {
            display: flex;
            justify-content: space-between;

            .edit-icon {
                cursor: pointer;
                color: #535bd6;
            }
        }

        .action-table-container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .edit-icon {
                cursor: pointer;
                color: #535bd6;
            }
        }

        .table thead tr {
            background-color: #dce0e4;
            color: #525a64;
            font-size: 13px;
            font-family: 'SegoeUI-Semibold';
        }

        .table thead th {
            cursor: pointer;
        }

        .table tbody td {
            color: #888f96;
            font-size: 13px;
            font-family: 'SegoeUI-Semibold';
        }

        .tooltip-content {
            font-size: 13px !important;
            font-weight: 300;
            font-family: 'SegoeUI';
            text-transform: none;
            background-color: #ebebeb !important;
            border: 1px solid #cdcdcd;
            border-radius: 5px;
            box-shadow: 0 0 3px 0 rgba(1, 1, 1, 0.09);
            font-size: 14px;
            color: #656565 !important;
            line-height: 1.15;
            z-index: 999;
        }

        .tooltip-content {
            max-width: 756px;

            h6 {
                font-size: 12px;
                color: #000;
                font-family: 'SegoeUI-Semibold';
                margin-bottom: 20px;
            }

            p {
                font-size: 13px;
                font-family: 'SegoeUI';
                color: #525a64;
                line-height: 18px;

                span {
                    font-family: 'SegoeUI-Semibold';
                }
            }
        }

        .tooltip-content .place-left .arrow {
            background: transparent;
            transform: rotate(90deg);
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;

            border-bottom: 10px solid #cdcdcd;
            right: -15px !important;
        }

        .react-bootstrap-table {
            margin-bottom: 1em;
            .table {
                margin: 0;
            }

            .currency {
                display: flex;
                justify-content: flex-end;
            }
        }

        .react-bootstrap-table::-webkit-scrollbar {
            cursor: pointer;
            height: 6px;
            width: 4px;
            background-color: #e5e5e5;
            border-radius: 10px;
        }

        .react-bootstrap-table::-webkit-scrollbar-thumb {
            background-color: #a0a0a0 !important;
            height: 4px;
            width: 3px;
            border-radius: 5px;
        }

        .total-credits div {
            margin-left: 10px;
        }

        .line-separator {
            margin: 0 1.5em;
            border: 1px solid #888f9645;
            height: 30px;
            margin-top: 0.5em;
        }

        .rw-dropdownlist-search {
            display: block !important;
        }

        .filters-bar {
            color: #888f96 !important;
            font-size: 13px;
            font-family: 'SegoeUI-Semibold';

            .form-group {
                display: flex;
                flex-wrap: wrap;
            }

            .input-group-text {
                border-radius: 5px 0px 0px 5px;
                border: none !important;
                border-right: 1px solid #ced4da !important;

                input {
                    max-width: 270px;
                }
            }

            .small-border {
                border: 1px solid #ced4da;
                border-radius: 5px;
                height: 30px;
                align-items: center;
                width: 125px;
            }

            .filter-item {
                display: flex;
                align-items: center;
                margin-top: 0.5em;

                label {
                    margin: 0;
                }

                label + div {
                    margin-left: 0.5em;
                }
            }

            .rw-state-focus .rw-widget-picker {
                border-color: #ccc !important;
            }

            .rw-dropdown-list {
                width: 150px !important;
            }

            .rw-dropdown-list-value {
                color: #888f96 !important;
            }

            .rw-list-option {
                color: #888f96;
            }
        }

        @media (max-width: 600px) {
            .line-separator {
                display: none;
            }
            .filters-bar .form-group {
                flex-direction: column;
                align-content: space-between;
            }
            .filters-bar .filter-item {
                justify-content: space-between;
            }
        }
    }
}

.subscriptions-page {
    .issue-refund-modal,
    .confirmation-modal,
    .change-employees-number-modal {
        .modal-footer {
            padding: 0.5rem 1rem;
        }
    }

    .confirmation-modal {
        .form-group {
            margin-bottom: 0px;
        }
    }
}
