.access-restricted-modal-wrapper {
    & .rodal-dialog {
        top: 20%;
    }

    & .access-restricted-modal-content {
        width: fit-content;
        height: 100%;
        margin-bottom: 1em;
    }

    & .btn-wrapper {
        display: flex;
        justify-content: flex-end;
        gap: 1em;

        & .close-button {
            background-color: #fff;
            border: 1px solid gray;
            color: gray;
        }
    }
}

@media (max-width: 1366px) {
    .access-restricted-modal-wrapper .rodal-dialog {
        left: 10%;
    }
}
