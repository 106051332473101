.pending-profile-modal {
    max-width: 600px !important;

    .pending-profile-modal-footer{
        background: #fff;
        border: none;
        padding: 0 22px 15.76px 15px !important;
    }
    
    .pending-profile-modal-body {
        padding: 1rem !important;
    }

    .pending-profile-button{
        padding-top: 0 !important;
    }

    .cancel-btn{
        max-width: 130px !important;
    }

}