.page-header {
    display: flex;
    justify-content: space-between;

    .page-title {
        display: flex;
        flex-direction: column;
        align-self: center;
    }

    .header-tools-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin: 0 10px;
    }

    .header-tools {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 10px 0;
    }

    .breadcrumb.breadcrumb {
        margin-bottom: 0.5rem;
    }
}

.pmg-page-header {
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
    padding: 15px 0px 13px;

    .page-title {
        h3 {
            color: #000;
            display: flex;
            font-family: SegoeUI-Bold;
            font-size: 14px;
            margin-bottom: 0;
            text-transform: uppercase;
        }
    }
}