.link-action-popup {
    font-size: 14px;

    .rodal-dialog {
        top: 15%;
    }

    .party-section {
        font-size: 16px;
    }

    .sections-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .action-type-section {
        display: flex;
        flex-direction: column;
    }

    .section-header {
        font-family: 'SegoeUI-Bold';
        margin-bottom: 0.5rem;
        font-size: 16px;
    }

    .buttons-wrapper {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
    }
}
