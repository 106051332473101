.view-details {
    .edit-title-body.modal-body {
        padding: 17px 24px 17.61px 24px;
    }

    .author-posted-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .input-title input[type='text'] {
        width: 100%;
    }

    .author-input input[type='text'] {
        width: 100%;
    }

    .edit-title-body.modal-body label {
        color: #525a64 !important;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
        margin-bottom: 6px;
    }

    .knowledge-contetnt-popup-header h5.modal-title {
        text-transform: none !important;
        font-size: 18px !important;
        font-family: 'SegoeUI-Semibold' !important;
        display: inline-block;
        color: #000000;
    }

    button.close {
        padding: 4px 15px 5px 12px !important;
    }

    .knowledge-contetnt-popup-header .modal-header {
        padding: 18px 16px 18px 20px;
    }

    .author-input {
        max-width: 213px !important;
        width: 100%;
    }

    .no-description-wrapper {
        padding-top: 11px;
    }

    .intro-text-wrapper .introInput input[type='text'] {
        min-height: 61px !important;
    }

    .intro-text-area textarea#exampleFormControlTextarea1 {
        width: 100%;
        resize: none;
        padding: 8px 10px;
        border: 1px solid #dce0e4 !important;
        border-radius: 0.25rem;
        outline: 0;
        font-size: 13px !important;
        color: #888f96 !important;
        font-family: 'SegoeUI-Semibold' !important;
    }

    .save-btn,
    .save-btn:hover {
        background: #535bd6 !important;
        border-color: #535bd6 !important;
        height: 29px;
        padding: 2px 5px 3px 5px;
        max-width: 53px;
        width: 100%;
    }

    .save-btn:not(:disabled):not(.disabled):active:focus,
    .save-btn:not(:disabled):not(.disabled).active:focus,
    .show > .save-btn.dropdown-toggle:focus {
        box-shadow: none !important;
        background-color: transparent !important;
        color: #ffffff !important;
        background: #535bd6 !important;
        border-color: #535bd6 !important;
    }

    .modal-content {
        border: 0 !important;
    }

    .btn-wrapper {
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .btn-wrapper button {
        margin-left: 10px;
    }

    .edit-detail {
        span {
            font-size: 13px !important;
            color: #888f96 !important;
            margin-bottom: 9px;
            font-family: 'SegoeUI-Semibold' !important;
        }

        .title-wrapper,
        .edited-post-wrapper,
        .lastEditStatuswrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 21px;
        }

        label {
            margin-bottom: 0px !important;
        }

        .intro-text-wrapper {
            padding-top: 9px;
            padding-bottom: 14px;
        }

        .btn-wrapper {
            padding-top: 26px;
            padding-bottom: 7.35px;
        }
    }

    .author-input label {
        padding-left: 21px;
    }

    .author-input span {
        margin-left: 21px;
    }

    .intro-text-wrapper {
        padding-top: 11px !important;
        padding-bottom: 24px !important;
    }

    .intro-text-wrapper label {
        padding-bottom: 5px;
    }

    .title-wrapper label {
        padding-bottom: 3px;
    }

    .click-wish-removal {
        padding-top: 3px;
        padding-right: 24px;
        position: relative;

        .info-svg {
            font-size: 1rem;
            margin-left: 4px;
            margin-bottom: 2px;
        }
    }

    .click-wish-removal h5 span {
        display: inline-block;
        margin-bottom: 0 !important;
        vertical-align: middle;
    }

    .edit-title-body.edit-detail.modal-body {
        padding: 5px 31px 30.1px 31px;
    }

    .tile-edit-btn-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 17px 31px 0 31px;

        h5 {
            text-transform: none !important;
            font-size: 20px !important;
            font-family: 'SegoeUI-Semibold' !important;
            display: inline-block;
            color: #000000;
        }

        label {
            padding-bottom: 5px;
        }
    }

    .click-wish-removal h5 {
        font-size: 16px !important;
        font-family: 'SegoeUI';
        color: #000000;
        font-weight: 400;
        line-height: 20px;
    }

    .initiate-removal-btn-div {
        text-align: center;
        margin-top: 23px;

        .inital-removal-voting-btn {
            background-color: #ed7d31;
            color: #ffffff;
            height: 29px;
            padding: 3px 5px 4px 5px;
            width: 179px;
            border: 0;
            text-align: center;
        }

        .inital-removal-voting-btn:not(:disabled):not(.disabled):active,
        .inital-removal-voting-btn:not(:disabled):not(.disabled).active,
        .show > .inital-removal-voting-btn.dropdown-toggle {
            background-color: #ed7d31 !important;
            color: #ffffff !important;
            background: #ed7d31 !important;
        }
    }
}

@media (max-width: 514px) {
    .view-details {
        .title-wrapper,
        .edited-post-wrapper,
        .lastEditStatuswrapper {
            flex-direction: column;
            padding-bottom: 21px;
        }

        .originally-based-col {
            max-width: 100% !important;
            width: 100%;
            padding-top: 15px;
        }

        .author-input {
            max-width: 100% !important;
            width: 100% !important;
        }

        .author-input label {
            padding-left: 0 !important;
        }

        .author-input span {
            margin-left: 0 !important;
        }

        .click-wish-removal {
            padding-right: 0px;
        }

        h5 {
            font-size: 18px !important;
        }

        .save-btn {
            margin-top: 9px;
            width: 100%;
            max-width: 100% !important;
        }

        .title {
            width: 100% !important;
            max-width: 100% !important;
            padding-bottom: 15px;
        }

        .author-input {
            width: 100% !important;
            max-width: 100% !important;
        }
    }
}
