.questionnaire-stages-host {
    .link-button {
        color: #535bd6 !important;
        cursor: pointer;
    }

    .questionnaire-stages-host-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .right-header {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    .stage-name-header {
        width: auto !important;
    }

    .stage-navigation-breadcrumb {
        padding-left: 15px;

        .link-text {
            color: #545cd8;
            cursor: pointer;
            text-decoration: underline;
        }

        .node-separator {
            white-space: break-spaces;
        }
    }
}
