.stages-table {
  .action-cell {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .disabled-row {
    pointer-events: none;
    opacity: 0.5;
    background-color: #f0f0f0;
  }

  .MuiTablePagination-root {
    display: none;
  }

  .MuiDataGrid-footerContainer {
    display: none !important;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .text-bold {
    font-weight: bold !important;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
  }
}