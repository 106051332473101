.opportunity-details {
    margin-top: 30px;

    .header-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .details-table-wrapper{
        padding: 5px 21px;
    }

    .card-header {
        padding: 0px 20px;
    }

    .status-container, .process-type-container {
        text-transform: none;
        font-size: 14px;

        .label {
            font-family: 'SegoeUI';
            font-weight: normal;
        }
    }
}