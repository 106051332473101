.saved-card {
    display: flex;
    align-items: center;
    font-family: 'SegoeUI-Bold';

    .payment-system-name {
        font-size: 12px;
        margin-right: 5px;
    }

    .last-digits {
        font-size: 12px;
    }

    .expires {
        font-size: 9px;
    }

    img {
        width: 30px;
        margin-right: 3px;
    }

    .card-system-image {
        padding: 0 5px;
    }
}
