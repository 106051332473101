.poc-profile-modal.profile-view-pop-up {
    max-width: 464px !important;
    background: #fff;
    margin: 0px;
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100% !important;

    .direct-button {
        align-self: flex-end;
    }

    .name-wrapper {
        display: flex;
        justify-content: center;
        padding-top: 7px;
        gap: 0.5em;
    }

    .save-btn {
        background: #535bd6 !important;
        border-color: #535bd6 !important;
        color: #fff;
        height: 29px;
        padding: 2px 5px 3px 5px;
        width: 53px;
    }

    .tile-edit-btn-wrapper {
        display: flex;
        flex-direction: row-reverse;
        padding: 0 47px 0 31px;
    }

    .delete-modal-header .modal-header {
        background: #fff !important;
        border-bottom: 0;
        padding: 17px 18px 0px 0px !important;
        height: 8px;
    }

    .profile-view-pop-up-body .delete-image-div {
        text-align: center;
        padding-bottom: 0px;
    }

    .profile-view-pop-up-body .delete-image-div .poc-avatar-icon {
        display: flex;
        justify-content: center;
    }

    .profile-view-pop-up-body .delete-image-div .poc-avatar-icon img {
        width: 70px;
        height: 70px;
        border-radius: 100%;
    }

    .profile-view-pop-up-body .delete-image-div .poc-avatar-icon .avatar-member-icon {
        width: 70px;
        height: 70px;
        border-radius: 100%;
    }

    .profile-view-pop-up-body .delete-image-div .poc-avatar-icon .avatar-member-initials {
        font-family: 'SegoeUI-Semibold';
        font-size: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .profile-view-pop-up-body .delete-image-div h2 {
        font-size: 18px;
        color: #000;
        font-family: 'SegoeUI-Semibold';
        margin-bottom: 0;
        place-self: center;
    }

    .profile-view-pop-up-body .profile-view-wrapper {
        padding: 15px 15px 15px 15px !important;
        display: flex;
        justify-content: space-between;
        padding-bottom: 21px;
    }

    .profile-view-pop-up-body .profile-view-wrapper.center {
        justify-content: space-evenly !important;
    }

    @media (max-width: 375px) {
        .profile-view-pop-up-body .profile-view-wrapper {
            flex-wrap: wrap;
        }

        .profile-view-pop-up-body .profile-view-wrapper .status-title.left {
            margin-bottom: 15px;
        }
    }

    .profile-view-pop-up-body .profile-view-wrapper .status-title {
        color: #525a64 !important;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
    }

    .profile-view-pop-up-body .profile-view-wrapper .status-title.full {
        width: 100%;
    }

    .profile-view-pop-up-body .profile-view-wrapper .status-title.right {
        max-width: 140px;
        width: 100%;
    }

    .profile-view-pop-up-body .title {
        width: 50% !important;
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }

    .profile-view-pop-up-body .profile-view-wrapper .input-title a {
        font-size: 13px !important;
        text-decoration: underline;
        color: #535bd6 !important;
        font-family: 'SegoeUI-Semibold' !important;
    }

    .input-title {
        font-size: 13px !important;
        font-family: 'SegoeUI-Semibold' !important;
        color: #888f96;
    }

    .badge {
        display: block;
    }

    .modal-header .close {
        z-index: 9999;
        padding: 4px 15px 5px 12px !important;
    }

    .input-title.mb-space-5 {
        margin-bottom: 5px;
    }
}