.available-credits {
    background-color: #e6e8ff;
    border-radius: 5px;
    padding: 5px 15px 5px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    min-width: 44%;
    justify-content: space-evenly;

    .bold {
        font-family: 'SegoeUI-Bold';
        padding: 5px 0px;
    }
}
@media (max-width: 715px) {
    .available-credits {
        margin: 10px 0;
        padding: 5px 15px 5px 15px;
        width: 100%;
    }
}
