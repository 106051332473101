.new-stage-modal-container {
  .new-stage-modal {
    top: 25%;
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
  }

  .text-danger {
    color: black !important;
  }

  @media only screen and (max-width: 1024px) {
    .new-stage-modal {
      top: 25%;
      max-height: 50vh;
    }
  }

  .no-content {
    margin-top: 100px;
  }

  .rodal-mask {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .rodal-dialog {

    top: 5% !important;

    @media (max-width: 1366px) {
      left: 21%;
      top: 10% !important;
    }

    @media (max-width: 1199px) {
      top: 20%;
      left: 10%;
      width: 42% !important;
    }

    @media (max-width: 599px) {
      top: 20%;
      left: 10%;
      width: 72% !important;
    }
  }
}