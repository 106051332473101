.file-uploads-list {
    max-height: 200px;
    overflow: auto;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1px 0px;

    &::-webkit-scrollbar {
        width: 6px;
        border-radius: 5px;
        background-color: #c1c1c157;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #a0a0a0;
        border-radius: 5px;
    }
}