.image-cropping-tool {
    .cropper-wrapper {
        min-height: 400px;

        .cropper-modal {
            background-color: white;
        }
    }

    .zoom-buttons {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 999;
        border-width: 2px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.2);
        border-radius: 4px;

        .zoom-btn {
            height: 30px;
            width: 30px;
            background-color: white;
            display: flex;
            align-items: center;
            border: none;
            justify-content: center;

            svg {
                font-size: 14px !important;
            }
        }

        .zoom-btn:first-child {
            border-bottom: 1px solid rgb(204, 204, 204);
        }
    }

    .cropper-container {
        min-height: 100px;
        max-width: 1500px;
        min-width: 300px;
    }

    .dropzone {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 11px 10px 15px 10px;
        border-width: 1px;
        border-radius: 0.25rem;
        border-color: #dce0e4;
        border-style: solid;
        background-color: #ffffff;
        color: #888f96;
        outline: none;
        transition: border 0.24s ease-in-out;

        .drop-text {
            font-family: 'SegoeUI-Semibold' !important;
            margin-top: 15px;
            margin-bottom: 10px;
        }

        .browse-btn {
            background: #535bd6 !important;
            border-color: #535bd6 !important;
            width: 108px;
            height: 29px;
            padding: 0px 3px 1px 3px;
            color: #ffffff;
            font-family: 'SegoeUI-Semibold';
            font-size: 14px;
            border: 0;
            border-radius: 0.25rem;
            margin-top: 11px;
            cursor: pointer;
        }

        .or-option {
            font-family: 'SegoeUI-Semibold';
        }
    }

    .buttons-wrapper {
        margin-top: 1rem;
        text-align: right;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .cancel-btn {
            color: #919191;
            border: 1px solid #919191;
            max-width: 70px !important;
            height: 29px;
            padding: 0px 3px 1px 3px;
            font-size: 14px;
            background-color: transparent;
            border-radius: 0.25rem;
            cursor: pointer;
            font-size: 14px !important;
            width: 100%;
        }

        .reset-btn {
            color: #919191;
            border: 1px solid #919191;
            max-width: 90px !important;
            height: 29px;
            padding: 0px 3px 1px 3px;
            font-size: 14px;
            background-color: transparent;
            border-radius: 0.25rem;
            cursor: pointer;
            font-size: 14px !important;
            width: 100%;
        }

        .apply-btn {
            color: #ffffff;
            border: 0;
            width: 72px;
            height: 29px;
            padding: 0px 3px 1px 3px;
            background-color: #535bd6;
            border-radius: 0.25rem;
            cursor: pointer;
            font-size: 14px !important;
        }

        &>*:not(:first-child) {
            margin-left: 1rem;
        }
    }
}