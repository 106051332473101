.leave-poc-modal-popup{
    h5 {
        font-size: 18px !important;
    }

    .buttons-content {
        margin-top: 20px;
    }

    .popup-header {
        color: black;
        font-weight: normal;
    }

    .rodal-dialog {
        max-width: 460px !important;
        top: 25%;

        .row {
            padding: 1rem 0;
            justify-content: center;
    
            .col-md-11 {
                text-align: center !important;
                font-size: 18px;

                .list-header-text {
                    text-align: start;
                }

                ul {
                    text-align: start;
                    padding-left: 20px;
                }
            }

            .bold {
                font-weight: bold;
            }
        }

        @media only screen and (max-width: 767px) {
            top: 25% !important;
        }
    }

    button {
        white-space: nowrap;
        border: 0;
        display: inline-block;
        height: 30px;
        line-height: 28px;
        padding: 0 10px;
        color: #fff;
        border-radius: 4px;
        font-size: 15px;
        text-decoration: none;
        text-transform: capitalize;
    }

    .confirm-button {
        margin-left: 10px;

        @media only screen and (max-width: 767px) {
            margin-left: 5px;
        }
    }

    .close-button {
        border: 1px solid;
        border-color: gray;
        background-color: white;
        color: gray;
    }

    .modal-body {
        padding: 0 1rem;
    }

    .modal-footer {
        padding: 0 1rem 1rem 1rem;
        border: none;
        background-color: inherit;
    }

    @media (max-width: 575px) {
        .modal-footer {
            justify-content: flex-end !important;
        }
    }
}
