.payment-history {
    th {
        cursor: pointer;
    }

    .referenced-text {
        color: #545cd8 !important;
        font-size: 13px;
    }

    .user-details-popup {
        .modal-title {
            color: #000;
            margin-bottom: 0;
        }

        .card-body {
            flex: 1 1 auto;
            padding: 1.25rem;
        }

        .table th,
        .table td {
            padding: 0.55rem;
            color: #525a64;
            font-size: 13px;
            font-family: 'SegoeUI-Semibold';
        }
    }

    .donor-name-cell {
        display: flex;
        align-items: center;

        .donor-name-span {
            white-space: nowrap;
        }

        .direct-button {
            margin-left: 0.3rem;
        }
    }

    table th,
    td {
        text-align: left;
    }

    .table thead tr {
        background-color: #dce0e4;
        color: #525a64;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';

        th:first-child span {
            margin-left: 6px !important;
        }

        th:first-child label {
            display: inline-block !important;
            margin: 0;
            height: 29px !important;
            border: 1px solid #dce0e4 !important;
            border-radius: 0.25rem;
            color: #888f96 !important;
            font-size: 13px;
            font-family: 'SegoeUI-Semibold';

            input {
                color: #888f96 !important;
                font-size: 13px;
                font-family: 'SegoeUI-Semibold';
                margin: 0;
                padding: 2px 15px 3px 12px;
                font-family: 'SegoeUI-Semibold';
                font-weight: 400 !important;
            }
        }
    }

    tbody td {
        color: #888f96;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
    }

    .link {
        cursor: pointer;
        color: #545cd8;
        text-decoration: underline;
    }

    .seletced-anonymity-tooltip-wrapper {
        .info-tooltip span {
            color: #656565;
            font-size: 13px !important;
            font-weight: 300;
            font-family: 'SegoeUI';
            text-transform: none;
        }

        text-wrap: nowrap;
        display: inline-block;
    }

    tbody .selected {
        background-color: #e6e8ff !important;
    }

    .react-bootstrap-table {
        overflow: auto;
        margin-bottom: 1em;

        .table {
            margin: 0;
        }
    }

    .react-bootstrap-table::-webkit-scrollbar {
        cursor: pointer;
        height: 6px;
        width: 4px;
        background-color: #e5e5e5;
        border-radius: 10px;
    }

    .react-bootstrap-table::-webkit-scrollbar-thumb {
        background-color: #a0a0a0 !important;
        height: 4px;
        width: 3px;
        border-radius: 5px;
    }

    .react-bs-table-sizePerPage-dropdown {
        .dropdown-menu {
            top: auto;
            bottom: 5px;
        }

        .dropdown-menu::before,
        .dropdown-menu::after {
            left: 5px;
            bottom: -5px;
            top: auto;
            z-index: 1;
            background-color: #fff;
        }
    }

    .ellipsis {
        max-width: 165px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}