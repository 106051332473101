.app-KnowledgeBaseContentManager {
    .searchBtnWrapper {
        display: flex;
        align-items: center;
    }

    .browseHistorysearchWrapper label {
        margin-bottom: 0;
    }

    .icon {
        display: flex;
        align-items: inherit;
        justify-content: inherit;
        fill: currentColor;
    }

    .body_inner_content {
        padding: 12px 28px 4.91px 22px;
    }

    .browseHistorysearchWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 23px 13px 22px;
        border-bottom: 1px solid #e5e5e5;
        width: 100% !important;
        overflow-x: unset !important;
    }

    .browseTitle {
        display: flex;
        text-transform: uppercase !important;
        font-size: 14px;
        font-family: 'SegoeUI-Bold', sans-serif;
        margin-bottom: 0;
        color: #000000;
    }

    .browseTitle label {
        margin-bottom: 0;
    }

    .createNewContentBtn,
    .createNewContentBtn:hover {
        background: #535bd6 !important;
        border-color: #535bd6 !important;
        height: 29px !important;
        padding: 2px 14px 3px 13.75px !important;
        margin-left: 39px;
    }

    .createNewContentBtn:not(:disabled):not(.disabled):active:focus,
    .createNewContentBtn:not(:disabled):not(.disabled).active:focus,
    .show > .createNewContentBtn.dropdown-toggle:focus {
        box-shadow: none !important;
        background: #535bd6 !important;
        border-color: #535bd6 !important;
        color: #ffffff !important;
    }

    .createNewTagBtn:not(:disabled):not(.disabled):active:focus,
    .createNewTagBtn:not(:disabled):not(.disabled).active:focus,
    .show > .createNewTagBtn.dropdown-toggle:focus {
        box-shadow: none !important;
        background: #535bd6 !important;
        border-color: #535bd6 !important;
        color: #ffffff !important;
    }

    .searchBox input {
        width: 292px !important;
        padding: 2px 15px 3px 12px;
        height: 29px !important;
        border: 1px solid #dce0e4 !important;
        outline: 0;
        color: #696a78 !important;
        font-size: 14px !important;
        font-family: 'Segoe UI', sans-serif;
        border-radius: 0.25rem;
        font-weight: 400 !important;
    }

    .searchBox {
        position: relative;
        margin-left: 11px;
        margin-right: 6px;
    }

    .searchBox:after {
        content: '';
        display: block;
        position: absolute;
        width: 1px;
        height: 30px;
        background-color: #e5e5e5;
        right: -21px;
        top: 0;
    }

    .createNewContentBtn {
        position: relative;
    }

    .createNewContentBtn:after {
        content: '';
        display: block;
        position: absolute;
        width: 1px;
        height: 30px;
        background-color: #e5e5e5;
        left: -22px;
        top: -1px;
        cursor: default;
    }

    .searchBox img {
        position: absolute;
        right: 11px;
        top: 6px;
    }

    .MuiTableCell-alignRight {
        text-align: left !important;
    }

    tr.MuiTableRow-root.MuiTableRow-hover {
        background-color: #f7f7f7;
    }

    tr.MuiTableRow-root.MuiTableRow-hover:nth-child(even) {
        background-color: #ffffff;
    }

    td.MuiTableCell-root.MuiTableCell-body {
        border: 0 !important;
        height: 59px;
        font-size: 14px !important;
        color: #000;
        font-family: 'SegoeUI', sans-serif !important;
        vertical-align: middle;
        padding: 0.5rem 0 0.5rem 16px;
    }

    p#mui-30788 {
        align-items: center;
        box-sizing: border-box;
        padding-right: 0px;
        padding-left: 0px;
        color: rgba(0, 0, 0, 0.54);
        font-size: 13px;
        background-color: white;
    }

    p.MuiTypography-root.MuiTablePagination-caption.MuiTypography-body2.MuiTypography-colorInherit {
        align-items: center;
        box-sizing: border-box;
        padding-right: 8px;
        padding-left: 8px;
        color: rgba(0, 0, 0, 0.54);
        font-size: 13px;
        background-color: white;
    }

    p.MuiTablePagination-selectLabel,
    p.MuiTablePagination-displayedRows {
        align-items: center;
        box-sizing: border-box;
        padding-right: 8px;
        padding-left: 8px;
        color: rgba(0, 0, 0, 0.54);
        font-size: 13px;
        background-color: white;
        margin-bottom: 0;
    }

    .MuiTablePagination-select {
        color: rgba(0, 0, 0, 0.54);
        font-size: 13px;
        padding-right: 14px;
        padding-top: 8px;
        background: #fff !important;
    }

    thead.MuiTableHead-root
        tr.MuiTableRow-root.MuiTableRow-head
        th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft.MuiTableCell-stickyHeader {
        background: transparent;
        border-bottom: 0;
    }

    span.MuiButtonBase-root.MuiTableSortLabel-root {
        color: #000;
        font-size: 14px;
        padding: 0 15px 5px 15px;
        font-family: 'SegoeUI-Bold', sans-serif;
    }

    th {
        padding-bottom: 11px !important;
        padding-top: 0 !important;
        border-bottom: 0;
    }

    span.display-content {
        display: contents;
    }

    .RRT__panel.practice_pmg_table {
        .card-body {
            table {
                @media (max-width: 1600px) {
                    width: auto;
                }
            }
        }
    }
}
