.user-access-rights-popup {
    position: absolute;
    z-index: 9999;

    & .rodal-dialog {
        z-index: 9999;
        top: 20%;
    }
}
.poc-access-rights-popup-header h5 {
    font-size: 18px;
    font-family: 'SegoeUI-SemiBold';
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 17px;
}
.poc-access-rights-popup-header .close {
    margin-top: -17px;
}
.modal-dialog.poc-access-rights-popup {
    -webkit-transform: translate(-50%, -50%) !important;
    transform: translate(-50%, -50%) !important;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100% !important;
}

.user-access-rights-popup-content {
    padding: 1rem;
    font-size: 14px;

    & .member-data {
        & .row:first-child {
            margin-bottom: 5px;
        }
    }

    & .close-button {
        border: 1px solid gray;
        background-color: white;
        color: gray;
    }

    & .close-button:active {
        background-color: gray;
        color: white;
    }

    & .form-group {
        display: flex;

        & label {
            align-self: center;
            margin: 0 1rem 0 0;
        }

        & div {
            align-self: center;
        }
    }

    & .main-label {
        font-family: 'SegoeUI-Semibold';
        margin-bottom: 1rem;
        margin-top: 1rem;
        font-size: 14px;
    }

    .btn-footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        padding-right: 0 !important;
        .btn-primary {
            margin-left: 10px;
        }
    }

    .text-style {
        color: #525a64;
    }

    .subtext-style {
        color: #888f96;
    }

    .col {
        margin-top: 5px;
    }

    & .rodal,
    & .rodal-mask {
        width: 100%;
        height: 100%;
    }

    & .rodal-dialog {
        top: 20%;
        max-width: 500px;
    }
}
