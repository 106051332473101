.create-new-discussion {
    transform: translate(-50%, -50%) !important;
    position: absolute;
    left: 50%;
    top: 50%;
    height: auto;
    background-color: #ffffff;
    width: 100%;

    .modal-header {
        background: #fff;
        border-bottom: 0;
        padding-top: 11px;
    }

    button.close {
        padding: 2px 6px 0px 0 !important;
        font-size: 30px;
        font-family: 'SegoeUI-Semibold';
    }

    .btn-wrapper {
        text-align: center;
        padding-top: 22px;

        .cancel-btn {
            color: #919191;
            height: 29px;
            border: 1px solid #919191;
            background-color: transparent;
            max-width: 70px;
            width: 100%;
            padding: 2px 5px 5px 5px;
            margin-right: 10px;
        }

        .discussion-btn {
            background: #535bd6 !important;
            border-color: #535bd6 !important;
            height: 29px;
            padding: 2px 5px 5px 5px;
            max-width: 141px;
            width: 100%;
        }
    }
}

.create-profile {
    transform: translate(-50%, -50%) !important;
    position: absolute;
    left: 50%;
    top: 50%;
    height: auto;
    width: auto;
}

.practice-owner-community {
    max-width: 639px !important;
    width: 100% !important;
}

.practice-owner-community-body.modal-body p {
    font-family: 'SegoeUI-Semibold';
    font-size: 15px !important;
    margin-bottom: 0;
    color: #000000 !important;
    line-height: 20px !important;
}

.practice-owner-community-body.modal-body p:nth-child(2) {
    padding-bottom: 20px;
}

.practice-owner-community .modal-header {
    padding: 16px 25px 15px 16px;
    background: #f8f9fa !important;
    height: 63px;
}

.practice-owner-community h5.modal-title {
    text-transform: none !important;
    font-size: 18px !important;
    font-family: 'SegoeUI-Semibold' !important;
    display: inline-block;
    color: #000000;
    text-align: left !important;
    padding-top: 0px;
    width: 100%;
}

.practice-owner-community-body.modal-body {
    border-top: 1px solid #dee2e6 !important;
    padding: 25px 24px 26px 30px !important;
}

.practice-owner-community-body.modal-body .btn-wrapper {
    text-align: right;
    padding-top: 30px;
}

.practice-owner-community-body.modal-body
    .btn-wrapper
    button.cancel-btn.back-home-btn.btn.btn-secondary {
    max-width: 102px !important;
}

button.discussion-btn.create-profile-btn.btn.btn-secondary {
    max-width: 115px !important;
    border: 0 !important;
}

@media (max-width: 642px) {
    .modal-dialog.create-new-discussion.practiceOwnerCommunity {
        max-width: 93% !important;
        height: auto;
    }

    .practiceOwnerCommunity .modal-header {
        height: auto;
    }

    .practiceOwnerCommunityBody.modal-body .btn-wrapper {
        text-align: center;
    }

    .practiceOwnerCommunity h5.modal-title {
        line-height: 22px;
    }

    .practiceOwnerCommunityBody.modal-body {
        padding: 20px 24px 20px 24px !important;
    }
}
