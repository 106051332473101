.reward-history-paginated-talbe {
    .formatted-currency {
        margin-left: 0.5em;
        color: #545cd8;
    }

    .table-header {
        margin-top: 1em;
    }

    .react-bootstrap-table {
        overflow: auto;

        .info-tooltip .tooltip-content {

            ul,
            ol {
                font-size: 13px;
                font-family: 'SegoeUI';
                color: #525a64;
                margin-left: -15px;
                margin-bottom: 0;
            }

            p {
                margin-bottom: 0.1em;
            }

            li {
                line-height: initial;
            }
        }
    }

    .react-bootstrap-table-pagination {
        flex-wrap: nowrap !important;
    }

    .link {
        cursor: pointer;
        color: #545cd8;
        text-decoration: underline;
    }

    tbody .selected {
        background-color: #e6e8ff;
    }

    thead {
        cursor: pointer;
    }

    .relative-container {
        position: relative;
        width: fit-content;
    }

    .rp-ref-reward-nf {
        position: absolute;
        right: -1.5em;
        top: -1em;
    }
}