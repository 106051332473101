.kl-payment-component {
    .payment-dialog {
        .rodal-dialog {
            max-width: 600px;
        }

        .modal-footer {
            padding: 0 1rem 1rem 1rem;
        }
    }
}