.about-poc-modal {


    .rodal-dialog {
        width: 900px !important;
        min-height: 540px;
        top: 10% !important;
    }

    .diagram-placeholder {
        display: flex;
        justify-content: center;

        img{
            max-width: 100%;
        }
    }

    @media (max-width: 1050px) {
        .rodal-dialog {
            width: 90% !important;
            top: 10% !important;
            left: 5%
        }
    }

    @media (max-width: 380px) {
        .rodal-dialog {
            top: 10% !important;
        }
    }

    .cancel-btn {
        margin-bottom: 1em;
        margin-right: 1em !important;
    }

    .knowledgeLibrary-detailsection .body_inner_content {
        padding: 29px 21px 21px 21px;
    }

    h5.modal-title {
        text-transform: none !important;
        font-size: 18px !important;
        font-family: 'SegoeUI-Semibold' !important;
        display: inline-block;
        color: #000000;
    }

    .about-knowledge-body.modal-body {
        padding-left: 25px;
        padding-right: 35px;
    }

    .modal-content {
        border: 0 !important;
    }

    .modal-header {
        padding: 18px 16px 18px 20px;
    }

    .cancel-btn,
    .cancel-btn:hover {
        font-family: 'SegoeUI-Semibold';
        font-size: 15px !important;
        color: #919191;
        width: 70px;
        height: 29px;
        border: 1px solid #919191;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    button.cancel-btn.btn.btn-secondary {
        margin-left: auto;
        margin-right: 6px;
        margin-top: 21px;
    }
}