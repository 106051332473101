.cropping-tool {
    svg {
        display: none;
    }
    img {
        margin: 19%;
        border: 2px solid gray;
        border-radius: 50%;
    }
    canvas {
        width: 250px !important;
        height: auto !important;
    }
    .cancel-btn {
        color: #919191;
        height: 29px;
        border: 1px solid #919191;
        background-color: transparent;
        max-width: 70px;
        width: 100%;
        padding: 2px 5px 5px 5px;
        margin-right: 10px;
        margin: 5px;
    }
    .btn-wrapper {
        display: flex;
        justify-content: end;
        align-items: center;
    }
    .save-btn {
        background: #535bd6 !important;
        border-color: #535bd6 !important;
        color: white !important;
        height: 29px;
        max-width: 70px;
        padding: 2px 5px 3px 5px;
        width: 100%;
        margin: 5px;
    }
    .btn-margin {
        margin: 10px;
    }
    .main-content {
        display: flex;
        margin: 10px;
    }
}
