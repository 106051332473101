.are-you-still-here-modal {
    text-align: center;
    .close-button {
        border: 1px solid;
        border-color: gray;
        background-color: white;
        color: gray;
    }

    .close-button:hover {
        background-color: gray;
        color: white;
    }

    .rodal-dialog {
        max-width: 350px !important;
        top: 30%;
        .rodal-close {
            display: none;
        }
    }

    .are-you-still-here-modal-title{
        font-family: 'SegoeUI-Bold';
        font-size: 16px;
        margin-bottom: 1rem;
    }

    .are-you-still-here-modal-text{
font-size: 14px;
    }

    .are-you-still-here-modal-time{
        font-family: 'SegoeUI-Bold';
        font-size: 16px;
        margin: 1rem 0rem;
    }

    .are-you-still-here-modal-buttons{
        display: flex;
        justify-content: space-between;
    }
}