.timeline-editor {
    .button-container {
        display: flex;
        justify-content: flex-end;
    }

    .input-height {
        height: 38px !important;
    }

    .delete-button {
        color: #d32f2f;
    }

    .flex-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .btn-cancel {
        height: auto;
        width: auto;
        background-color: #fff;
        color: #919191;
        border-color: #919191;
    }

    .text-bold {
        font-weight: bold !important;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .timeline-details-row {
        .col-md-3,
        .col-md-4,
        .col-md-5 {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .timeline-details {
        display: flex;
        align-items: baseline;
        gap: 10px;

        p {
            margin-bottom: 0;
        }

        .practice-text {
            font-weight: bold;
        }

        .template-text {
            font-size: smaller;
            display: flex;
            flex-wrap: wrap;
            gap: 0.25em;
        }
    }

    @media (max-width: 767px) {
        .hide-on-small {
            display: none;
        }

        .change-on-small {
            margin-bottom: 10px;
        }

        .add-event-btn {
            width: 100%;
        }
    }

    @media (max-width: 992px) {
        .buttons-container {
            .ButtonLoader-Div {
                width: 100%;
            }

            .btn {
                width: 100%;
            }
        }

        .timeline-details {
            flex-direction: column;
        }
    }
}

.timeline-editor-preview {
    height: calc(100vh - 100px + calc(30px - var(--grid-gutter-width)));
    display: flex;
    flex-direction: column;

    .gantt-chart-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }

    .mb-3 {
        margin-bottom: 0.5rem !important;
    }
}
