.app-clubhouse-page {
    .header-tools {
        @media (max-width: 555px) {
            .user {
                display: none;
            }

            .return-to-pmg {
                display: none;
            }
        }

        @media (max-width: 317px) {
            .top-liked-posts {
                display: none;
            }

            .return-to-pmg {
                display: none;
            }
        }
    }
}

.clubhouse-area {
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    overflow: auto;
    min-height: 400px;

    .loader {
        display: flex;
        align-items: center;
        height: 100%;
    }

    h4.practice-mgt-title {
        text-transform: uppercase !important;
        font-size: 14px;
        font-family: 'SegoeUI-Bold';
        margin-bottom: 0;
        color: #000000;
    }

    .post-title {
        text-transform: capitalize;
        font-size: 14px !important;
        padding-top: 2px;
    }

    .app-inner-layout__wrapper.chat-box-wrapper-main {
        min-height: 230px !important;
    }

    .app-inner-layout__wrapper .app-inner-layout__sidebar.card,
    .app-inner-layout__sidebar-header {
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px;
        border-bottom: #e9ecef solid 1px
    }

    .app-inner-layout__wrapper .app-inner-layout__content.card {
        border-top-right-radius: 6px !important;
        border-bottom-right-radius: 6px !important;
    }

    .error-message {
        text-align: center;
        color: #545cd8;
    }

    @media screen and (max-width: 992px),
    (max-height: 550px) {
        .app-inner-layout__wrapper.chat-box-wrapper-main {
            min-height: 400px;
            display: flex;
            flex-direction: column;
        }
    }

    @media (max-width: 350px) {
        .app-inner-layout__sidebar-header {
            .btn-group {
                flex-wrap: wrap;
            }
        }
    }
}