.modal-copy-timeline-popup {
    .copy-name-label {
        font-family: 'SegoeUI-Semibold';
        font-size: 14px;
    }

    .modal-title {
        margin-bottom: 0;
        color: #000;
    }

    .loader-container {
        width: 100%;
    }

    .form-group {
        margin-bottom: 0;
    }

    .modal-copy-buttons {
        padding-top: 20px;
    }

    .btn-cancel {
        height: auto;
        width: auto;
        background-color: #fff;
        color: #919191;
        border-color: #919191;
        margin-right: 5px;
    }

    @media screen {
        top: 10%;
    }
}
