.practice-profile label {
    font-size: 16px;
    font-family: 'SegoeUI-Bold';
}

.practice-profile h6 {
    font-family: 'SegoeUI';
}

.body_white_box.associate_member .rodal.rodal-fade-enter.modal-style {
    background: none;
    overflow: inherit;
}

.rodal-mask {
    background: none;
}

.associate-member-details .rodal-dialog {
    left: 0 !important;
}

.associate-member-details .rodal-close {
    width: 2% !important;
}

.associate-member-details span {
    width: auto !important;
}

.delete-dialog .rodal-close {
    width: 4% !important;
}

.wallet-tables {
    margin: 0 -1em;
    padding: 0 1em;

    .table thead tr th {
        background-color: #dce0e4 !important;
        color: #525a64 !important;
        font-size: 13px !important;
        font-family: 'SegoeUI-Semibold' !important;
    }

    .table tbody td {
        color: #888f96 !important;
        font-size: 13px !important;
        font-family: 'SegoeUI-Semibold' !important;
    }
}

.table-section {
    span {
        display: initial;
        width: initial;
        margin-bottom: 0;
        font-size: 14px;
        color: initial;
        line-height: initial;
    }
}

.cls-user-details-modal {
    .user-info-tab {
        .col-sm-8 {
            .col-sm-2 {
                text-wrap: nowrap;
            }
        }
    }
}

.customerStyle {
    background: url('../../../../../assets/images/arrow.png');
    background-position: calc(100% - 10px);
    background-repeat: no-repeat;
    border: 1px solid #dce0e4 !important;
    outline: 0;
    max-width: 270px;
    border-radius: 0.25rem;
    height: 30px;
    overflow: hidden;
    cursor: pointer;
}

@media (max-width: 1430px) {
    .cls-user-details-modal {
        .rodal-dialog {
            top: 10%;
            left: 10%;
            width: 82% !important;
        }
    }
}

@media (max-width: 1199px) {
    .cls-user-details-modal {
        .active-subscriptions-paginated-table .filter-scroller {
            flex-direction: column;
            align-content: space-between;

            .filter-item {
                justify-content: space-between;
            }

            .line-separator {
                display: none;
            }
        }
    }
}

@media (max-width: 1067px) {
    .cls-user-details-modal {
        .payment-history-paginated-table .filter-scroller {
            flex-direction: column;
            align-content: space-between;

            .filter-item {
                justify-content: space-between;
            }

            .line-separator {
                display: none;
            }
        }
    }
}

.user-details-referral-data {
    .nonprofit-icon {
        height: 27px;
        width: 27px;
        stroke-width: 20px;
        stroke: black;
        margin-left: 0.5em;
        outline: 0;
    }

    .center-container {
        align-content: center;
    }

    .sub-code-row {
        align-items: center;
    }

    .tooltip-content span {
        margin-bottom: 0;
    }
}