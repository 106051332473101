.subscription-payment-info-popup {
    .rodal-dialog {
        top: 30%;
    }

    .row {
        margin-bottom: 5px;
        align-items: baseline;
    }

    h6 {
        color: #888f96;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
    }

    label {
        color: #525a64;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
    }

    .footer {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
    }

    .btn {
        height: 29px;
        padding: 0px 12px 2px 12px;
    }

    .rodal-close {
        width: 16px;
    }

    .rw-dropdown-list {
        color: #888f96;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';

        .rw-dropdown-list-value {
            color: #888f96 !important;
        }

        .rw-list-option {
            color: #888f96;
        }
    }

    .rw-state-focus .rw-widget-picker {
        border-color: #ccc !important;
    }

    @media only screen and (max-width: 1199px) and (min-width: 700px) {
        .rodal-dialog {
            width: 550px !important;
        }
    }
}
