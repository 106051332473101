.stage-editor-modal {
    .modal-stages {
        top: 10%;
        max-width: 100%;
        min-height: 700px;
        overflow-y: hidden;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
    }

    .bootstrap-table-container {
        max-height: 300px;
        min-height: 300px;
        overflow-y: auto;
        overflow-x: auto;
    }

    .bootstrap-table-container::-webkit-scrollbar {
        display: none;
    }

    .loader-container{
      max-height: 300px;
      min-height: 300px;
      align-content: center;
    }

    @media only screen and (max-width: 1024px) {
        .modal-stages {
            top: 10%;
            max-height: 70vh;
        }
    }

    .no-content {
        margin-top: 100px;
    }

    .rodal-mask {
        background-color: rgba(0, 0, 0, 0.5);
    }

    .confirmation-modal-container .rodal-dialog {
            
        top: 20% !important;

        .modal-footer {
            padding-top: 0;
        }

        @media (max-width: 1366px) {
            left: 21%;
            top: 30%;
        }
        
        @media (max-width: 1199px) {
            top: 20% !important;
            left: 10%;
            width: 42% !important;
        }

        @media (max-width: 599px) {
            top: 15% !important;
            left: 10%;
            width: 82% !important;
        }
    }
}