.associate-members-table {
    .edit-col {
        // width: 65px !important;
        text-align: left !important;
    }

    .confirm-removing-representative-access-right-pop-up {
        .rodal-dialog {
            top: 25% !important;
        }

        .modal-header {
            padding-left: 15px !important;
            height: 5rem !important;
        }

        .btn-cancel {
            color: #919191;
            height: 29px;
            background-color: transparent;
            max-width: 150px;
            width: max-content !important;
            padding: 2px 15px 5px 15px !important;
            margin-right: 10px;
        }

        .btn-primary {
            background: #535bd6 !important;
            border-color: #535bd6 !important;
            color: #fff;
            width: max-content !important;
            height: 29px;
            padding: 2px 15px 5px 15px !important;
            margin-right: 20px;
        }

        @media (max-width: 577px) {
            .modal-footer {
                justify-content: flex-end;
            }

            .btn-cancel {
                height: 50px !important;
            }

            .btn-primary {
                height: 50px !important;
                width: 10rem !important;
            }
        }
    }

    .section-container {
        .section-header {
            font-family: 'SegoeUI-Bold';
            font-size: 17px;
            margin: 5px 0;
        }

        .section-sub-header {
            font-family: 'SegoeUI-SemiBold';
            font-size: 15px;
            margin: 5px 0 7px 10px;
        }
    }

    .associate_data.row #pageDropDown {
        background-color: #545cd800 !important;
        color: #545cd8 !important;
        border: none !important;
        box-shadow: none !important;
        outline: none !important;
    }

    .associate_data.row .card {
        border: 0 !important;
    }

    .body_white_box.associate_member .tableHeader {
        padding: 10px;
        background-color: transparent;
    }

    .body_white_box.associate_member .body_header_title h5 {
        width: 100%;
        text-transform: uppercase !important;
        font-size: 14px;
        color: #000000;
        font-family: 'SegoeUI-Bold';
        margin-bottom: 0;
    }

    .associate_data.row td {
        color: #888f96;
        font-size: 13px !important;
        font-family: 'SegoeUI-Semibold';
        line-height: 17.5px;
        vertical-align: middle;
    }

    .body_white_box.associate_member .tableHeader .heading {
        display: none;
    }

    .body_white_box.associate_member {
        position: relative;
    }

    .body_white_box.associate_member .tableHeader.row {
        text-align: right;
        justify-content: end;
        display: flex;
        position: absolute;
        right: 33px;
        top: 9px;
    }

    .body_white_box.associate_member .tableHeader.row {
        width: 222px;
        height: 29px;
        padding: 0 0 0 0px;
    }

    .body_white_box.associate_member .card.mb-3 {
        margin-bottom: 0px !important;
    }

    .body_white_box.associate_member .card-body {
        padding: 10px 21px 20px;
    }

    .associate_data.row .table th:nth-child(1) {
        width: 34%;
    }

    .associate_data.row .table th:nth-child(2) {
        width: 22%;
    }

    .associate_data.row .table th:nth-child(3) {
        width: 22%;
    }

    .associate_data.row .table th:nth-child(4) {
        width: 22%;
    }

    .associate-member-list-table {
        table,
        .react-bootstrap-table {
            border: none !important;
        }
    }
    .associate_data.row .pagination li a {
        position: relative;
        display: block;
        padding: 0.5rem 0.75rem;
        margin-left: -1px;
        line-height: 1.25;
        background-color: #fff;
        border: 0;
        color: #757575;
        font-family: 'SegoeUI';
        font-size: 14px;
        font-weight: 400;
    }

    .associate_data.row .pagination li.active a:hover {
        color: #000;
        opacity: 0.5;
    }

    .associate_data.row #pageDropDown {
        background-color: #757575;
    }

    .body_white_box.associate_member .modal-body p {
        font-size: 16px;
        font-family: 'SegoeUI-Semibold';
        margin-bottom: 7px;
        line-height: 20px;
        color: #000000;
    }

    .body_white_box.associate_member .rodal-dialog.rodal-fade-enter {
        width: 900px !important;
    }

    .body_white_box.associate_member .modal-body h6 {
        font-size: 15px;
        font-family: 'SegoeUI-Bold';
        color: #000000;
        padding-top: 7px;
        padding-bottom: 0px;

        &.associate-popup {
            font-size: 17px;
        }
    }

    .body_white_box.associate_member .modal-body .col-md-4 input {
        max-width: 270px;
        height: 30px;
        border: 1px solid #dce0e4;
        padding: 13px 15px 14px;
        outline: none;
        color: #888f96;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
    }

    .body_white_box.associate_member {
        .invalid-feedback {
            display: none !important;
        }
    }

    .body_white_box.associate_member .modal-body {
        max-width: 894px;
        margin: 0 auto;
        padding-top: 17px;
        padding-bottom: 0;
    }

    .associate_form {
        border-bottom: 1px solid #dee2e6;
    }

    .body_white_box.associate_member .feature_checkbox .modal-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 1rem;
        border-top: 1px solid #dee2e6;
        border-bottom-right-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
        background: transparent !important;
    }

    .body_white_box.associate_member .modal-header {
        background: #f8f9fa;
        height: 63px;
        padding: 19px 0 0 23px;
    }

    .associate_form label {
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
        color: #525a64 !important;
    }

    .modal-footer {
        background: transparent;
        margin: 0 auto;
        border-top: none !important;
        padding: 0px 0 17px 0;
        width: 100%;
    }

    .associate_form .row {
        margin-bottom: -3px;
    }

    .footer-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-inline: 20px;
    }

    @media (max-width: 1500px) {
        .body_white_box.associate_member .react-bootstrap-table .table {
            border: 0px;
            max-width: 1300px;
            overflow-x: auto !important;
        }
    }

    .feature_checkbox .input-group-text {
        padding: 0 !important;
        color: #535bd6;
        border: 0 !important;
        margin-left: 5px;
    }

    .feature_checkbox .col-md-6 .form-check {
        line-height: 25px !important;
        padding: 10px 0 0 20px;
    }

    .customerStyle select {
        background: transparent;
        padding: 5px;
        border: 0;
        border-radius: 0;
        height: 35px;
    }

    .customerStyle {
        background: url('../../assets/images/arrow.png');
        background-position: calc(100% - 10px);
        background-repeat: no-repeat;
        border: 1px solid #dce0e4 !important;
        outline: 0;
        max-width: 270px;

        border-radius: 0.25rem;
        height: 30px;
        overflow: hidden;
        cursor: pointer;
    }

    .customerStyle select {
        overflow: hidden;
        position: relative;
        padding: 4px 10px 4px 10px;
        color: #888f96;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
        outline: 0;
        border: none;
        box-shadow: none;
        background-color: transparent;
        background-image: none;
        height: 30px;
        border-radius: 0.25rem;
        cursor: pointer;
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        text-overflow: '';
        text-indent: 0.01px;
        text-overflow: '';
    }

    .customerStyle .form-control.is-invalid,
    .customerStyle .form-select.is-invalid {
        background-color: #fff;
        height: 28px;
    }

    .form-control.is-invalid,
    .form-select.is-invalid,
    .was-validated .form-control:invalid,
    .was-validated .form-select:invalid {
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: URL("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: center right 5px;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .customerStyle .form-control:disabled,
    .customerStyle .form-select:disabled {
        background-color: #e9ecef;
    }

    .customerStyle select:focus {
        outline: none;
    }

    .section-body {
        display: flex;
        position: relative;
        padding-left: 28px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        line-height: 20px;
        color: #888f96;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
        text-transform: capitalize;
        align-items: center;

        .info-tooltip {
            margin-bottom: 0;

            li {
                margin-left: -20px;
            }
        }
    }

    .section-body.pmg-access-rights {
        width: max-content;
    }

    .discount-code-label {
        font-size: 13px;
        text-transform: capitalize;
        font-family: 'SegoeUI-Semibold';
        line-height: 20px;
        color: #888f96;
        margin-bottom: -1rem;
    }

    /* Hide the browser's default checkbox */

    .section-body input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px !important;
        border: 1px solid #dce0e4;
        border-radius: 0.25rem;
    }

    .section-body .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        border: 1px solid #dce0e4;
        border-radius: 0.25rem;
        background: #fff;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }

    .section-body input:disabled ~ .checkmark:after {
        border: solid #949494;
        border-width: 0 3px 3px 0;
    }

    .section-body input:disabled ~ .checkmark {
        background-color: #f0f4f7;
    }

    /* Show the checkmark when checked */
    .section-body input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .section-body .checkmark:after {
        left: 6px;
        top: 0px;
        width: 7px;
        height: 14px;
        border: solid #535bd6;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .body_white_box.associate_member .modal-body p:nth-child(2) {
        padding-top: 5px;
    }

    .body_white_box.associate_member .modal-footer button.btn.btn-primary {
        width: 130px;
        height: 29px;
        padding: 0 5px 2px 5px;
        border-radius: 0.25rem;
        cursor: pointer;
    }

    .body_white_box.associate_member .modal-footer button.btn.btn-secondary {
        width: 71px;
        height: 29px;
        padding: 0 5px 2px 5px;
        border-radius: 0.25rem;
    }

    .body_white_box.associate_member .associate_form .col-md-4 {
        padding-bottom: 5px;
    }

    .feature_checkbox {
        padding-top: 7px;
    }

    .btn-primary.focus,
    .btn-primary:focus {
        color: #fff;
        box-shadow: none !important;
    }

    .associate_form .col-md-4:nth-child(1) {
        padding-right: 4px;
    }

    .associate_form .col-md-4:nth-child(2) {
        padding-right: 0px;
        padding-left: 12px;
    }

    .role-dropdown .col-md-4:nth-child(1) {
        padding-right: 4px;
    }

    .role-dropdown .col-md-4:nth-child(2) {
        padding-right: 0px;
        padding-left: 12px;
    }

    .role-dropdown .col-md-4:nth-child(3) {
        padding-right: 0px;
        padding-left: 6px;
    }

    .feature_checkbox.practice1 .col-md-4 {
        padding-right: 0;
    }

    .feature_checkbox.practice1,
    .feature_checkbox.practice2,
    .feature_checkbox.practice3 {
        padding-top: 12.5px;
    }

    .body_white_box.associate_member .modal-body p {
        font-size: 16px;
        font-family: 'SegoeUI-Semibold';
    }

    .disabled-paragraph {
        font-size: 13px !important;
        color: #888f96 !important;
    }

    .intro-text {
        overflow-wrap: break-word;
    }

    .edit-btn button {
        width: 49px !important;
        height: 29px;
        background: #535bd6;
        color: #fff;
        border: 0;
        border-radius: 0.25rem;
        font-size: 14px;
        font-family: 'SegoeUI-Semibold';
        outline: 0 !important;
        padding: 0 5px 2px 5px;
    }

    button.cancel {
        color: #919191;
        border: 1px solid #919191;
        background: #fff;
        font-size: 14px;
        font-family: 'SegoeUI-Semibold';
        width: 71px;
        height: 29px;
        margin-right: 10px;
        border-radius: 0.25rem;
    }

    button.remove {
        width: 80px !important;
        height: 29px;
        background: #535bd6;
        color: #fff;
        border: 0;
        border-radius: 0.25rem;
        font-size: 14px;
        font-family: 'SegoeUI-Semibold';
        outline: 0 !important;
        padding: 0 5px 2px 5px;
    }

    button.save {
        width: 58px !important;
        height: 29px;
        background: #535bd6;
        color: #fff;
        border: 0;
        border-radius: 0.25rem;
        font-size: 14px;
        font-family: 'SegoeUI-Semibold';
        outline: 0 !important;
        margin: 0 !important;
    }

    .btn-popup {
        text-align: right;
    }

    .btn-popup button {
        font-size: 11px;
        margin: 0 5px;
    }

    .btn-popup button:last-child {
        margin-right: 0;
    }

    @media (max-width: 1280px) {
        .body_white_box.associate_member .tableHeader.row button {
            width: 100%;
            max-width: 100%;
        }

        .body_white_box.associate_member .rodal-dialog.rodal-fade-enter {
            width: 68% !important;
        }

        .body_white_box.associate_member .modal-body {
            margin-right: 10px;
        }

        .body_white_box.associate_member .modal-footer {
            margin-right: 17px;
        }
    }

    @media (max-width: 991px) {
        .body_white_box.associate_member .rodal-dialog.rodal-fade-enter {
            max-width: 80% !important;
            left: 0 !important;
            width: 80% !important;
        }

        .body_white_box.associate_member .modal-footer {
            padding: 0 0 20px 0;

            .footer-wrapper {
                padding-left: 15px !important;
                padding-right: 25px !important;
            }
        }

        .body_white_box.associate_member .rodal-dialog {
            top: 15% !important;
            left: 4%;
            width: 82% !important;
        }

        .body_white_box.associate_member .associate_form .col-md-4 {
            padding-bottom: 0px;
        }

        .associate_form .col-md-4:nth-child(1) {
            padding-right: 15px;
        }

        .associate_form .col-md-4:nth-child(2) {
            padding-right: 15px;
            padding-left: 15px;
        }

        .role-dropdown .col-md-4:nth-child(1) {
            padding-right: 15px;
        }

        .role-dropdown .col-md-4:nth-child(2) {
            padding-right: 15px;
            padding-left: 15px;
        }

        .role-dropdown .col-md-4:nth-child(3) {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    @media (max-width: 768px) {
        .body_white_box.associate_member .rodal-dialog.rodal-fade-enter {
            max-width: 500px !important;
            height: auto !important;
        }

        .body_white_box.associate_member .rodal-dialog {
            top: 0% !important;
            left: 4%;
            width: 82% !important;
        }

        .body_white_box.associate_member .rodal-dialog.rodal-fade-enter {
            max-width: 80% !important;
            height: auto !important;
            left: 0 !important;
            width: 80% !important;
        }
    }

    @media (max-width: 767px) {
        .body_white_box.associate_member .modal-body .col-md-4 input,
        .customerStyle {
            max-width: 100%;
        }

        .associate_form {
            padding-bottom: 10px;
        }

        .practices-list {
            flex-wrap: wrap !important;
        }
    }

    .practices-list {
        display: flex;
        margin-right: -15px;
        margin-left: -15px;
        margin-bottom: 10px;
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;
    }

    .body_white_box.associate_member
        .delete-dialog
        .rodal-dialog.rodal-fade-enter {
        width: 400px !important;
    }

    .delete-dialog {
        top: 25%;
    }

    .delete-dialog .body {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .delete-dialog .header {
        padding-top: 30px !important;
        width: 75%;
        align-self: center;
        text-align: center;
    }

    .body_white_box.associate_member .delete-dialog .secondary-text {
        padding-top: 5px !important;
        width: 75%;
        align-self: center;
        text-align: center;
        color: #000 !important;
    }

    .delete-dialog .footer-wrapper {
        margin: auto;
        margin-top: 15px;
        margin-bottom: 5px;
        border-top: none !important;
    }

    .delete-dialog .yes-button {
        width: 49px !important;
    }

    .delete-dialog .no-edit-button {
        width: 49px !important;
        background: #fff;
        margin-right: 10px;
        color: #919191;
        padding: 0 5px 2px 5px;
    }

    .member-button-panel {
        display: inline-flex;
    }

    .loader-submit {
        padding: 0px 12px 2px 12px !important;
        width: auto !important;
    }
}
