.create-room-modal {
    min-width: 800px;
    min-height: 200px;
    max-height: 800px;

    h5.modal-title {
        font-size: 18px;
    }

    p.disclaimer {
        font-family: 'SegoeUI-Semibold';
        font-size: 14px;
        color: #888f96;
    }

    .searchBar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        padding-bottom: 25px;
    }

    .searchBar input[type='text'] {
        width: 92%;
        resize: none;
        padding: 4px 10px 4px 10px;
        border: 1px solid #dce0e4 !important;
        border-radius: 0.25rem;
        outline: 0;
        font-size: 13px !important;
        color: #888f96 !important;
        font-family: 'SegoeUI-Semibold' !important;
        line-height: 20px;
    }

    .searchBar img {
        max-width: 100%;
        height: 30px;
    }

    .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
        display: none;
    }

    tbody.MuiTableBody-root a {
        font-size: 13px !important;
        text-decoration: underline !important;
        color: #535bd6 !important;
        font-family: 'SegoeUI-Semibold' !important;
        cursor: pointer;
    }

    .MuiTableSortLabel-root {
        cursor: pointer;
        display: inline-block !important;
    }

    .MuiTableCell-alignRight {
        text-align: left !important;
        flex-direction: row-reverse;
    }

    .MuiPaper-elevation1 {
        box-shadow: none !important;
    }

    th:nth-child(1) {
        width: 23%;
        padding: 5px 0px 0px 14px !important;
        display: revert;
    }


    th:nth-child(1) span.MuiButtonBase-root.MuiTableSortLabel-root {
        padding-left: 15px !important;
    }

    th:nth-child(2) {
        width: 34%;
    }

    th:nth-child(3) {
        width: 27%;
    }

    th:nth-child(4) {
        width: 16%;
    }


    th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignRight {
        border-bottom: 0px;
        border-top: 0px;
        padding: 5px 0 !important;
        vertical-align: middle;
    }

    tr.MuiTableRow-root.MuiTableRow-head {
        background: #dce0e4 !important;
        height: 50px;
    }


    label {
        color: #888f96;
        font-size: 13px !important;
        font-family: 'SegoeUI-Semibold' !important;
        cursor: pointer;
    }

    td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignRight,
    td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter {
        color: #888f96;
        padding: 5px 0px 5px 0px !important;
        font-size: 13px !important;
        font-family: 'SegoeUI-Semibold' !important;
        height: 31px;
        vertical-align: initial;
    }


    span.MuiButtonBase-root.MuiTableSortLabel-root {
        color: #525a64 !important;
        font-size: 13px !important;
        font-family: 'SegoeUI-Semibold' !important;
    }

    tr.MuiTableRow-root.MuiTableRow-hover {
        padding: 5px 0 !important;
        cursor: pointer;
    }

    .offlineDot {
        height: 7px;
        width: 7px;
        background-color: #888;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-left: 3px;
    }

    .onlineDot {
        height: 7px;
        width: 7px;
        background-color: #0a9e00;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        margin-left: 3px;
    }

    span.clientLogin {
        margin-right: 6px;
        height: 100%;
    }

    .searchBar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
        padding-bottom: 25px;
    }

    .searchBar input[type='text'] {
        width: 92%;
        resize: none;
        padding: 4px 10px 4px 10px;
        border: 1px solid #dce0e4 !important;
        border-radius: 0.25rem;
        outline: 0;
        font-size: 13px !important;
        color: #888f96 !important;
        font-family: 'SegoeUI-Semibold' !important;
        line-height: 20px;
    }

    .searchBar img {
        max-width: 100%;
        height: 30px;
    }

    h5.modal-title {
        font-size: 18px;
    }

    .MuiTableContainer-root {
        width: 100%;
        overflow-x: auto;
        min-height: 100px;
        max-height: 310px;
        overflow-y: auto;
    }


    .MuiTableContainer-root::-webkit-scrollbar {
        width: 5px;
        height: 4px;
        border-radius: 50px;
        background-color: #e5e5e5;
        height: 0;
        border-radius: 16px;
        width: 5px;
    }


    .MuiTableContainer-root::-webkit-scrollbar-thumb {
        background: #a0a0a0;
        height: 0;
        border-radius: 50px;
        width: 5px;
    }

    transform: translate(-50%, -50%) !important;
    position: absolute;
    left: 50%;
    top: 50%;

    .MuiTableContainer-root {
        border-radius: 7px !important;
    }

    th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft {
        border-bottom: 0px;
        border-top: 0px;
        padding: 0 !important;
        vertical-align: middle;
    }

    .modal-content {
        min-height: 200px;
        max-height: 800px;
        background: #fff;
        border: none !important;
    }

    @media (max-width: 1440px) {
        .modal-content {
            height: auto;
            background: #fff;
        }
    }

    @media screen and (max-width: 992px), (max-height: 550px) {
        max-width: 95%;
        height: auto;
        min-width: 620px;

        table.MuiTable-root.posterDirectorTable {
            width: 646px;
        }
    }

    @media (max-width: 640px) {
        left: 48.5%;
        min-width: 92%;
        height: auto;

        h5.modal-title {
            line-height: 20px !important;
        }
    }

    @media (max-width: 520px) {
        .modal-dialog.knowledge-contetnt-popup.knowledge-in-detail.discussion-hr-popup.votingPopup {
            max-width: 95% !important;
        }

        .modal-dialog.knowledge-contetnt-popup.knowledge-in-detail.discussion-hr-popup.votingPopup .title {
            width: 100% !important;
            flex: 0 0 100% !important;
            padding-bottom: 10px;
        }

        .modal-dialog.knowledge-contetnt-popup.knowledge-in-detail.discussion-hr-popup.votingPopup .author-input {
            width: 100% !important;
            max-width: 100% !important;
            flex: 0 0 100% !important;
            padding-bottom: 0px;
        }

        .modal-dialog.knowledge-contetnt-popup.knowledge-in-detail.discussion-hr-popup.votingPopup h5.modal-title {
            display: flex !important;
            justify-content: space-between;
            width: 100%;
            flex-wrap: wrap;
        }

        .modal-dialog.knowledge-contetnt-popup.knowledge-in-detail.discussion-hr-popup.votingPopup .title-wrapper {
            justify-content: flex-start !important;
            flex-wrap: wrap;
            padding-bottom: 10px !important;
        }

        .modal-dialog.knowledge-contetnt-popup.knowledge-in-detail.discussion-hr-popup.votingPopup .edited-post-wrapper {
            padding-bottom: 0px !important;
            flex-wrap: wrap;
        }

        .discussion-hr-popup .author-input label {
            padding-left: 0px !important;
        }

        .discussion-hr-popup .author-input span {
            margin-left: 0px !important;
        }

        svg#SvgjsSvg1076 {
            width: 300px !important;
            max-width: 100% !important;
        }

        .apexcharts-text tspan {
            font-family: inherit;
            font-size: 10px !important;
        }

        .modal-content {
            height: auto;
            background: #fff;
        }
    }

    @media (max-width: 414px) {
        .knowledgeContetntPopup .btnWrappwer {
            justify-content: center !important;
        }

        button.saveBtn.profileBtn.btn.btn-secondary {
            margin-left: 12px;
        }
    }

    @media (max-width: 375px) {

        .voteRemoveBtn,
        .ChangeVoteBtn {
            width: 100%;
        }

        .voteButtonWrapper {
            flex-direction: column;
        }

        .voteKeepBtn {
            margin-left: 0;
            width: 100%;
            margin-top: 10px;
        }
    }

    @media (max-width: 480px) {
        svg#SvgjsSvg1451 {
            overflow: initial !important;
        }
    }

    @media (max-width: 1300px) {
        table.MuiTable-root.posterDirectorTable {
            width: 800px !important;
        }
    }

    tr.MuiTableRow-root.MuiTableRow-head {
        position: sticky;
        top: 0px;
        z-index: 1;
    }

    .MuiTableContainer-root table {
        border: 1px solid #dce0e4;
        border-top: 0px;
        border-bottom: 0px;
        margin-bottom: 0px;
    }

    .table-avatar-icon {
        float: left;
        height: 100%;
        width: 22px;
        margin-right: 6px;
    }

    .poster-member-icon {
        float: left;
        margin-right: 6px;
        height: 22px;
        width: 22px;
        border-radius: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .poster-member-initials {
        font-family: 'SegoeUI-Semibold';
        font-size: 10px;
        cursor: pointer;
    }

    .btn-wrapper {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-top: 1em;

        .cancel-btn {
            color: #919191;
            height: 29px;
            border: 1px solid #919191;
            background-color: transparent;
            max-width: 70px;
            width: 100%;
            padding: 2px 5px 5px 5px;
            margin-right: 10px;
        }

        .save-btn {
            background: #535bd6 !important;
            border-color: #535bd6 !important;
            color: #fff;
            height: 29px;
            padding: 2px 5px 5px 5px;
        }
    }

    .selected-row {
        background-color: rgba(0, 0, 0, 0.08) !important;
    }
}