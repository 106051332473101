.info-tooltip {

    .tooltip-icon {
        font-size: 1rem;
        margin-left: 4px;
        color: #535bd6;
        margin-right: -1px;
        outline: 0;
        background-color: white;
        border-radius: 2rem;
    }

    .opaque {
        opacity: 1;
    }

    .tooltip-content {
        font-size: 13px !important;
        font-weight: 300;
        font-family: 'SegoeUI';
        text-transform: none;
        background-color: #ebebeb !important;
        border: 1px solid #cdcdcd;
        border-radius: 5px;
        box-shadow: 0 0 3px 0 rgba(1, 1, 1, 0.09);
        font-size: 14px;
        color: #656565 !important;
        line-height: 1.15;
        z-index: 10;
        text-wrap: wrap
    }

    .tooltip-content {
        max-width: 756px;

        @media (max-width: 770px) {
            max-width: 90vw;
        }

        .arrow {
            border-top: 10px solid #cdcdcd !important;
        }

        h6 {
            font-size: 12px;
            color: #000;
            font-family: 'SegoeUI-Semibold';
            margin-bottom: 20px;
        }

        p {
            font-size: 13px;
            font-family: 'SegoeUI';
            color: #525a64;
            line-height: 18px;

            span {
                font-family: 'SegoeUI-Semibold';
            }
        }
    }

    .tooltip-content.place-top .arrow {
        background: transparent;
        transform: none;

        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;

        border-top: 10px solid #cdcdcd;
        bottom: -11px !important;
    }

    .tooltip-content.place-right .arrow {
        background: transparent;
        transform: rotate(90deg);

        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;

        border-top: 10px solid #cdcdcd;
        left: -16px !important;
    }

    .tooltip-content.place-bottom .arrow {
        background: transparent;
        transform: rotate(180deg);

        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;

        border-top: 10px solid #cdcdcd;
        top: -10px !important;
    }
}