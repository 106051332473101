.editable-name-editor,
.editable-name-view {
    display: flex;
    gap: 0.5rem;
    width: 100%;

    white-space: nowrap;

    svg {
        cursor: pointer;
    }
}

.editable-name-editor {
    align-items: center;

    input {
        min-width: 250px;
    }

    @media (max-width: 499px) {
        input {
            max-width: 150px;
            min-width: 150px;
        }
    }
}

.editable-name-view {
    align-items: center;
}