.rich-text-input {
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 11;
    background-color: #fff;

    @media (max-width: 800px) {
        padding-inline: 5px !important;
    }

    @media (max-width: 330px) {
        .search-btn-wrapper {
            gap: 0.15em;
            flex-wrap: wrap;

            .upload-share-wrapper {
                width: 100%;
            }

            .share-img {
                margin-right: 5px;
            }
        }
    }

    .close-icon-div {
        position: relative;

        img.close-icon-img {
            position: absolute;
            top: -3px;
            right: inherit;
            left: -4px;
            cursor: pointer;
        }
    }

    .search-box.edit-search {
        max-width: 100% !important;
        width: 100%;
        border: 1px solid #dce0e4 !important;
        border-radius: 0.25rem;
        margin-right: 5px;
        max-height: 192px;
        overflow: auto;
        flex-direction: column;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
        height: auto !important;
    }

    .search-box.edit-search::-webkit-scrollbar {
        width: 6px;
        background-color: #c1c1c157;
    }

    .search-box.edit-search::-webkit-scrollbar-thumb {
        background-color: #a0a0a0;
        border-radius: 5px;
    }

    .search-box textarea {
        width: 318px !important;
        padding: 10px 15px 3px 10px;
        outline: 0;
        color: #696a78 !important;
        font-size: 14px !important;
        font-family: 'Segoe UI';
        border-radius: 0.25rem;
        font-weight: 400 !important;
        margin-right: 5px;
        resize: none;
    }

    textarea.form-control-lg.form-control {
        border: 0 !important;
        outline: 0 !important;
        font-size: 14px;
        font-family: 'SegoeUI';
        font-weight: 400;
        color: #696a78 !important;
    }

    textarea.form-control-lg.form-control::placeholder {
        color: #696a78 !important;
    }

    input.form-control-lg.form-control {
        max-width: 99% !important;
        width: 100% !important;
    }

    textarea.form-control-lg.form-control {
        max-width: 99% !important;
        width: 100% !important;
        box-shadow: none;
        min-height: 42px !important;
    }

    .disabled {
        opacity: 0.5;
    }

    .readonly {
        pointer-events: none;
        cursor: default;
    }

    @media (max-width: 1500px) {
        .chat-wrapper .app-inner-layout__bottom-pane.d-block.text-center textarea.form-control-lg.form-control {
            max-width: 96% !important;
            width: 100% !important;
        }
    }

    .inline-text-editor-area {
        height: 35px;
        padding: 5px 5px 0px 5px;
        background-color: #fff;
        position: sticky;
        top: 0px;
        z-index: 9999;
    }

    .inline-text-editor-controls {
        height: 100%;
        display: flex;
        align-items: center;
        border-radius: 5px;
        background-color: #f4f4f4;
    }

    .inline-rich-text-tool-control {
        position: inherit !important;
        margin: 5px;
        color: #7e7e7e;
        height: 14px;
        width: 14px;
        cursor: pointer;
    }

    .inline-rich-text-tool-control:hover {
        background-color: #e2e2e2 !important;
    }

    .inline-rich-text-tool-control-bg {
        margin-left: 5px;
        cursor: pointer;
        border: 0px solid #e2e2e2;
        border-radius: 0.25rem;
    }

    .inline-rich-text-tool-control-bg:hover {
        background-color: #e2e2e2 !important;
    }

    .inline-rich-text-tool-control-bg:hover .inline-rich-text-tool-control {
        background-color: #e2e2e2 !important;
    }

    .message-input-textarea {
        width: 100%;
        display: inline-flex;
    }

    .message-input-textarea .edit-image {
        margin: 5px 5px 5px 5px;
        position: sticky;
        top: 40px;
        z-index: 9999;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }

    .message-input-textarea .message-input-editor {
        width: 100%;
        position: inherit;
        padding-right: 5px;
    }

    .message-input-textarea .message-input-editor a :hover {
        text-decoration: underline;
    }

    .DraftEditor-editorContainer {
        flex-direction: column;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 85px;
    }

    .public-DraftStyleDefault-block {
        margin: 0px;
    }

    div.DraftEditor-root {
        font-size: 14px;
        font-family: 'SegoeUI';
        color: #696a78 !important;
        display: grid;
    }

    .public-DraftEditor-content[contenteditable='true'] {
        margin-bottom: 5px;
    }

    .public-DraftStyleDefault-ol,
    .public-DraftStyleDefault-ul,
    .public-DraftStyleDefault-ltr {
        margin: 0px 0px 5px 0px;
    }

    .DraftEditor-editorContainer {
        margin: 12px 0px 0px 0px;
    }

    .editor .public-DraftEditorPlaceholder-root {
        color: #999;
        position: absolute;
    }

    .public-DraftEditorPlaceholder-root {
        margin-top: 10px;
    }

    .add-link-popup-box {
        position: absolute;
        bottom: 50px;
        z-index: 999;
    }

    .add-link-box-content {
        background-color: #ffffff;
        margin: 15% auto;
        padding: 20px;
        border: 1px solid #dce0e4 !important;
        border-radius: 0.25rem;
        box-shadow: 1px 1px 5px 1px #dce0e4;
    }

    .add-link-flex-controls {
        display: inline-flex;
        vertical-align: middle;
    }

    .DraftEditor-editorContainer::-webkit-scrollbar {
        width: 6px;
        background-color: #c1c1c157;
        margin-bottom: 5px;
    }

    .DraftEditor-editorContainer::-webkit-scrollbar-thumb {
        background-color: #a0a0a0;
        border-radius: 5px;
    }
}

.modal-dialog.add-link-popup-box {
    max-width: 270px;
}

.modal-dialog.add-link-popup-box .modal-body {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .btn-close {
        max-width: 2em;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: 'SegoeUI-Semibold';
        font-size: 30px;
        padding: 2px 6px 0px 0 !important;
        margin: -1.7rem -1rem -1rem auto;
    }

    .add-link-box-content button,
    .add-link-box-content .add-link-label {
        padding: 2px 10px 2px 10px;
        height: 29px;
        font-family: 'SegoeUI-Semibold';
        font-size: 14px;
        margin-left: 5px;
        margin-bottom: 5px;
    }

    .add-link-box-content .add-link-label {
        display: flex;
        margin: -10px 0px 0px 0px;
        padding: 0px;
        align-items: center;
        vertical-align: middle;
    }

    .insert-link-textarea {
        height: 29px;
        font-size: 14px;
        font-family: 'SegoeUI';
        color: #696a78 !important;
        border: 1px solid #dce0e4 !important;
        border-radius: 0.25rem;
        padding-left: 5px;
    }

    .invalid-input-textarea {
        border: 1px solid #dc3545 !important;
    }

    .insert-link-textarea:focus {
        outline: none;
    }

    .insert-link-textarea:focus::placeholder {
        opacity: 70%;
    }

    button.close {
        margin: -10px -5px 0px 0px;
    }

    .add-link-flex-controls.error-message {
        border: 0 !important;
        outline: 0 !important;
        font-size: 12px;
        font-family: 'SegoeUI';
        font-weight: 400;
        color: #dc3545 !important;
        margin-top: 3px;
        margin-bottom: -15px;
    }
}

.backdrop-link.modal-backdrop.show,
.backdrop-link.modal-backdrop.fade {
    display: none;
}