.app-discussion-list {
    height: 100%;

    .sort-buttons {
        padding-left: 17px;
        padding-bottom: 11px;
        padding-right: 1rem;
        width: 100%;

        button {
            font-size: 12px;
            text-transform: uppercase;
            padding: 0.1rem 0.5rem;
            height: 30px;
            color: #888f96;
            border: 1px solid #dce0e4;
            background-color: white;
            font-family: 'SegoeUI-Semibold';

            @media (max-width: 380px) {
                font-size: 11px;
            }
        }

        .btn-primary {
            color: white;
        }
    }

    .add-discussion {
        margin-bottom: 0;
    }

    .navbar-wrapper {
        display: block !important;
        overflow: auto;
    }

    .navbar-wrapper::-webkit-scrollbar {
        width: 6px;
        border-radius: 5px;
        background-color: #c1c1c157;
    }

    .navbar-wrapper::-webkit-scrollbar-thumb {
        background-color: #a0a0a0;
        border-radius: 5px;
    }

    .search-box {
        position: relative;
        width: 100%;
    }

    .search-box img {
        position: absolute;
        right: 11px;
        top: 6px;
    }

    img {
        vertical-align: middle;
        border-style: none;
    }

    .search-box input {
        width: 100%;
        padding: 2px 15px 3px 12px;
        height: 30px !important;
        border: 1px solid #dce0e4 !important;
        outline: 0;
        color: #888f96 !important;
        font-size: 14px !important;
        font-family: 'SegoeUI-Semibold';
        border-radius: 0.25rem;
        font-weight: 400 !important;
    }

    .app-inner-layout__sidebar-header {
        position: sticky;
        top: 0;
    }

    .buttons-wrapper {
        display: flex;
        padding-left: 17px;
        padding-right: 17px;
        padding-bottom: 11px;
        justify-content: space-between;
        flex-wrap: wrap;

        .button-wrapper-item {
            cursor: pointer;
        }

        .button-wrapper-item:not(:last-child) {
            margin-right: 1rem;
        }
    }

    .link-button {
        font-size: 14px;
        color: #535bd6 !important;
        text-decoration: underline;
        font-family: 'SegoeUI-Semibold';
        cursor: pointer;
        line-height: 24px;
    }

    .poc-members-button {
        label {
            padding-right: 0.5rem;
        }
    }

    li.nav-item {
        padding: 0px 10px;
    }

    button.not-active.dropdown-item .widget-content.p-0.let-chat-data .widget-heading {
        color: #fff;
    }

    button.not-active.dropdown-item .widget-content.p-0.let-chat-data .widget-subheading {
        color: #fff;
    }

    .dropdown-item:active,
    button.not-active.dropdown-item {
        color: #ffffff !important;
        text-decoration: none;
        background-color: #535bd6 !important;
        opacity: 1;
        border-radius: 8px;
    }

    button.dropdown-item {
        padding: 11.82px 18px !important;
    }

    .not-active img.hover-image {
        display: inline-block !important;
    }

    .not-active img.default-image {
        display: none !important;
    }

    .dropdown-item:active .hover-image {
        display: none !important;
    }

    .dropdown-item .hover-image {
        display: none !important;
    }

    .dropdown-item:active .hover-image {
        display: inline-block !important;
    }

    .dropdown-item:active .default-image {
        display: none !important;
    }

    .dropdown-item:hover,
    .dropdown-item:focus {
        background-color: transparent;
    }

    .chat-icon-text-wrapper {
        justify-content: space-between;
    }

    .widget-content .widget-content-left {
        padding-right: 10px;
    }

    .widget-content-left.mr-3 {
        margin-right: 0 !important;
        padding-right: 0 !important;
    }

    .widget-content-left.group-icon {
        margin-right: 0 !important;
        padding-right: 20px !important;
    }

    .widget-content-left.group-icon img {
        width: 20px !important;
    }

    .widget-content .buttons-group {
        display: flex;
        flex: 1 1;
        position: relative;
        align-items: center;
        justify-content: flex-end;
    }

    .widget-content-left.mr-3.group-icon.avatar-icon {
        width: 10px;
    }

    .widget-content .widget-content-left .widget-heading {
        opacity: 1;
        font-weight: bold;
        font-size: 14px;
        font-family: 'SegoeUI-Bold';
        color: #000000;
        padding-bottom: 3px;
    }

    .widget-content .widget-content-left .widget-subheading {
        opacity: 1;
        font-weight: bold;
        font-size: 14px;
        font-family: 'SegoeUI';
        font-weight: 400;
        color: #000000;
    }

    .progress-bar {
        position: absolute;
        left: 0px;
        line-height: 53px;
        width: 43px;
        top: -10px;
        left: -15px;
        background-color: transparent !important;

        text {
            display: none;
        }
    }

    .avatar-icon {
        border: none !important;
        width: 100% !important;
        height: 100% !important;
        overflow: initial !important;
    }

    .avatar-icon img {
        width: auto !important;
        height: auto !important;
    }

    .avatar-icon-wrapper .badge-dot.badge-dot-lg.badge-bottom {
        top: 30px !important;
        right: 0;
        bottom: 0;
    }

    .avatar-icon-wrapper .badge-dot.badge-dot-lg.badge-bottom {
        top: 30px !important;
        right: 0;
        bottom: 0;
    }

    .search-btn-wrapper {
        padding-top: 14px !important;
    }

    .readonly {
        pointer-events: none;
        cursor: default !important;
        font-size: 14px;
        color: #5e5e5e !important;
        font-family: 'SegoeUI-Semibold';
        line-height: 24px;
    }

    .dropdown-item:active .widget-content-left div {
        color: #ffffff !important;
    }

    .bottom-line {
        margin-top: 0.75em;
        border-bottom: 1px solid #535bd6;
        margin-bottom: 0.75em;
    }
}

@media screen and (max-width: 992px),
(max-height: 550px) {
    .app-inner-layout__sidebar-header {
        position: sticky;
        top: 0;
        z-index: 0 !important;
        display: contents;
    }

    .chat-layout.app-inner-layout .app-discussion-list {
        display: block !important;
        max-width: 100%;
        width: 100% !important;
        padding-bottom: 10px;
    }

    .app-discussion-list {
        max-height: 342px;

        .navbar-wrapper {
            max-height: 210px;
            height: 100%;
        }

        .search-box {
            position: relative;
            max-width: 100%;

            input {
                width: 100% !important;
                max-width: 100% !important;
            }
        }
    }

    .app-inner-layout__top-pane.practice-title-searchbar-wrapper .pane-left {
        padding: 8px 0 0px 5px;
    }

    .mobile-app-menu-btn {
        display: none !important;
    }

    .app-inner-layout__top-pane.practice-title-searchbar-wrapper .pane-right {
        display: block !important;
        align-items: center;
        margin-left: unset;
        padding-top: 13px;
    }

    .search-box {
        position: relative;
        max-width: 100%;

        input {
            width: 100% !important;
            max-width: 100% !important;
        }
    }
}

@media (max-width: 1600px) {
    .app-discussion-list {
        .buttons-wrapper {
            padding-bottom: 0 !important;
        }

        .search-navbar {
            padding-bottom: 0.25em !important;
        }
    }
}


.poc-discussion-list {
    width: 385px !important;
    flex: 0 0 385px !important;
}