.review-document-form {
    .link-button {
        color: #545cd8;
        text-decoration: underline;
        cursor: pointer;
        margin-bottom: 0.5rem;
    }

    .file-display {
        border-right: none;
        background-color: #fff;
        font-size: 14px;
    }

    .file-addon {
        background-color: #fff;
        border: 1px solid #ced4da;
        border-left: 0;

        .download-icon {
            cursor: pointer;
        }
    }
}
