.wallet-modal-popup {
    $label-width-for-computers: 270px;
    $label-height-for-computers: 30px;

    $label-width-for-mobile: 70vw;
    $label-height-for-mobile: 6vh;

    label {
        margin-bottom: 0 !important;
    }

    .adjustment-comment-input-invalid .search-box.edit-search {
        border: 1px solid red !important;
        padding-right: calc(1.5em + 0.75rem);
        background-image: URL("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: center right 5px;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .user-form {
        font-size: 14px;
        font-family: 'SegoeUI-Semibold';
        margin-bottom: 40px;
    }

    .btn-footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
        padding-right: 0 !important;
        .btn-primary {
            margin-left: 10px;
        }
    }

    .rich-text-input {
        padding: 0 !important;

        .search-box.edit-search {
            margin-right: -100%;
        }

        .form-group {
            height: auto;
        }
    }

    .adjustment-comment {
        height: auto !important;
        margin-bottom: -1.2rem !important;
    }

    .member-data {
        font-size: 14px;
        .text-style {
            color: #525a64;
        }

        .subtext-style {
            color: #888f96;
        }

        .col {
            margin-top: 5px;
        }
    }

    .rodal,
    .rodal-mask {
        width: 100%;
        height: 100%;
    }

    .rodal-dialog {
        top: 20%;
        max-width: 500px;
    }

    .shadow-none {
        box-shadow: none !important;
    }

    .form-control.is-invalid {
        background-position: center right 5px !important;
    }

    .min-width {
        min-width: 234px;
    }

    .input-with-icon {
        width: $label-width-for-computers - 40 !important;
    }

    .credit-type-select {
        width: $label-width-for-computers;
        height: $label-height-for-computers;
        cursor: pointer;
        font-size: 14px !important;
        color: #888f96 !important;
    }

    .rw-dropdown-list-value {
        color: #888f96 !important;
    }

    textarea {
        font-size: 14px !important;
        color: #888f96 !important;
    }

    .rw-list-option {
        color: #888f96 !important;
    }

    .rw-state-selected {
        color: #fff !important;
    }

    .invalid-feedback {
        display: none !important;
    }

    .prepend-addon {
        height: 30px;
    }

    .input-group {
        width: $label-width-for-computers;
    }

    .rodal-dialog input.form-control {
        height: $label-height-for-computers;
        width: $label-width-for-computers;
        font-size: 14px !important;
        color: #888f96;
    }

    .form-group {
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        height: $label-height-for-computers;
    }

    label {
        display: flex;
        align-items: center;
    }

    textarea {
        resize: none;
        overflow: auto;
        width: $label-width-for-computers;
        height: 1.8 * $label-height-for-computers;
        border: 1px solid #cdcff4;
    }

    .close-button {
        border: 1px solid;
        border-color: gray;
        background-color: white;
        color: gray;
    }

    .close-button:hover {
        background-color: gray;
        color: white;
    }

    input[aria-invalid='true'] {
        border: 1px solid red !important;
        border-radius: 0.25rem;
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: URL("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: center right 5px;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .currency-input {
        border-top-right-radius: 0.25rem !important;
        border-bottom-right-radius: 0.25rem !important;
    }

    .rw-state-readonly {
        background-color: #e9ecef;
        opacity: 1;

        div {
            background-color: #e9ecef;
            opacity: 1;
        }
    }

    @media (max-width: 580px) {
        .form-group {
            flex-direction: column;
            margin-bottom: 30px;
            > .form-control {
                width: 100% !important;
            }
        }
        .adjustment-comment {
            margin-bottom: -4.5rem !important;
        }
        .input-group {
            width: 100% !important;
        }
        .adjustment-comment > .form-group {
            height: 100px !important;
        }
        textarea {
            height: 100px !important;
        }
        .user-form {
            margin-bottom: 100px;
        }
        .rw-dropdown-list {
            width: 100% !important;
        }
        label {
            margin-bottom: 5px !important;
        }
        .input-group > .form-group {
            min-width: calc(100% - 36px);
        }
    }
}
