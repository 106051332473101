.direct-chat {
    display: flex;
    height: 100%;

    .rooms-list {
        height: 100%;
        padding: 1em 1em 10px 1em;
        width: 25em;
    }

    .rooms-list-only {
        @extend .rooms-list;
        margin-top: 2em;
    }

    .messages-container {
        width: 60em;
        display: flex;
        flex-direction: column;
        background-color: white;

        .info-tooltip {
            margin-right: auto;
            margin-top: -1rem;
            margin-left: 0.2rem;
        }

        .flex-container {
            display: flex;
            flex-direction: column;
            overflow: auto;
            height: 100%;
            position: relative;
        }

        .practice-mgt-title {
            text-transform: uppercase !important;
            font-size: 14px;
            font-family: 'SegoeUI-Bold';
            margin-bottom: 0;
            color: #000000;
        }

        .practice-title {
            display: flex;
            align-content: center;
            align-items: center;
            text-align: left;
            padding-top: 1.25rem;
            padding-inline: 1.5rem;
            padding-bottom: 0.5em;
            border-bottom: 1px solid #e5e5e5;
            justify-content: space-between;
        }

        .pane-right {
            margin-right: 1em;
        }

        .post-title {
            text-transform: capitalize;
            font-size: 14px !important;
            padding-top: 2px;
        }

        .messages {
            height: 100%;
            display: flex;
            overflow: auto;
            flex-direction: column;
        }
    }

    @media (max-width: 800px) {
        flex-direction: column;

        .rooms-list {
            margin-top: 2em;
            max-height: 30vh;
            width: auto;
        }

        .messages-container {
            width: auto;
            height: 50vh;
        }
    }
}