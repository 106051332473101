.sponsor {
    height: 26px;
    place-self: flex-start;
    margin-left: 0.5em;
}

.sponsor-tooltip-arrow {
    background: transparent;
    -webkit-transform: none;
    transform: none;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #cdcdcd;
    bottom: -11px !important;
}