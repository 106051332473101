.create-data-table-form {
    .rw-multiselect-tag-label {
        max-width: 90%;
        display: inline-table;
        vertical-align: middle;
        height: 100%;
    }

    .rw-multiselect-tag-btn {
        float: right;
        display: flex;
        padding: 0.25em 0.35em 0em 0em;
    }
}
