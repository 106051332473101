.table-settings {
    .rodal-dialog {
        max-width: 500px;
        top: 15%;
    }

    .ml-56 {
        margin-left: 56px;
    }

    .rw-multiselect .rw-widget-input.rw-widget-picker.rw-widget-container {
        height: 100% !important;
        overflow-x: auto;
    }

    .rw-dropdownlist-search {
        display: block;
    }

    .rw-multiselect-input {
        display: block;
        padding: 6px 12px;
        height: 20px;
    }

    .rw-multiselect-input::placeholder {
        color: #888f96;
    }

    .rw-multiselect-taglist {
        align-items: center !important;
    }
    .rw-multiselect-tag {
        width: fit-content;
        display: inline-table;
        color: #888f96;
        height: 20px;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold' !important;
        border-radius: 4px;
        background-color: #e5e8eb;
        border: 1px solid #e5e8eb;
        margin-left: 5px;
        margin-bottom: 2px;
        margin-top: 2px;
        min-height: 0;
    }

    .stages-settings-list {
        &::-webkit-scrollbar {
            cursor: pointer;
            height: 6px;
            width: 4px;
            background-color: #e5e5e5;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #a0a0a0 !important;
            height: 4px;
            width: 3px;
            border-radius: 5px;
        }
    }

    .field-switcher {
        min-height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .field-label {
            margin-bottom: 0px;
            font-size: 14px;
            font-family: 'SegoeUI-Semibold';
            margin-left: 0.5rem;
        }
    }

    .button-container {
        margin-top: 1.5rem;
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
    }

    .row {
        margin-bottom: 0.5rem;
    }

    .questionnaire-section-input {
        padding: 0;

        > :not(:last-child) {
            margin-bottom: 0.5rem;
        }
    }

    .section-header {
        font-size: 16px;
        font-family: 'SegoeUI-Semibold';
        margin-bottom: 1rem;
        margin-top: 1.5rem;
    }

    .stages-settings-list {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        max-height: 300px;
        overflow: auto;
        flex-wrap: nowrap;
    }
}
