.top-liked-posts-modal {
    min-width: 500px;

    .tr {
        cursor: pointer;
    }

    label {
        color: #525a64 !important;
        font-size: 13px !important;
        font-family: 'SegoeUI-Semibold' !important;
    }

    th:nth-child(1) {
        label {
            padding-left: 15px !important
        }
    }

    td:nth-child(1) {
        padding-left: 15px !important
    }

    .buttons-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 1em;
    }

    .cancel-btn {
        color: #919191;
        height: 29px;
        border: 1px solid #919191;
        background-color: transparent;
        max-width: 70px;
        width: 100%;
        padding: 2px 5px 5px 5px;
    }
}

.modal-dialog.top-liked-posts-modal tr.MuiTableRow-root.MuiTableRow-hover {
    cursor: pointer;
}

.modal-dialog.top-liked-posts-modal {
    min-width: 800px;
    min-height: 200px;
    max-height: 600px;
}

.modal-dialog.top-liked-posts-modal .editTitleBody.deleteModalBody.profileViewPopUpBody.modal-body {
    padding: 21px 30px 28px 30px !important;
}

.modal-dialog.top-liked-posts-modal .MuiTableCell-alignRight {
    text-align: left !important;
    flex-direction: row-reverse;
}

.modal-dialog.top-liked-posts-modal .MuiPaper-elevation1 {
    box-shadow: none !important;
}

.modal-dialog.top-liked-posts-modal th:nth-child(1) {
    width: 15%;
}

.modal-dialog.top-liked-posts-modal th:nth-child(2) {
    width: 30%;
}

.modal-dialog.top-liked-posts-modal th:nth-child(3) {
    width: 100%;
}

.modal-dialog.top-liked-posts-modal tr.MuiTableRow-root.MuiTableRow-head {
    background: #dce0e4 !important;
    height: 50px;
}

.modal-dialog.top-liked-posts-modal td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignRight,
.modal-dialog.top-liked-posts-modal td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter {
    color: #888f96;
    padding: 5px 0px 5px 0px;
    font-size: 13px !important;
    font-family: 'SegoeUI-Semibold' !important;
    height: 31px;
    vertical-align: initial;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.modal-dialog.top-liked-posts-modal h5.modal-title {
    font-size: 18px;
}

.modal-dialog.top-liked-posts-modal button.close {
    padding: 5px 15px !important;
}


.modal-dialog.top-liked-posts-modal .MuiTableContainer-root {
    width: 100%;
    overflow-x: auto;
    min-height: 100px;
    max-height: 310px;
    overflow-y: auto;
}

.modal-dialog.top-liked-posts-modal .MuiTableContainer-root::-webkit-scrollbar {
    width: 5px;
    height: 4px;
    border-radius: 50px;
    background-color: #e5e5e5;
    height: 0;
    border-radius: 16px;
    width: 5px;
}

.modal-dialog.top-liked-posts-modal .MuiTableContainer-root::-webkit-scrollbar-thumb {
    background: #a0a0a0;
    height: 0;
    border-radius: 50px;
    width: 5px;
}

.modal-dialog.top-liked-posts-modal {
    transform: translate(-50%, -50%) !important;
    position: absolute;
    left: 50%;
    top: 50%;
}

.modal-dialog.top-liked-posts-modal .modal-content {
    min-height: 200px;
    max-height: 475px;
    background: #fff;
    border: none !important;
}

@media (max-width: 1440px) {
    .modal-dialog.top-liked-posts-modal .modal-content {
        height: auto;
        background: #fff;
    }
}

@media screen and (max-width: 992px), (max-height: 550px) {
    .modal-dialog.top-liked-posts-modal {
        max-width: 95%;
        height: auto;
    }

    .modal-dialog.top-liked-posts-modal table.MuiTable-root.top-liked-table {
        width: 646px;
    }

    .modal-dialog.top-liked-posts-modal {
        min-width: 620px;
    }
}

@media (max-width: 640px) {
    .modal-dialog.top-liked-posts-modal {
        left: 48.5%;
    }

    .modal-dialog.top-liked-posts-modal {
        min-width: 92%;
        height: auto;
    }

    h5.modal-title {
        line-height: 20px !important;
    }
}

.modal-dialog.top-liked-posts-modal .MuiTableContainer-root table {
    border: 1px solid #dce0e4;
    border-top: 0px;
    border-bottom: 0px;
    margin-bottom: 0px;
}