.documents-templates {
    margin-top: 15px;

    .action-icons {
        font-size: 18px;
        cursor: pointer;
        display: flex;
        gap: 10px;

        .delete-button {
            color: #d32f2f;
        }
    }

    table th:first-of-type {
        min-width: 300px;
    }
}
