.update-poc-popup {
    .save-btn {
        width: 100% !important;
    }

    .other-field {
        display: flex;
        color: #888f96;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
        flex-direction: column;
        max-width: 100% !important;
        margin-bottom: 30px;
        margin-top: 7px;
        height: 30px !important;
    }

    .edit-profile-modal.modal-body {
        padding: 17px 24px 18.3px 24px !important;
    }

    .editTitleHeader h5.modal-title {
        font-family: 'SegoeUI-Semibold' !important;
        font-size: 18px !important;
        color: #000000;
    }

    .edit-profile-modal.modal-body span {
        color: #888f96;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
    }

    .title-wrapper,
    .edit-wrapper {
        display: flex;
        justify-content: space-between;
    }

    .type-title {
        width: 140px;
    }

    .title {
        padding-right: 1em;
    }

    .select-file-wrapper {
        display: flex;
        justify-content: space-between;
        flex: 0 0 100% !important;
        max-width: 100% !important;
        margin-bottom: 8px;
    }

    .modal-header {
        padding: 17.5px 1rem;
    }

    select.selectCustomer {
        background: #fff;
    }

    .input-title a {
        text-decoration: underline !important;
        color: #535bd6 !important;
        line-height: 23px;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
        cursor: pointer;
    }

    label {
        margin-bottom: 2px !important;
    }

    .edit-wrapper {
        padding: 21px 0 19px 0;
    }

    textarea#exampleFormControlTextarea1 {
        height: 30px;
        overflow: hidden;
        padding: 5px 10px !important;
    }

    .select-file-wrapper label,
    .intro-text-wrapper label {
        margin-bottom: 7px !important;
    }

    .year-drop-down-margin {
        margin-right: 7px !important;
    }

    .btn-wrapper {
        padding-top: 0px !important;
    }

    .modal-dialog.editTitlePopup.knowledgeContetntPopup.knowledgeInDetail {
        height: auto !important;
    }

    span.practice span {
        display: block;
        margin-top: -4px;
    }

    label {
        color: #525a64 !important;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
        margin-bottom: 6px;
    }

    .info-field {
        max-width: 140px !important;
        width: 100%;
        flex: 0 0 50% !important;
    }

    .intro-text-area textarea {
        width: 100%;
        resize: none;
        border: 1px solid #dce0e4 !important;
        border-radius: 0.25rem;
        outline: 0;
        font-size: 13px !important;
        color: #888f96 !important;
        font-family: 'SegoeUI-Semibold' !important;
        padding: 5px 10px !important;
        box-shadow: none;
    }

    textarea.form-control-lg.form-control::-webkit-scrollbar {
        width: 6px;
        background-color: #c1c1c157;
    }

    textarea.form-control-lg.form-control::-webkit-scrollbar-thumb {
        background-color: #a0a0a0;
        border-radius: 5px;
    }

    .year-drop-down.type-title {
        width: 50%;
    }

    .width-100-percent {
        width: 100%;
    }

    .customerStyle {
        background: url('../../assets/images/arrow.png');
        background-position: calc(100% - 10px);
        background-repeat: no-repeat;
        border: 1px solid #dce0e4 !important;
        outline: 0;
        max-width: 270px;

        border-radius: 0.25rem;
        height: 30px;
        overflow: hidden;
        cursor: pointer;
    }

    label.upload-now {
        color: #535bd6 !important;
        cursor: pointer;
        position: absolute;
        right: 25px;
        font-size: 13px !important;
        font-family: 'SegoeUI-Semibold' !important;
        padding-top: 2px;
        text-decoration: underline;
    }

    .preview-container {
        display: flex;
        justify-content: space-between;
    }

    .upload-file-group {
        margin-right: -1px;
        padding: 0;
        height: 30px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #dce0e4 !important
    }

    .choose-file-label {
        padding-inline: 10px;
        padding-block: 4px;
        cursor: pointer;
        margin-bottom: 0 !important;
        color: #888f96 !important;
    }

    .upload-file-input {
        opacity: 0;
        position: absolute;
        z-index: -1;
    }

    .file-section.form-group {
        margin: 0;
    }

    .input-file-text {
        border: 1px solid #dce0e4 !important;
        border-radius: 0.25rem;
        outline: 0;
        padding: 2px 11px 4px 10px !important;
        height: 30px !important;
        font-size: 13px !important;
        color: #888f96 !important;
        margin-bottom: 9px;
        font-family: 'SegoeUI-Semibold' !important;
        border-radius: 0;
    }

    .btn-wrapper {
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .cancel-btn {
        color: #919191;
        height: 29px;
        border: 1px solid #919191;
        background-color: transparent;
        max-width: 70px;
        width: 100%;
        padding: 2px 5px 5px 5px;
        margin-right: 10px;
    }

    .save-btn {
        background: #535bd6 !important;
        border-color: #535bd6 !important;
        color: white !important;
        height: 29px;
        padding: 2px 5px 3px 5px;
        width: 100%;
    }

    .file-section {
        padding-top: 7px;
        flex-wrap: nowrap;

        .form-group {
            width: 100%;
        }
    }

    button.close {
        font-size: 30px;
        font-family: 'SegoeUI-Semibold';
        padding: 4px 15px 5px 12px !important;
    }

    .disabled {
        pointer-events: none;
    }

    .trash-icon {
        padding: 7.5px;
        height: 100%;
        cursor: pointer;
        border: 1px solid #dce0e4 !important;
        margin-left: -1px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

@media (max-width: 504px) {
    .update-poc-popup {

        .title-wrapper,
        .edit-wrapper {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            flex: 0 0 100%;
        }

        .year-drop-down {
            width: 100%;
        }

        .select-file-wrapper {
            display: flex;
            justify-content: space-between;
            flex: 0 0 100% !important;
            max-width: 100% !important;
            margin-bottom: 8px;
            flex-wrap: wrap;
        }

        .year-drop-down:last-child {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
}