.confirmation-modal{
    .modal-title {
        margin-bottom: 0 !important;
        color: black;
    }

    .modal-footer {
        background-color: #fff;
        padding-bottom: 0 !important;
        border-top: none;
    }

    .btn-cancel {
        height: auto;
        width: auto;
        background-color: #fff;
        color: #919191;
        border-color: #919191;
    }

    .form-group {
        margin-bottom: 1rem !important;
    }
}