.add-new-option-form-row {
    margin-top: 1rem !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    input {
        height: 35px;
    }

    .form-group {
        margin: 0;
    }
}
