.data-request-dashboard {
    .white-box-content {
        padding: 1rem;
    }

    .page-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .body-tabs {
        margin-bottom: 1rem;
    }

    .loader-container {
        align-items: flex-end;
    }

    .table th,
    .table td {
        border: none;
    }

    .table tbody th {
        font-family: 'SegoeUI';
        font-weight: 400;
    }

    .table tbody tr:nth-child(odd) {
        background-color: #f7f7f7;
    }

    .table .member-access-rights-column {
        white-space: nowrap;
    }

    .table .column-header-tooltip-wrapper {
        display: flex;
        align-items: flex-end;
    }

    .table th {
        color: #000;
        font-size: 14px;
        padding: 15px;
        font-family: 'SegoeUI-Bold';
        text-align: left;
        cursor: pointer;
    }

    .react-bootstrap-table {
        overflow: auto;
    }
    .react-bootstrap-table::-webkit-scrollbar {
        cursor: pointer;
        height: 6px;
        width: 4px;
        background-color: #e5e5e5;
        border-radius: 10px;
    }
    .react-bootstrap-table::-webkit-scrollbar-thumb {
        background-color: #a0a0a0 !important;
        height: 4px;
        width: 3px;
        border-radius: 5px;
    }

    .table td {
        font-size: 14px;
        color: #000;
        text-align: left;
    }

    .table td {
        vertical-align: middle;

        .checkbox-field {
            text-align: center;
        }
        .complete-checkbox {
            width: 100%;
            position: relative;
            cursor: pointer;
            margin: 0 !important;
        }

        .in-review-notification-badge {
            top: -3em;
            left: -3em;
        }
    }

    .table td,
    .table tbody th {
        padding: 12px 15px;
    }

    @media (max-width: 767px) {
        .card-body {
            overflow: auto;
        }
    }
}
