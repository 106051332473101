.vote-component-app {
    .type-info {
        width: 275px;
    }

    .vote-to-remove {
        width: 30%;
        text-align: center;
    }

    .intianl-removal-btn-div {
        text-align: center;
        margin-top: 23px;

        .inital-removal-voting-btn {
            background-color: #ed7d31;
            color: #ffffff;
            height: 29px;
            padding: 3px 5px 4px 5px;
            max-width: 179px;
            width: 100%;
            border: 0;
            text-align: center;
        }

        .inital-removal-voting-btn:not(:disabled):not(.disabled):active,
        .inital-removal-voting-btn:not(:disabled):not(.disabled).active,
        .show > .inital-removal-voting-btn.dropdown-toggle {
            background-color: #ed7d31 !important;
            color: #ffffff !important;
            background: #ed7d31 !important;
        }
    }

    .vote-detail {
        padding: 11px 0 10px 15px;
        border: 1px solid #697079;

        .vote-row {
            display: flex;
            justify-content: space-between;
        }

        h6 {
            font-size: 13px;
            font-family: 'SegoeUI-Semibold';
            color: #000000;
        }

        .member {
            width: 40%;
            text-align: left;

            label {
                display: flex;
                flex-direction: column;
                color: #888f96 !important;
                margin-bottom: 9px;
                font-size: 13px !important;
                line-height: 20px;
                font-family: 'SegoeUI-Semibold';
            }
        }

        .check {
            padding: 0px 0 0px 0;
            display: block;
            height: 18px;
            margin-bottom: 2px;

            label {
                margin-bottom: 0px !important;
                display: inline-block;
                height: 18px;
                background: #dedede;
                cursor: pointer;
            }

            input[type='radio'] {
                display: none;
            }

            input[type='radio'] + label:before {
                content: '';
                height: 18px;
                width: 34px;
                display: inline-block;
                padding: 0 0 0 0px;
                cursor: pointer;
            }

            input[type='radio']:checked + label:before {
                content: 'X';
                font-size: 13px;
                padding: 0px 0 0px 0;
                height: 18px;
                width: 34px;
                line-height: 20px;
                margin: 0px 0 3.5px 0;
                position: relative;
                top: -2px;
                cursor: pointer;
                font-weight: bold;
                color: #000000;
            }
        }
    }

    label#kenCole {
        background: #fff !important;
        border: 0.5px solid #535bd6;
        width: 34px;
        padding: 0px;
        line-height: 0;
    }

    .small-grey {
        color: #888f96;
        font-size: 10px;
        margin-top: 10px;
    }

    .vote-btn img {
        margin-right: 11px;
    }

    .vote-btn {
        border: 0;
        font-size: 14px;
        color: #fff;
        font-family: 'SegoeUI-Semibold';
        padding: 5px 15px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .vote-btn:last-child {
        margin-left: 10px;
    }

    .keep-button-color {
        background-color: #3bc47e !important;
    }

    .delete-button-color {
        background-color: #d40000 !important;
    }

    .change-vote-button {
        border: 0;
        font-size: 14px;
        color: #fff;
        font-family: 'SegoeUI-Semibold';
        padding: 6px 15px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1px;
    }

    .vote-buttons-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 0 0px;
    }

    .chart-data {
        margin-left: -15px;
    }

    @media (max-width: 480px) {
        .chart-data {
            margin-left: 0;
            overflow-x: auto;
        }

        .vote-buttons-wrapper {
            margin-top: 15px;
        }
    }

    @media (max-width: 375px) {
        .vote-btn,
        .change-vote-button {
            width: 100%;
        }

        .vote-buttons-wrapper {
            flex-direction: column;
        }

        .vote-btn {
            margin-left: 0;
            width: 100%;
            margin-top: 10px;
        }

        .vote-btn:last-child {
            margin-left: 0;
        }
    }
}
