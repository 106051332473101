.timeline-templates {
    margin-top: 15px;

    .card-body {
        overflow: unset;
    }

    .react-bootstrap-table {
        overflow: auto;
    }

    .react-bootstrap-table-pagination {
        flex-wrap: nowrap;
    }

    .name-column-header {
        width: 25% !important;
    }

    .last-edited-column-header {
        width: 20% !important;
    }

    .actions-column-header {
        width: 25% !important;
    }

    .modal-body {
        padding: 1rem !important;
    }

    .modal-footer {
        padding: 0 1rem;
    }

    .flex {
        display: flex;
    }

    .action-controls {
        justify-content: center;
        align-items: center;
        padding: 0 10px;
    }

    .template-control-icon {
        cursor: pointer;
        font-size: 20px;
    }

    .action-icons {
        font-size: 18px;
        cursor: pointer;
        display: flex;
        gap: 10px;

        .delete-button {
            color: #d32f2f;
        }
    }

    .back-btn {
        float: right;
        margin-right: 21px;
    }

    @media (min-width: 1527px) {
        .actions-column-header {
            width: 15% !important;
        }
    }
}