.direct-host {
    .wrapper {
        width: 100%;
        margin: 0;
        height: 100%;
        position: absolute;
        z-index: 10;
    }

    .icon {
        display: inline-block;
        border-radius: 60px;
        padding: 8px;
        height: 26px;
        color: white;
        background-color: #535bd6;
        margin-top: 3px;
    }

    .toggle-button {
        padding: 0;
    }

    .unread-messages {
        position: absolute;
        height: 20px !important;
        width: auto !important;
        border-radius: 20px;
        margin-left: 0 !important;
        background-color: red;
        color: white;
        top: 2.6em;
        left: 2.2em;
    }
}

.fade.show {
    transition: none;
}

.popover {
    z-index: 10;
    height: 42em;
    max-height: 80vh;
    max-width: 90vw;
    background: #f8f9fa;
    border: #f4f4f4 solid 1px;
    box-shadow: 0px 0px 30px 5px #888f96;

    position: fixed !important;

    .popover-inner {
        height: 100%;
    }

    .host-popover {
        height: 100%;
    }

    .show .has-rooms {
        @media (max-width: 1400px) {
            left: -5% !important;
            top: 50% !important;
            max-width: 90% !important;
            -webkit-transform: translateY(-50%) !important;
            transform: translateY(-50%) !important;
        }
    }

    @media (max-width: 800px) {
        height: 80vh;
    }
}
