.app-upload-files {
    .container {
        padding: 0;
    }

    .file-info-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: end;

        .file-info-delete-button {
            font-size: 20px;
            cursor: pointer;
        }

        .file-info-name {
            overflow-wrap: anywhere;
        }
    }

    ul {
        list-style: none;
        padding: 0;
    }

    li {
        font-family: 'SegoeUI-Semibold' !important;
        margin-top: 8px;
        margin-bottom: 8px;
        font-size: 13px;
        color: rgb(136, 143, 150);
    }

    .modal-header {
        padding: 18px 17px 18px 16px !important;

        button.close {
            font-size: 30px;
            font-family: 'SegoeUI-Semibold';
            padding: 4px 15px 5px 12px !important;
        }

        h5 {
            text-transform: none !important;
            font-size: 18px !important;
            font-family: 'SegoeUI-Semibold' !important;
            display: inline-block;
            color: #000000;
        }
    }

    .edit-title-body.edit-detail.modal-body {
        padding: 22px 26px 8px 26px !important;
    }

    .dropzone {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 11px 10px 15px 10px;
        border-width: 1px;
        border-radius: 0.25rem;
        border-color: #dce0e4;
        border-style: solid;
        background-color: #ffffff;
        color: #888f96;
        outline: none;
        transition: border 0.24s ease-in-out;

        .drop-text {
            font-family: 'SegoeUI-Semibold' !important;
            margin-top: 15px;
            margin-bottom: 10px;
        }

        .browse-btn {
            background: #535bd6 !important;
            border-color: #535bd6 !important;
            width: 108px;
            height: 29px;
            padding: 0px 3px 1px 3px;
            color: #ffffff;
            font-family: 'SegoeUI-Semibold';
            font-size: 14px;
            border: 0;
            border-radius: 0.25rem;
            margin-top: 11px;
            cursor: pointer;
        }

        .or-option {
            font-family: 'SegoeUI-Semibold';
        }
    }

    .canel-upload-wrapper {
        margin-top: 15px;
        margin-bottom: 15px;
        text-align: right;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .cancel-btn {
            color: #919191;
            border: 1px solid #919191;
            max-width: 70px !important;
            height: 29px;
            padding: 0px 3px 1px 3px;
            font-size: 14px;
            background-color: transparent;
            font-family: 'SegoeUI-Semibold';
            border-radius: 0.25rem;
            cursor: pointer;
            font-size: 14px !important;
            width: 100%;
        }

        .upload-button {
            color: #ffffff;
            border: 0;
            width: 72px;
            height: 29px;
            padding: 0px 3px 1px 3px;
            background-color: #535bd6;
            font-family: 'SegoeUI-Semibold';
            border-radius: 0.25rem;
            cursor: pointer;
            font-size: 14px !important;
            margin-left: 10px;
        }
    }
}

@media (max-width: 502px) {
    .app-upload-files {
        max-width: 90% !important;
        width: 100% !important;
    }
}
