.discount-coupon {
    .main-container {
        position: relative;
        width: 180px;
        height: 100px;
    }

    .svg-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('/assets/images/coupon-img.svg');
        background-size: cover;
    }

    .my-component {
        position: absolute;
        top: 10%;
        left: 35%;
        padding-left: 10px;
        padding-top: 10px;
    }

    h3 {
        font-size: 14px;
        margin-bottom: 0.25em;
        color: #70ad47;
    }

    h5 {
        font-size: 10px;
        color: #70ad47;
        margin-top: 0.5em;
    }
}