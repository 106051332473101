$input-shift: 5px;

.fee-based-permisstion-sections {
    .practice-sale-cover {
        padding-left: 28px;

        label {
            font-family: 'SegoeUI-SemiBold';
            font-size: 15px;
            margin: 5px 0 7px 0;
        }

        input,
        textarea {
            width: calc(100% - #{$input-shift}) !important;
            margin-left: $input-shift;
            color: #888f96;
            font-size: 13px;
            font-family: 'SegoeUI-Semibold';
            border: 1px solid #dce0e4;
            box-shadow: none;
        }
    }
}
