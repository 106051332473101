.custom-reactstrap-tooltip {
    .tooltip-inner {
        font-size: 13px !important;
        font-weight: 300;
        font-family: 'SegoeUI';
        text-transform: none;
        background-color: #ebebeb !important;
        border: 1px solid #cdcdcd;
        border-radius: 5px;
        box-shadow: 0 0 3px 0 rgba(1, 1, 1, 0.09);
        font-size: 14px;
        color: #656565 !important;
        line-height: 1.15;
        z-index: 10;
    }

    &[x-placement^='top'] {
        .arrow::before {
            border-top-color: #cdcdcd !important;
            border-width: 0.6rem 0.6rem 0 !important;
        }
    }

    &[x-placement^='left'] {
        .arrow::before {
            border-left-color: #cdcdcd !important;
            border-width: 0.4rem 0rem 0.4rem 0.6rem !important;
        }
    }

    &[x-placement^='bottom'] {
        .arrow::before {
            border-bottom-color: #cdcdcd !important;
        }
    }

    &[x-placement^='right'] {
        .arrow::before {
            border-right-color: #cdcdcd !important;
            border-width: 0.4rem 0.6rem 0.4rem 0;
        }
    }
}
