.create-stage-popup {
    .rodal-dialog {
        top: 20%;
        max-width: 400px;
    }

    label {
        font-size: 14px;
        font-family: 'SegoeUI-Semibold';
        min-height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .btn-cancel {
        background-color: #fff;
        border-color: #919191;
        color: #919191;
    }

    .buttons-wrapper {
        display: flex;
        gap: 1rem;
        justify-content: flex-end;
    }

    .rw-dropdownlist-search {
        display: block;
    }
}
