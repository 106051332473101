@font-face {
    font-family: 'Arial Nova';
    src: local('Arial Nova Regular'), local('Arial-Nova-Regular'),
        url('ArialNova.woff2') format('woff2'),
        url('ArialNova.woff') format('woff'),
        url('ArialNova.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'SegoeUI';
    src: url('SegoeUI.eot');
    src: url('SegoeUI.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI.woff2') format('woff2'), url('SegoeUI.woff') format('woff'),
        url('SegoeUI.ttf') format('truetype'),
        url('SegoeUI.svg#SegoeUI') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SegoeUI-Bold';
    src: url('SegoeUI-Bold.eot');
    src: url('SegoeUI-Bold.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI-Bold.woff2') format('woff2'),
        url('SegoeUI-Bold.woff') format('woff'),
        url('SegoeUI-Bold.ttf') format('truetype'),
        url('SegoeUI-Bold.svg#SegoeUI-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SegoeUI-Semibold';
    src: url('SegoeUI-Semibold.eot');
    src: url('SegoeUI-Semibold.eot?#iefix') format('embedded-opentype'),
        url('SegoeUI-Semibold.woff2') format('woff2'),
        url('SegoeUI-Semibold.woff') format('woff'),
        url('SegoeUI-Semibold.ttf') format('truetype'),
        url('SegoeUI-Semibold.svg#SegoeUI-Semibold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
