.expanded-text {
    max-width: 100%;
    min-height: 20px;

    .text-label {
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        display: -webkit-box;
        max-width: 400px;
    }

    .text-label.expanded {
        -webkit-line-clamp: inherit !important;
    }

    a {
        text-decoration: underline !important;
        color: #535bd6 !important;
    }
}
