.marketplace-buyers-tab {
    margin-top: 15px;

    .card-body {
        overflow: unset;
    }

    .header-title {
        color: black;
        font-family: 'SegoeUI-Bold';
        font-size: 16px;
    }

    .page-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .react-bootstrap-table {
        overflow: auto;
    }
    
    .react-bootstrap-table-pagination {
        flex-wrap: nowrap;
    }

    .buyer-name {
        width: 35% !important;
    }

    .link {
        color: #545cd8;
        text-decoration: underline;
        cursor: pointer;
    }

    .user-details-popup {
        .modal-title {
            color: #000;
            margin-bottom: 0;
        }
        .card-body {
            flex: 1 1 auto;
            padding: 1.25rem;
        }

        .table th,
        .table td {
            padding: 0.55rem;
            color: #525a64;
            font-size: 13px;
            font-family: 'SegoeUI-Semibold';
        }
    }

    .corporate-affiliation-name {
        width: 35% !important;
    }

    .active-engagements-count {
        width: 20% !important;
    }

    .actions {
        width: 10% !important;
    }
}
