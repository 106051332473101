.app-poc-page {
    .no-margin {
        padding: 0 !important;
        margin: 0 !important
    }

    .header-tools {
        @media (max-width: 380px) {
            .top-liked-posts {
                display: none;
            }
        }

        @media (max-width: 245px) {
            .poc-profile {
                display: none;
            }
        }
    }


    @media (max-width: 1800px) {

        .about-poc-title,
        h3 {
            padding-top: 0;
            font-size: 19px;
        }
    }

    @media (max-width: 1600px) {
        .page-title {
            margin-left: 5px;
        }

        .header-tools {
            margin-block: 0 !important;
        }

        .contact-support-button .reference-text {
            font-size: 14px;
        }

        .top-liked-posts {
            padding-block: 0 !important;
            text-transform: none !important;
        }

        .page-header {
            padding-bottom: 0.25em;
        }

        .breadcrumb {
            margin-bottom: 0 !important;
        }


        .about-poc-title {
            font-size: 16px !important;
        }

        .div,
        h3 {
            font-size: 18px;
            margin-bottom: 0.15em;
        }

        .post-title {
            font-size: 12px !important;
        }
    }

    @media (max-width: 1400px) {

        .div,
        h3 {
            font-size: 16px;
            margin-bottom: 0.1em;
        }
    }

    @media (max-width: 1200px) {

        .about-poc-title,
        h3 {
            font-size: 16px;
        }
    }

    @media (max-width: 550px) {

        .about-poc-title,
        h3 {
            padding-top: 0;
        }

        .page-header {
            display: flex;
            flex-wrap: wrap;
        }

        .about-poc-title {
            margin-right: 0.5em;
        }

        .page-header .header-tools-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0;
            gap: 1em;
        }
    }

    .about-poc-title {
        color: #535bd6;
        text-transform: none !important;
        font-size: 20px;
        font-family: 'SegoeUI-Semibold';
        font-style: italic;
        display: flex;
        width: fit-content;

        & div {
            display: flex;
            width: fit-content;
            cursor: pointer;
        }
    }

    .about-poc-title-wrapper {
        display: flex;
        align-items: center;

        @media (max-width: 1490px) {
            max-width: 18%;
        }

        @media (max-width: 767px) {
            max-width: max-content;
            margin-bottom: 10px;
        }
    }

    .top-liked-posts {
        font-size: 14px;
        color: #535bd6 !important;
        text-decoration: underline !important;
        text-transform: uppercase;
        font-family: 'SegoeUI-Semibold';
        cursor: pointer;
        padding: 4px 10px 2px 10px;
        margin-right: 0.5em;
        align-self: center;
    }

    .hidden-element {
        display: none !important;
    }

    height: calc(100vh - 100px + calc(30px - var(--grid-gutter-width)));
    display: flex;
    flex-direction: column;
    overflow: auto;
    min-height: 400px;

    @media screen and (max-width: 992px),
    (max-height: 550px) {
        height: auto;
    }

    .flex-container {
        display: flex;
        flex-direction: column;
        overflow: auto;
        height: 100%;
    }

    .page-title {
        display: flex;
        justify-content: space-between;
    }

    .action-buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
            padding: 2px 10px 2px 10px;
            height: 29px;
        }
    }

    .error-message {
        text-align: center;
        color: #545cd8;
    }

    .loader {
        display: flex;
        align-items: center;
        height: 100%;
    }

    @media screen and (max-width: 992px),
    (max-height: 580px) {
        height: auto !important;
    }

    @media screen and (max-width: 992px),
    (max-height: 550px) {
        .flex-row {
            flex-direction: column !important;
        }

        height: auto !important;
        display: inherit !important;
        overflow: inherit !important;

        .chat-right-side {
            padding-right: 0 !important;
        }

        .app-inner-layout,
        .clubhouse-area {
            min-height: 400px;
            height: auto !important;
            display: inherit !important;
            overflow: inherit !important;
        }

        .clubhouse-container {
            height: auto;
        }

        .app-chat-area {
            min-height: 25vh;
        }

        .app-messages {
            min-height: 400px;
        }
    }
}