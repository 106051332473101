.edit-project-variable-popup {
    .rodal-dialog {
        top: 20%;

        .options-list {
            max-height: 400px;
            overflow-y: auto;
        }

        .options-list::-webkit-scrollbar {
            cursor: pointer;
            height: 6px;
            width: 4px;
            background-color: #e5e5e5;
            border-radius: 10px;
        }
        .options-list::-webkit-scrollbar-thumb {
            background-color: #a0a0a0 !important;
            height: 4px;
            width: 3px;
            border-radius: 5px;
        }
    }

    .btn-cancel {
        background-color: #fff;
        color: #919191;
        border-color: #919191;
    }

    .rodal h5.modal-title {
        color: black !important;
        margin-bottom: 0;
    }

    .project-variable-tile {
        font-family: 'SegoeUI-Semibold';
        margin-top: 8px;
        margin-bottom: 8px;
        font-size: 14px;
        color: #888f96;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        .project-variable-name {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .delete-button {
            padding: 0 0.5rem;
            cursor: pointer;
        }

        .project-variable-info {
            display: flex;
            gap: 0.5rem;
            max-width: 90%;
        }
    }

    .buttons-wrapper {
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
    }

    .multiselect {
        margin-top: 1rem;

        input {
            border: 0 !important;
            height: 20px !important;
        }

        .rw-multiselect-input {
            display: block;
            padding: 6px 12px;
        }

        .rw-multiselect-input::placeholder {
            color: #888f96;
        }

        .rw-multiselect-tag {
            width: fit-content;
            display: inline-table;
            color: #888f96;
            height: 20px;
            font-size: 13px;
            font-family: 'SegoeUI-Semibold' !important;
            border-radius: 4px;
            background-color: #e5e8eb;
            border: 1px solid #e5e8eb;
            margin-left: 5px;
            margin-bottom: 0;
            margin-top: 0;
            min-height: 0;
        }

        .rw-multiselect-taglist {
            align-items: center !important;
        }

        .rw-select {
            vertical-align: middle;
        }

        .rw-multiselect {
            cursor: default;
        }

        .rw-multiselect .rw-widget-input.rw-widget-picker.rw-widget-container {
            height: 35px !important;
        }

        .rw-multiselect-tag-btn {
            padding: 0;
            margin-right: 5px;
            vertical-align: top;
        }

        .rw-multiselect-tag-label {
            display: table-cell;
            vertical-align: middle;
            height: 100%;
        }
    }
}
