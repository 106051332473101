.content-view-modal {
    top: 200px !important;

    .modal-title {
        margin-bottom: 0;
        color: black;
    }

    .rodal .rodal-dialog {
        width: 500px !important;
    }

    .body_white_box {
        margin-bottom: 0;
    }

    .rodal-dialog {
        width: 500px !important;
    }

    .edit-title-dec-wrapper label {
        color: #525a64 !important;
        font-size: 13px !important;
        font-family: 'SegoeUI-Semibold' !important;
        margin-bottom: 0;
    }

    .edit-title-image-div {
        display: flex;
        justify-content: space-between;
    }

    .edit-tags {
        max-width: 390px;
    }

    .edit-tags .badge {
        background: #797c85 !important;
        border: none !important;
        font-size: 11px !important;
        font-family: 'SegoeUI';
        padding: 4.5px 7.89px 4.5px 7.8px !important;
        font-weight: 400;
        margin-right: 5px;
        margin-bottom: 5px;
        color: #fff;
    }

    .edit-image-icon {
        display: flex;

        label {
            margin-bottom: 0;
            align-content: center;
        }

        svg:nth-child(2),
        svg:nth-child(3) {
            margin-left: 15px;
        }

        img:nth-child(2),
        img:nth-child(3) {
            margin-left: 15px;
            vertical-align: -2px;
            filter: invert(52%) sepia(3%) saturate(1423%) hue-rotate(167deg) brightness(85%) contrast(89%);
        }
    }

    .edit-title-intro-text-wrapper {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 38vh;

        &::-webkit-scrollbar {
            width: 7px;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #888f96;
        }

        @media only screen and (max-height: 900px) {
            max-height: 30vh;
        }

        @media only screen and (max-width: 1200px) {
            max-height: 30vh;
        }
    }

    .edit-title-intro-text-wrapper p {
        width: 99%;
        height: 40%;
        max-height: 38vh;

        @media screen and (max-height: 850px) {
            height: min-content;
            max-height: 20vh;
        }
    }

    .edit-title-dec-wrapper p {
        color: #888f96;
        font-size: 13px !important;
        font-family: 'SegoeUI-Semibold' !important;
        margin: 0;
        padding: 4px 0 11px 0;
    }

    .edit-title-dec-wrapper {
        padding-top: 10px;
    }

    .file-icon {
        color: #545cd8;
        height: 25px;
        width: 20px;
    }

    .file-info-wrapper {
        display: flex;
        padding-top: 4px !important;
    }

    .file-name {
        margin-left: 12px !important;
        margin-top: 4px !important;
    }

    p {
        padding-top: 4px !important;
        margin-top: 0 !important;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    h5 {
        width: 440px;
    }

    .modal-header {
        height: fit-content !important;
        padding: 19px 0 19px 23px !important;
    }

    .save-btn {
        background: #535bd6 !important;
        border-color: #535bd6 !important;
        color: #fff;
        height: 29px;
        padding: 2px 5px 3px 5px;
    }

    .purchase-btn-container {
        float: right;
        padding-bottom: 1em;
    }
}