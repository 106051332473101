.issue-refund-modal-rodal {
    .invalid-feedback {
        display: none !important;
    }

    .form-control:focus {
        box-shadow: none !important;
    }

    .div-item {
        display: flex;
        flex-direction: row;
        margin-bottom: 5px;
        .div-label {
            font-family: 'SegoeUI';
            font-size: 14px;
            color: #525a64;
            margin-right: 5px;
        }
        .div-value {
            font-family: 'SegoeUI';
            font-size: 14px;
            color: #888f96;
        }
    }
    .div-field-item {
        display: flex;
        flex-direction: column;
        margin-bottom: 5px;
        .div-field-label {
            font-family: 'SegoeUI-Semibold';
            font-size: 14px;
            color: #000000;
            margin-bottom: 8px;
        }
        .div-field-value {
            font-family: 'SegoeUI';
            font-size: 14px;
            color: #888f96 !important;
            input {
                color: #888f96 !important;
                font-size: 14px;
            }
            textarea {
                color: #888f96 !important;
                font-size: 14px;
            }
        }
    }

    .close-button {
        border: 1px solid;
        border-color: gray;
        background-color: white;
        color: gray;
    }

    .close-button:hover {
        background-color: gray;
        color: white;
    }

    .mt8 {
        margin-top: 8px;
    }
}
