.numeric-cell-input {
    .form-group {
        margin-bottom: 0;
    }

    input {
        width: 100%;
        height: 100%;
        border: none !important;
        padding: 0;
        background: transparent !important;
        font-family: 'SegoeUI-Semibold';
        color: #888f96;
        font-size: 13px;
        border-radius: 0 !important;
    }

    input:focus {
        outline: none;
    }

    .form-control:focus,
    .form-select:focus {
        color: #888f96;
    }
}
