.documents-upload-popup {
    .modal-body {
        gap: 1rem;
        display: flex;
        flex-direction: column;
    }

    .modal-title {
        color: black;
    }

    .section-title {
        font-family: 'SegoeUI-Bold';
    }

    .link-text {
        text-decoration: underline;
        color: #545cd8;
        width: fit-content;
        cursor: pointer;
    }

    .new-documents-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .buttons-wrapper {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
    }

    .btn-cancel {
        background-color: #fff;
        color: #919191;
        border-color: #919191;
    }

    th {
        cursor: pointer !important;
    }

    .uploads {
        min-width: 50%;
    }

    .document-uploads-table {
        .react-bootstrap-table {
            max-height: 250px;
            overflow: auto;
            border: 1px solid #dce0e4;

            table {
                margin-bottom: 0;
            }

            thead {
                position: sticky;
                top: 0;
                background-color: #dce0e4;
                box-shadow: 0px 2px 1px #e3e3e3;
            }

            &::-webkit-scrollbar {
                width: 6px;
                border-radius: 5px;
                background-color: #c1c1c157;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #a0a0a0;
                border-radius: 5px;
            }
        }
    }
}
