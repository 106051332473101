.upgrade-subscription-modal {
  input[type='radio'] {
    left: 20px !important;
    width: 19px;
    height: 19px;
    position: inherit !important;

    &::after {
      display: none !important;
    }
  }

  .btn-cancel {
    background-color: #fff;
    border-color: #919191;
    color: #919191;
  }

  .subscription-types {
    padding-inline: 2rem;
    gap: 2em;
    font-size: larger;
    display: flex;

    .radio-label {
      margin-left: 0.3em;
    }
  }

  .checkbox-label {
    padding-inline: 3.3rem;
  }

  .p-4 {
    padding-bottom: 0 !important;
  }

}

.upgrade-payment-modal {
  .action-buttons {
    display: flex;
    justify-content: right;
    flex-wrap: wrap;
    text-align: right;
    padding-inline: 1em;
    gap: 1em;
  }

  button {
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
    border-top-right-radius: 3px !important;
    border-bottom-right-radius: 3px !important;
  }

  .default-image {
    max-width: 120px;
    margin: 3px 4px 5px 0px;
    padding-bottom: 1em;
    padding-right: 1em;
  }

  .cancel-btn {
    width: 90px;
  }
}