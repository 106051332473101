.note-popup {
    .rodal-dialog {
        top: 15% !important;
    }
}

.group-note-rodal {
    height: 400 !important;
    background: #0000008c !important;
}
.group-note {
    .modal-header {
        padding-bottom: 0.5rem;
        padding-top: 0.5rem;

        h5.modal-title {
            font-size: 18px;
            font-family: 'SegoeUI-SemiBold';
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-transform: capitalize !important;
            color: #000;
            text-align: left;
        }
    }

    .modal-body {
        textarea {
            height: 250px;
            max-height: 400px;

            width: 100%;
            resize: vertical;
            border-radius: 0.25rem;
            outline: 0;
            font-size: 13px !important;
            color: #888f96 !important;
            font-family: 'SegoeUI-Semibold', sans-serif !important;
            min-height: 60px;
            box-shadow: none !important;
        }
    }

    .modal-footer {
        background: transparent;
        margin: 0 auto;
        border-top: none !important;
        padding: 0 1rem 1rem 1rem;
        width: 100%;

        .discard-button {
            color: #919191;
            height: 29px;
            border: 1px solid #919191;
            background-color: transparent;
            max-width: 120px;
            padding: 2px 5px 3px 5px;
            width: 100% !important;
            margin-right: 0 !important;
            font-size: 13px;
        }

        .save-btn {
            margin-left: 10px;
            font-size: 13px;
            font-family: 'SegoeUI-Semibold';
            height: 29px;
            width: 55px;
            background-color: #545cd8;
            border-color: #545cd8;
            padding: 2px 10px 3px 10px;
        }
    }
}
