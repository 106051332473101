.gift-card {
    max-width: 585px;
    min-height: 220px;
    min-width: 300px;
    width: 100%;
    background: rgb(175, 209, 159);
    background: linear-gradient(0deg, rgba(124, 174, 88, 1) 0%, rgba(175, 209, 159, 1) 90%);
    box-shadow: -2px -1px 8px 3px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: -2px -1px 8px 3px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: -2px -1px 8px 3px rgba(0, 0, 0, 0.4);
    position: relative;
    color: black;
    p {
        color: black;
    }

    @media (min-width: 1220px) {
        width: 585px !important;
    }

    .dollar-icon {
        height: 2em;
        position: absolute;
    }

    .top-left-dollar-icon {
        @extend .dollar-icon;
        top: 1em;
        left: 1em;
    }

    .bottom-left-dollar-icon {
        @extend .dollar-icon;
        bottom: 1em;
        left: 1em;
    }

    .top-right-dollar-icon {
        @extend .dollar-icon;
        top: 1em;
        right: 1em;
    }

    .bottom-right-dollar-icon {
        @extend .dollar-icon;
        bottom: 1em;
        right: 1em;
    }

    .gift-card-content-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0.5em;
    }

    .gift-card-row-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .gift-card-header {
        text-align: center;
    }

    .gift-card-details-container {
        display: flex;
        justify-content: space-evenly;
        margin-inline: 2em;
    }

    p {
        font-size: 14px;
        margin-bottom: 0.5em;

    }

    h2 {
        font-size: 22px;
    }

    .footnote {
        font-size: 12px;
        font-style: italic;
        margin-top: 1em;
    }

    img {
        height: 100px;
    }

}

.gift-card-loader {
    @extend .gift-card;
    height: auto !important;
    padding-top: 2em;
}