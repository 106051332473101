.user-documents-table {
    .manual-uploader-section {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 1rem;
    }

    .action-buttons-wrapper {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        .action-button {
            cursor: pointer;
        }
    }

    .document-removal-modal {
        .rodal-dialog {
            left: 0%;
        }
    }
}
