.create-timeline-popup {
    .copy-name-label {
        font-family: 'SegoeUI-Semibold';
        font-size: 14px;
    }

    .modal-title {
        margin-bottom: 0;
        color: #000;
    }

    .loader-container {
        width: 100%;
    }

    .form-group {
        margin-bottom: 0;
    }

    .modal-copy-buttons {
        padding-top: 20px;
    }

    .btn-cancel {
        height: auto;
        width: auto;
        background-color: #fff;
        color: #919191;
        border-color: #919191;
        margin-right: 5px;
    }
    .form-group {
        margin-bottom: 10px;
    }

    form label,
    input,
    .react-datepicker__input-container .btn {
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
        color: #525a64;
    }

    .react-datepicker__header--time {
        padding-bottom: 10px;
    }

    .react-datepicker-time__input {
        border: none;
    }

    .react-datepicker {
        display: flex !important;
    }

    .react-datepicker__navigation--next--with-time:not(
            .react-datepicker__navigation--next--with-today-button
        ) {
        right: 130px;
    }

    .small-border {
        border: 1px solid #ced4da;
        border-radius: 5px;
        height: 30px;
        align-items: center;
    }

    .arrow-down {
        height: 11px;
    }

    .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
        right: 5px !important;
        top: 45%;
    }

    select,
    input {
        color: #888f96;
    }

    .MuiOutlinedInput-input::placeholder {
        color: inherit;
        opacity: 1;
    }

    select {
        padding-left: 8px !important;
    }

    select[aria-invalid='true'] {
        appearance: none;
        padding-left: 14px !important;
    }

    .react-datepicker__input-container .btn {
        color: #888f96;
    }

    @media screen {
        top: 10%;
    }
}
