.member-access-popup {
    .rodal-dialog {
        top: 15%;
    }
    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .member-rows-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .member-row {
        display: flex;
        gap: 0.5rem;
        cursor: pointer;
    }

    .buttons-wrapper {
        display: flex;
        gap: 1rem;
        justify-content: flex-end;
    }

    .btn-secondary {
        background-color: #fff;
        color: #919191;
        border-color: #919191;
    }
}
