.my-engagement {
    .sampledetail h6 {
        color: #000000;
        font-size: 14px;
        margin: 0;
        line-height: 23px;
    }
    .sampledetail h6 span {
        font-size: 14px;
        font-family: 'SegoeUI';
        font-weight: 400;
        color: #000000;
    }
}
