.gift-card-request-modal {
    h5 {
        font-size: 18px !important;
    }

    .no-display {
        display: none;
    }

    .center-container {
        display: flex;
        justify-content: center;
        padding: 0.5em;
    }

    .rodal-dialog {
        max-width: 720px !important;
        top: 15%;

        .form-row,
        .row {
            justify-content: start;
            align-items: center;

            .bold {
                font-weight: bold;
            }

            .section {
                margin: 1em 0;
            }

            .subsection {
                margin-left: 15px;

                label {
                    font-size: 14px;
                    font-weight: normal;
                }
            }

            .error-message-div {
                color: red;
                font-size: 13px;
                font-weight: 600;
                margin-bottom: 10px;
                margin-left: 15px;
            }

            .form-group {
                margin-bottom: 0.25rem;

                input {
                    font-size: 14px;
                }
            }
        }

        .no-bottom-margin {
            margin-bottom: 0 !important;
            /*.gift-card-request-modal-body .row {
                padding: 1rem 0;
                justify-content: center;
            }*/

            @media only screen and (max-width: 767px) {
                top: 25% !important;
            }
        }

        .no-top-margin {
            margin-top: 0 !important;
        }

        button {
            white-space: nowrap;
            border: 0;
            display: inline-block;
            height: 30px;
            line-height: 28px;
            padding: 0 10px;
            color: #fff;
            border-radius: 4px;
            font-size: 15px;
            text-decoration: none;
            text-transform: capitalize;
        }

        .find-button,
        .cancel-choise-button {
            margin-top: 5px;
            margin-left: 10px;
        }

        .confirm-button {
            margin-left: 10px;

            @media only screen and (max-width: 767px) {
                margin-left: 5px;
            }
        }

        .close-button {
            border: 1px solid;
            border-color: gray;
            background-color: white;
            color: gray;
        }

        .gift-card-request-modal-body {
            padding: 0 1rem;
        }

        .modal-footer {
            padding: 1rem;
            border: none;
            background-color: inherit;
        }

        @media (max-width: 575px) {
            .modal-footer {
                justify-content: flex-end !important;
            }
        }

        .modal-body-footer {
            font-size: 14px;
            margin-bottom: 1rem;
        }

        .popup-header {
            color: black;
            font-weight: normal;
        }

        .have-additional-input {
            display: flex;

            .form-group {
                margin: 0;
            }

            input {
                margin-left: 1rem;
                margin-top: -0.25rem;
                width: 50%;
            }
        }
    }

    .requesting-sub-code-holder-dropdown {
        select {
            width: max-content;
        }

        label {
            font-size: 14px;
            font-weight: normal;
            font-family: 'Segoe UI';
        }
    }

    .message-note-header {
        font-size: 14px;
    }
}
