.onboard-new-client {
    .rodal-dialog {
        top: 15%;
    }

    .field-label {
        font-size: 14px;
        font-family: 'SegoeUI-Semibold';
    }

    .ml-56 {
        margin-left: 56px;
    }

    .multiselect {
        margin-top: 1rem;

        input {
            border: 0 !important;
            height: 20px !important;
        }

        .rw-multiselect-input {
            display: block;
            padding: 6px 12px;
        }

        .rw-multiselect-input::placeholder {
            color: #888f96;
        }

        .rw-multiselect-tag {
            width: fit-content;
            display: inline-table;
            color: #888f96;
            height: 20px;
            font-size: 13px;
            font-family: 'SegoeUI-Semibold' !important;
            border-radius: 4px;
            background-color: #e5e8eb;
            border: 1px solid #e5e8eb;
            margin-left: 5px;
            margin-bottom: 2px;
            margin-top: 2px;
            min-height: 0;
        }

        .rw-multiselect-taglist {
            align-items: center !important;
        }

        .rw-select {
            vertical-align: middle;
        }

        .rw-multiselect {
            cursor: default;
        }

        .rw-multiselect .rw-widget-input.rw-widget-picker.rw-widget-container {
            height: 100% !important;
            overflow-x: auto;
        }

        .rw-multiselect-tag-btn {
            padding: 0;
            margin-right: 5px;
            vertical-align: top;
        }

        .rw-multiselect-tag-label {
            display: inline-table;
            vertical-align: middle;
            height: 100%;
        }
    }

    .fields-block-label {
        font-size: 14px;
        font-family: 'SegoeUI-Bold';
    }

    .field-switcher {
        min-height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;

        .field-label {
            margin-left: 10px;
        }
    }

    .button-container {
        text-align: end;
    }

    .rw-dropdownlist-search {
        display: block !important;
    }

    .row {
        margin-bottom: 20px;
    }

    .questionnaire-section-input {
        > :not(:last-child) {
            margin-bottom: 10px;
        }
    }
}

@media (max-width: 770px) {
    .onboard-new-client {
        .field-label {
            margin-top: 10px;
        }

        .fields-block-label {
            margin-top: 20px;
        }

        .row {
            display: flex;
            flex-direction: column;
            margin-bottom: 0 !important;
        }

        .field-switcher {
            margin-top: 10px;
            margin-bottom: 10px;
            .field-label {
                margin-top: 0px;
            }
        }

        .button-container {
            margin-top: 20px;
        }
    }
}
