.cell-text-input {
    textarea {
        width: 100%;
        height: 100%;
        border: none;
        color: #888f96;
        padding: 0 16px;
        overflow: auto !important;
        resize: none;

        &::-webkit-scrollbar {
            cursor: default;
            height: 6px;
            width: 6px;
            background-color: #e5e5e5;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            cursor: default;
            background-color: #a0a0a0 !important;
            height: 6px;
            width: 6px;
            border-radius: 5px;
        }
    }

    textarea:focus {
        outline: none;
    }
}
