.direct-rooms-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    
    h5 {
        margin-block: 0.5em;
    }

    .widget-heading {
        opacity: 1 !important;
        display: flex;
        align-items: center;
        min-width: 300px;
    }

    .room-info {
        display: flex;
        padding-left: 5px;

        span {
            font-size: 12px;
            font-weight: 400;
        }

        :not(:first-child) {
            margin-left: 3px;
        }
    }

    .full-width {
        width: 100%;
    }

    .sort-buttons {
        width: 100%;

        button {
            font-size: 12px;
            text-transform: uppercase;
            padding: 0.1rem 0.5rem;
            height: 30px;
            color: #888f96;
            border: 1px solid #dce0e4;
            background-color: white;
            font-family: 'SegoeUI-Semibold';
        }

        .btn-primary {
            color: white;
        }
    }

    .new-messages-badge {
        height: 20px !important;
        width: auto !important;
        border-radius: 20px;
        margin-left: 0 !important;
    }

    .active-new-messages-badge {
        @extend .new-messages-badge;
        background-color: white;
        color: red;
    }

    .inactive-new-messages-badge {
        @extend .new-messages-badge;
        background-color: red;
        color: white;
    }

    .badge-wrapper {
        margin-left: 5px;
        width: 2.5em;
    }

    .poster-member-icon {
        height: 25px;
        width: 25px;
        margin-right: 8px;
    }

    .avatar-icon-wrapper {
        .badge {
            height: 10px !important;
            width: 10px !important;
            border: #fff solid 1px !important;
        }
    }

    .table-avatar-icon {
        .badge {
            right: 5px !important;
            bottom: -2px !important;
        }
    }

    .nav-item { 
    	width: 100%
    }
 
    .room-name-wrapper {
        display: flex;
        justify-content: space-between;
        width: 77%
    }

    .room-name {
        margin-left: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .initials {
        .badge {
            right: -3px !important;
            bottom: -2px !important;
        }
    }

    .table-avatar-icon {
        margin-right: 8px;
    }

    .search-box {
        position: relative;
        width: 100%;
        margin-top: 0.5em;
    }

    .search-box img {
        position: absolute;
        right: 11px;
        top: 6px;
    }

    img {
        vertical-align: middle;
        border-style: none;
    }

    .search-box input {
        width: 100%;
        padding: 2px 15px 3px 12px;
        height: 30px !important;
        border: 1px solid #dce0e4 !important;
        outline: 0;
        color: #888f96 !important;
        font-size: 14px !important;
        font-family: 'SegoeUI-Semibold';
        border-radius: 0.25rem;
        font-weight: 400 !important;
    }

    .add-room {
        font-size: 14px;
        color: #535bd6 !important;
        text-decoration: underline;
        font-family: 'SegoeUI-Semibold';
        cursor: pointer;
        margin-top: 0.5em;
    }

    .navbar-wrapper {
        display: block !important;
        overflow-y: auto;
    }

    .navbar-wrapper::-webkit-scrollbar {
        width: 6px;
        border-radius: 5px;
        background-color: #c1c1c157;
    }

    .navbar-wrapper::-webkit-scrollbar-thumb {
        background-color: #a0a0a0;
        border-radius: 5px;
    }

    .dropdown-item:active,
    button.not-active.dropdown-item {
        color: #ffffff !important;
        text-decoration: none;
        background-color: #535bd6 !important;
        opacity: 1;
        border-radius: 8px;
    }

    .blocked-chat {
        color: #adb5bd;
    }

    button.dropdown-item {
        padding: 11.82px 18px !important;
    }

    .dropdown-item:hover,
    .dropdown-item:focus {
        background-color: transparent;
    }

    @media (max-width: 800px) {
        height: 100%;
        display: flex;
        flex-direction: column;

        .navbar-wrapper {
            max-height: 100%;
            width: 100%;
        }

        .badge-wrapper {
            margin-left: auto;
        }
    }

    @media (max-width: 430px) {
        .room-info {
            flex-wrap: wrap;
        }
    }
}
