.checkout-form {
    font-size: 16px;

    .price-items-wrapper {
        width: 54%;
    }

    .price-item {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    .congratulations {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .order-information-body {
        margin-right: 0 !important;
        margin-left: 0 !important;
        margin-bottom: 10px;
        justify-content: space-between !important;
    }

    .discount-alert {
        background-color: #fdc700;
        color: black;
        font-family: 'SegoeUI-Bold';
        width: 100%;
        padding: 0.3em 0.7em;
        border-radius: 0.25em;
        margin-bottom: 1em;
    }

    @media (max-width: 715px) {
        .rebates-section {
            width: 100% !important;
            margin-right: 16px;
        }

        .price-items-wrapper {
            width: 95%;
        }
    }
}
