.data-table-stages-list {
    .invalid-stage {
        background-image: URL("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: center left 5px;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        transition: none !important;

        .data-table-name-wrapper {
            margin-left: 10px;
        }
    }

    .lock-icon-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .action-icons {
        font-size: 18px;
        cursor: pointer;
        display: flex;
        gap: 10px;
        justify-content: flex-start;

        .delete-button {
            color: #d32f2f;
        }
    }

    .data-table-stages-host-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 1rem;
    }

    .checkbox-field {
        text-align: center;
        .complete-checkbox {
            width: 100%;
            position: relative;
            cursor: pointer;
            margin: 0 !important;
        }
    }

    .data-table-name-wrapper {
        display: flex;
        gap: 1rem;

        .edit-icon {
            font-size: 16px;
            cursor: pointer;
        }
    }
    .react-bootstrap-table {
        max-height: 48ch;
        overflow: auto;
    }

    .react-bootstrap-table::-webkit-scrollbar {
        cursor: pointer;
        height: 6px;
        width: 4px;
        background-color: #e5e5e5;
        border-radius: 10px;
    }
    .react-bootstrap-table::-webkit-scrollbar-thumb {
        background-color: #a0a0a0 !important;
        height: 4px;
        width: 3px;
        border-radius: 5px;
    }

    .date-picker-container {
        position: relative;
        float: left;
        padding-bottom: 3px;
    }

    .date-input {
        height: 30px;
        width: 40px;
        visibility: hidden;
    }

    .open-icon {
        position: absolute;
        right: 15px;
        top: 1px;
        width: 25px;
        height: 25px;
        background: #fff;
        pointer-events: none;
    }
    .due-date-container {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }
    .link-button {
        color: #535bd6 !important;
        cursor: pointer;
        text-decoration: underline;
    }

    .table {
        thead {
            position: sticky;
            top: 0;
            background: white;
            z-index: 1;
        }
        th:first-of-type {
            width: auto !important;
        }
        .table-view-row {
            .data-table-name-wrapper {
                padding-left: 3.5rem;
            }
        }

        .table-view-row.invalid-stage {
            background-position: center left 45px !important;
        }

        th,
        td {
            border: none;
        }

        td,
        tbody th {
            padding: 12px 15px;
        }

        th {
            color: #000;
            font-size: 14px;
            padding: 15px;
            font-family: 'SegoeUI-Bold';
            cursor: pointer;
        }

        td {
            font-size: 14px;
            color: #000;
        }

        tbody {
            th {
                font-family: 'SegoeUI';
                font-weight: 400;
            }

            tr:nth-child(odd) {
                background-color: #f7f7f7;
            }
        }
    }

    @media (max-width: 767px) {
        .card-body {
            overflow: auto;
        }

        .table {
            width: 800px;
        }

        .table th:nth-child(1) {
            width: 6%;
        }
    }
}
