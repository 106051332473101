.membership-profile-modal {
    .rodal {
        z-index: 1100;
    }

    .name-container {
        display: flex;
        align-items: center;
    }

    .direct-button {
        margin-left: 0.5em;
    }

    .name-wrapper {
        display: flex;
        justify-content: center;
        padding-top: 7px;
    }

    .profile-view-pop-up-body .delete-image-div {
        text-align: center;
        padding-bottom: 0px;
    }

    .profile-view-pop-up-body .delete-image-div .poc-avatar-icon {
        display: flex;
        justify-content: center;
    }

    .profile-view-pop-up-body .delete-image-div .poc-avatar-icon img {
        width: 70px;
        height: 70px;
        border-radius: 100%;
    }

    .profile-view-pop-up-body
        .delete-image-div
        .poc-avatar-icon
        .avatar-member-icon {
        width: 70px;
        height: 70px;
        border-radius: 100%;
    }

    .profile-view-pop-up-body
        .delete-image-div
        .poc-avatar-icon
        .avatar-member-initials {
        font-family: 'SegoeUI-Semibold';
        font-size: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .profile-view-pop-up-body .delete-image-div h2 {
        font-size: 18px;
        color: #000;
        font-family: 'SegoeUI-Semibold';
        margin-bottom: 0;
        place-self: center;
    }

    .profile-view-pop-up-body .profile-view-wrapper {
        padding: 15px 15px 15px 15px !important;
        display: flex;
        justify-content: space-evenly !important;
        padding-bottom: 21px;
    }

    @media (max-width: 375px) {
        .profile-view-pop-up-body .profile-view-wrapper {
            flex-wrap: wrap;
        }

        .profile-view-pop-up-body .profile-view-wrapper .status-title.left {
            margin-bottom: 15px;
        }
    }

    .profile-view-pop-up-body .profile-view-wrapper .status-title {
        color: #525a64;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
    }

    .profile-view-pop-up-body .profile-view-wrapper .status-title.full {
        width: 100%;
    }

    .profile-view-pop-up-body .profile-view-wrapper .status-title.right {
        max-width: 140px;
        width: 100%;
    }

    .profile-view-pop-up-body .title {
        width: 50% !important;
        flex: 0 0 50% !important;
        max-width: 50% !important;
    }

    .profile-view-pop-up-body .profile-view-wrapper .input-title a {
        font-size: 13px !important;
        text-decoration: underline;
        color: #535bd6 !important;
        font-family: 'SegoeUI-Semibold' !important;
    }
}

.general-profile-modal {

    .rodal-dialog {
        top: 39% !important;
        width: 464px !important;
        left: 0% !important;
    }

    .profile-view-wrapper .status-title {
        font-size: 13px !important;
        font-family: 'SegoeUI-Semibold' !important;
        color: #888f96 !important;
    }

    .modal-body {
        padding-top: 33px !important;
    }

    .general-profile-header {
        display: none;
    }

    .rodal-close:hover:before,
    .rodal-close:hover:after {
        background: #000;
        opacity: 0.75;
    }

    .rodal-close:before,
    .rodal-close:after {
        background: #7f7f7f;
        opacity: 0.5;
    }

    .input-title {
        font-size: 13px !important;
        font-family: 'SegoeUI-Semibold' !important;
        color: #888f96;
    }

    .input-title.mb-space-5 {
        margin-bottom: 5px;
    }
}
