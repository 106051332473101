.direct-button {
    .icon {
        display: inline-block;
        border-radius: 60px;
        padding: 8px;
        height: 26px;
        color: white;
        background-color: #535bd6;
        vertical-align: middle !important;
    }

    .small-icon {
        @extend .icon;
        padding: 5px;
        height: 16px;
    }

    .medium-icon {
        @extend .icon;
        padding: 6px;
        height: 19px;
    }

    .toggle-button {
        padding: 0;
    }

    .unread-messages {
        position: absolute;
        height: 20px !important;
        width: auto !important;
        border-radius: 20px;
        margin-left: 0 !important;
        background-color: red;
        color: white;
        top: 2.6em;
        left: 2.2em;
    }
}