.date-label-wrapper {
    display: flex;
    justify-content: flex-end;
    width: fit-content;
    
    .editable-date-label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        width: fit-content;

        .date-picker-container {
            position: relative;
            float: left;
            padding-bottom: 0px !important;
        }

        .date-input {
            height: 30px;
            width: 40px;
            visibility: hidden;
        }

        .na-label {
            width: 3ch;
        }

        .input-group-prepend {
            margin-left: 0.5rem;
        }

        .react-datepicker__triangle {
            display: none;
        }

        .react-datepicker-wrapper {
            z-index: 0;

            .react-datepicker__input-container {
                input {
                    border: none;
                    width: 10ch;
                    padding: 0;
                    background: transparent;
                }

                input:focus-visible {
                    outline: none !important;
                }
            }
        }

        .calendar-icon {
            cursor: pointer;
        }

        .react-datepicker-popper {
            .react-datepicker__aria-live {
                display: none;
            }
        }
    }
}

.react-datepicker {
    .react-datepicker__aria-live {
        display: none;
    }
}
