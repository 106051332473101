.marketplace-template-preview {
}

.marketplace-template-preview-chart {
    height: calc(100vh - 100px + calc(30px - var(--grid-gutter-width)));
    display: flex;
    flex-direction: column;

    .gantt-chart-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: auto;
    }
}
