.project-editor {
    .RRT__tab {
        padding: 5px 20px 17px !important;
    }

    form {
        .form-group {
            margin: 0;
        }
    }

    .project-settings-section {
        display: flex;
        gap: 1em;
        align-items: center;
    }

    .questionnaire-stages-host-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .editor-header {
        display: flex;
        align-items: baseline;
        justify-content: space-between;

        .clickable {
            cursor: pointer;
            display: flex;
            gap: 0.25rem;
            align-items: center;
        }
    }

    .disabled {
        pointer-events: none;
        background-color: #e9ecef;
    }

    .error-tab {
        background-image: URL("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right top 5px !important;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        transition: none !important;
    }

    .Mui-error {
        background-image: URL("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 16px top 2px !important;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

        input {
            padding-right: 22px;
        }
    }

    .MuiDataGrid-cellContent.Mui-error {
        background-position: top 2px right 7px !important;
    }

    .MuiDataGrid-cellContent {
        min-height: 30px;
        min-width: 100%;
    }

    .header-row {
        align-items: baseline;
        gap: 1rem;
    }

    .card-body > *:not(:last-child) {
        margin-bottom: 1rem;
    }

    .buttons-wrapper {
        gap: 1rem;
        justify-content: flex-end;
    }

    .btn-cancel {
        background-color: #fff;
        color: #919191;
        border-color: #919191;
    }

    .name-editor-container {
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
        white-space: break-spaces;
    }

    .stage-navigation-breadcrumb {
        .link-text {
            color: #545cd8;
            cursor: pointer;
        }

        .node-separator {
            white-space: break-spaces;
        }
    }

    .header-row-item {
        display: flex;
        gap: 0.5rem;
        align-items: baseline;
    }
}
