.data-and-document-requests-dashboard {
    .card-header {
        color: #000;
    }

    .link {
        color: #545cd8;
        text-decoration: underline;
        cursor: pointer;
    }

    .action-buttons-wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
    }

    .data-requests-tables-container {
        .react-bootstrap-table {
            overflow: auto;
        }
        .react-bootstrap-table::-webkit-scrollbar {
            cursor: pointer;
            height: 6px;
            width: 4px;
            background-color: #e5e5e5;
            border-radius: 10px;
        }
        .react-bootstrap-table::-webkit-scrollbar-thumb {
            background-color: #a0a0a0 !important;
            height: 4px;
            width: 3px;
            border-radius: 5px;
        }

        .table {
            white-space: nowrap;
        }

        .table th,
        .table td {
            border: none;
        }

        .table tbody th {
            font-family: 'SegoeUI';
            font-weight: 400;
        }

        .table tbody tr:nth-child(odd) {
            background-color: #f7f7f7;
        }

        .table th {
            color: #000;
            font-size: 14px;
            padding: 15px;
            font-family: 'SegoeUI-Bold';
            cursor: pointer;
        }

        .table th:last-child {
            cursor: auto;
        }

        .table td {
            font-size: 14px;
            color: #000;
        }

        .table td,
        .table tbody th {
            padding: 12px 15px;
        }
    }

    @media (max-width: 767px) {
        .card-body {
            overflow: auto;
        }

        .data-requests-tables-container {
            .table {
                width: 800px;
            }

            .table th:nth-child(1) {
                width: 6%;
            }
        }
    }
}
