.data-request-project-name-editor {
    .project-name-editor,
    .project-name-view {
        display: flex;
        gap: 0.5rem;
        width: fit-content;

        svg {
            cursor: pointer;
        }
    }

    .project-name-editor {
        align-items: center;

        input {
            min-width: 300px;
        }
    }

    .project-name-view {
        align-items: flex-end;
    }
}
