.timeLine-main-header-container {
    .header-top {
        height: 30px;
        font-size: 12px;
        border-bottom: solid 1px #000;
        display: flex;
        align-items: center;
    }

    .header-middle {
        height: 30px;
        background-color: white;
        color: grey;
        font-size: 12px;
        border-bottom: solid 1px #000;
        display: flex;
        align-items: center;
    }
    .header-bottom {
        height: 30px;
        font-size: 12px;
        border-bottom: solid 1px #000;
        display: flex;
        align-items: center;
    }
}
