.custom-autocomplete {
    height: 30px;

    .MuiAutocomplete-inputRoot {
        height: 30px;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        font-size: inherit;
        font-family: inherit;
        color: #888f96;

        .MuiOutlinedInput-notchedOutline {
            border: 1px solid #ced4da !important;
        }
    }

    .Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: #545cd8 !important;
    }
}

.custom-autocomplete-is-invalid {
    border: 1px solid red !important;
    border-radius: 0.25rem;
    border-color: red;
    background-image: URL("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center right 5px;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    .MuiAutocomplete-endAdornment {
        display: none;
    }
}
