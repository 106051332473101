.gantt-chart::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #c1c1c157;
}

.gantt-chart::-webkit-scrollbar-thumb {
    background-color: #a0a0a0;
    border-radius: 5px;
}

.gantt-chart {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto !important;
    font-size: 12px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    overflow-x: auto;
    overflow-y: auto;

    /* Main Area */

    .timeLine-main {
        flex: 1 1 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        // overflow-y: hidden;
    }
    /* Main Area Header*/

    .timeLine-main-header-viewPort {
        flex: 0 0 90px;
        position: relative;
        height: 90px;
        width: 100%;
        background-color: #fff;
        position: sticky;
        top: 0;
        z-index: 10;
    }

    .timeLine-main-header-container {
        flex: 0 0 60px;
        position: relative;
        top: 0;
        left: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: #333333;
        user-select: none;
    }

    .timeLine-main-header-day-item {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: rgb(112, 112, 112);
        font-size: 10px;
        text-align: center;
        border-right: solid 1px;
        border-top: solid 1px;
        border-bottom: solid 1px;
        top: 20px;
        height: 40px;
        color: white;
        text-align: center;
    }

    .timeLine-main-header-top-item {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-right: solid 1px white;
        height: 20px;
        z-index: 91;
    }

    .timeLine-main-header-day-week {
        flex: 0 0 12px;
        padding: 4px;
        z-index: 90;
    }

    .timeLine-main-header-day-month {
        top: 0px;
        position: sticky;
        flex: 0 0 15px;
        padding: 5px;
        z-index: 90;
    }

    .timeLine-main-header-time {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        height: 22px;
        justify-content: stretch;
    }

    .timeLine-main-header-time-item {
        border-left: solid 1px silver;
        border-bottom: solid 1px silver;
        border-top: solid 1px silver;
        text-align: center;
        padding-top: 5px;
    }
    /* Main Area Data*/

    .timeLine-main-data-viewPort {
        flex: 1 1 auto;
        position: relative;
        background-color: #fff;
    }
    .timeLine-main-data-container {
        position: relative;
        top: 0;
        left: 0;
        height: 100%;
        background-color: rgb(255, 255, 255);
    }

    .timeLine-main-data-row {
        position: absolute;
        width: 100%;
        height: 50px;
    }

    .timeLine-main-data-task {
        position: absolute;
        background-color: darkorchid;
        border-radius: 14px;
        color: white;
        text-align: center;
    }

    .timeLine-main-data-task-side {
        position: absolute;
        width: 10px;
        cursor: col-resize;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .timeLine-main-data-task-side-linker {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        cursor: default;
        z-index: 100;
    }
    .timeLine-main-data-task-side-linker:hover {
        background-color: black;
        border: solid 0.5px grey;
    }
    /* .timeLine-main-data-task:hover {
    background-color:chocolate;
    border:solid 2px darkorchid;
    cursor: move;
} */

    .timeLine-main-data-verticalLine {
        flex: 1 1 auto;
        height: 100%;
        width: 24px;
        background-color: white;
        border-left-width: 0.5px;
        border-left-color: rgb(207, 207, 205);
        border-left-style: dashed;
    }

    /* Side Area */

    .timeLine-side-main {
        flex: 0 0 auto;
        /* width: 108px;*/
        min-width: 347px;
        display: flex;
        flex-direction: row;
    }

    .timeLine-side {
        flex: 1 0 100px;
        display: flex;
        flex-direction: column;
        border-right: solid 1px rgb(207, 207, 205);
    }

    .verticalResizer {
        flex: 0 0 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: col-resize;
        border-right: solid 1px rgb(207, 207, 205);
        height: 100%;
    }
    .squareGrip {
        flex: 0 0 auto;

        border-radius: 50%;
        height: 5px;
        width: 5px;
        margin: 3px 0;
    }

    .timeLine-side-title {
        flex: 0 0 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .timeLine-side-task-viewPort {
        position: relative;
        flex: 1 1 auto;
        height: 100%;
        background-color: #fbf9f9;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .timeLine-side-task-container {
        position: relative;
        overflow-x: hidden;
        overflow-y: hidden;
    }

    .timeLine-side-task-row {
        position: absolute;
        width: 100%;
        background-color: rgb(112, 112, 112);
        border-bottom-width: 0.5px;
        border-bottom-color: rgb(207, 207, 205);
        border-bottom-style: solid;
        height: 30px;
        color: grey;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .link-text {
        color: #545cd8;
        cursor: pointer;
    }
}
