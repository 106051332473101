.create-data-table-popup {
    .rodal-dialog {
        top: 20%;
        max-width: 400px;
    }

    .rw-multiselect-tag-label {
        max-width: 90%;
        display: inline-table;
        vertical-align: middle;
        height: 100%;
    }

    .rw-multiselect-tag-btn {
        float: right;
        display: flex;
        padding: 0.25em 0.35em 0em 0em;
    }

    .data-table-section-input {
        max-width: auto;

        .rw-multiselect-input {
            display: block;
            padding: 6px 12px;
            height: 20px;
        }

        .rw-multiselect .rw-widget-input.rw-widget-picker.rw-widget-container {
            height: 100% !important;
            overflow-x: auto;
        }

        .rw-multiselect-taglist {
            align-items: center !important;
        }
        .rw-multiselect-tag {
            width: fit-content;
            display: inline-table;
            color: #888f96;
            height: 20px;
            font-size: 13px;
            font-family: 'SegoeUI-Semibold' !important;
            border-radius: 4px;
            background-color: #e5e8eb;
            border: 1px solid #e5e8eb;
            margin-left: 5px;
            margin-bottom: 2px;
            margin-top: 2px;
            min-height: 0;
        }
    }

    label {
        font-size: 14px;
        font-family: 'SegoeUI-Semibold';
        min-height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .btn-cancel {
        background-color: #fff;
        border-color: #919191;
        color: #919191;
    }

    .buttons-wrapper {
        display: flex;
        gap: 1rem;
        justify-content: flex-end;
    }
}
