.donor-information-modal {
    .rodal-dialog {
        top: 25%;

        .donor-name-direct {
            display: flex;
            align-items: baseline;

            .direct-button {
                margin-left: 0.5em;
            }
        }

        h6 {
            color: #525a64;
            font-size: 13px;
            font-family: 'SegoeUI-Semibold';
        }

        p {
            color: #888f96;
            font-size: 13px;
            font-family: 'SegoeUI-Semibold';
        }
    }
}