.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.disabledSteps {
    pointer-events: none;
    opacity: 0.6;
}

.Toastify__progress-bar {
    bottom: 9%;
    left: 2%;
    opacity: 0.7 !important;
    width: 96%;
}

@media only screen and (min-width: 1700px) {
    .numInfoClass {
        width: 94%;
    }
}

@media only screen and (max-width: 1700px) {
    .numInfoClass {
        width: 89%;
    }
}

.numInfoClassdrp {
    width: 70%;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.test {
    /* font-family: 'Times New Roman';
  font-weight: 500; */
}

.section1,
.section2,
.section3,
.section4 {
    font-size: 1rem;
    font-weight: 400;
}

.drpInfo {
    width: 91%;
}

.slide2-row {
    margin-bottom: 10px;
}

/* 
.wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 40px;
  border-radius: 10px;
  box-shadow: 0px 1px 2px #555;
  background-color: #ffffff;
}

form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

h1 {
  text-align: center;
  width: 100%;
  color: #111;
  font-weight: lighter;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

label {
  font-size: 1rem;
  margin-bottom: 0.25em;
  color: #222;
  width: 10%;
  font-weight: 600;
}

input {
  padding: 10px 10px;
  border-radius: 5px;
  margin-right: 3rem;
  width: 100%;
  outline: none;
  border: 1px solid #cfcfcf;
}

input::placeholder {
  font-size: 1.2em;
  font-weight: lighter;
  color: #999;
}

input.error {
  border: 1px solid red;
}

.header{
margin-top: 1rem;
font-weight: 700;
}

.errorMessage {
  color: red;
  padding-top: 10px;
  font-size: 1rem;
  display: relative;
}

.isRange {
  width: 100%;
  display: inline-block;
  padding-bottom: 18px;
}
.css-1pcexqc-container{
  width: 100% !important;
}

.css-ssi0ig-container{
  width: 100% !important;
}
.createAccount {
     width: 100%;
    float: right;
    margin-bottom: 1rem;
    position: relative;
}

input[type="checkbox"]{
  box-sizing: border-box;
  padding: 0px;
  width: 3%;
  position: relative;
  top: 4px;
  height: 1rem;
}

.createAccount button {
  background-color: #519e8a;
  color: #fff;
  border: 2px solid #fff;
  width: 10%;
  height: 73%;
  margin-top: 1em;
  float: right;
  position: relative;
  font-weight: 500;
  padding: 8px 0px;
  font-size: 1em;
  font-weight: lighter;
  letter-spacing: 1px;
  margin-bottom: 0.25em;
}

.createAccount button:disabled {
  background-color: #cdd6d4;
  color: rgb(0, 0, 0);
  border: 2px solid #fff;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 0.25em;
}
/* 
.createAccount button:hover {
  color: #519e8a;
  background-color: #fff;
  border: 2px solid #519e8a;
} */

/* .createAccount button:active {
  background-color: #3e8e41;
}

.createAccount small {
  color: #999;
  font-weight: lighter;
} */

.expand-cell {
    cursor: pointer;
}

.expand-cell-header {
    cursor: pointer;
}
