.modal-dialog.posterDirectoryTablePopUp {
    min-width: 800px;
    min-height: 200px;
    max-height: 475px;
}

.modal-dialog.posterDirectoryTablePopUp .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
    display: none;
}

.modal-dialog.posterDirectoryTablePopUp .direct-button .btn {
    position: inherit !important;
}

.modal-dialog.posterDirectoryTablePopUp td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignRight {
    font-size: 13px;
    color: #525a64;
}

.modal-dialog.posterDirectoryTablePopUp .editTitleBody.deleteModalBody.profileViewPopUpBody.modal-body {
    padding: 21px 30px 28px 30px !important;
}

.modal-dialog.posterDirectoryTablePopUp tbody.MuiTableBody-root a {
    font-size: 13px !important;
    text-decoration: underline;
    color: #535bd6 !important;
    font-family: 'SegoeUI-Semibold' !important;
    cursor: pointer;
}

.modal-dialog.posterDirectoryTablePopUp td {
    font-size: 13px !important;
}

.modal-dialog.posterDirectoryTablePopUp .MuiTableSortLabel-root {
    cursor: pointer;
    display: inline-block !important;
}

.modal-dialog.posterDirectoryTablePopUp .MuiTableCell-alignRight {
    text-align: left !important;
    flex-direction: row-reverse;
}

.modal-dialog.posterDirectoryTablePopUp .MuiPaper-elevation1 {
    box-shadow: none !important;
}

.modal-dialog.posterDirectoryTablePopUp th:nth-child(1) {
    width: 27%;
    padding: 5px 0px 0px 14px !important;
    display: revert;
}

.modal-dialog.posterDirectoryTablePopUp th:nth-child(1) span.MuiButtonBase-root.MuiTableSortLabel-root {
    padding-left: 15px !important;
}

.modal-dialog.posterDirectoryTablePopUp th:nth-child(2) {
    width: 25%;
}

.modal-dialog.posterDirectoryTablePopUp th:nth-child(3) {
    width: 25%;
}

.modal-dialog.posterDirectoryTablePopUp th:nth-child(4) {
    width: 17%;
}

.modal-dialog.posterDirectoryTablePopUp th:nth-child(5) {
    width: 6%;
}

.modal-dialog.posterDirectoryTablePopUp th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignRight {
    border-bottom: 0px;
    border-top: 0px;
    padding: 5px 0 !important;
    vertical-align: middle;
}

.modal-dialog.posterDirectoryTablePopUp tr.MuiTableRow-root.MuiTableRow-head {
    background: #dce0e4 !important;
    height: 50px;
}

.modal-dialog.posterDirectoryTablePopUp td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignRight,
.modal-dialog.posterDirectoryTablePopUp td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter {
    color: #888f96;
    padding: 5px 0px 5px 0px !important;
    font-size: 13px !important;
    font-family: 'SegoeUI-Semibold' !important;
    height: 31px;
    vertical-align: middle;
}

.modal-dialog.posterDirectoryTablePopUp span.MuiButtonBase-root.MuiTableSortLabel-root {
    color: #525a64 !important;
    font-size: 13px !important;
    font-family: 'SegoeUI-Semibold' !important;
}

.modal-dialog.posterDirectoryTablePopUp tr.MuiTableRow-root.MuiTableRow-hover {
    padding: 5px 0 !important;
}

.modal-dialog.posterDirectoryTablePopUp .onlineDot {
    height: 7px;
    width: 7px;
    background-color: #888;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-left: 3px;
}

.modal-dialog.posterDirectoryTablePopUp .offlineDot {
    height: 7px;
    width: 7px;
    background-color: #0a9e00;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    margin-left: 3px;
}

.modal-dialog.posterDirectoryTablePopUp span.clientLogin {
    margin-right: 6px;
    height: 100%;
}

.modal-dialog.posterDirectoryTablePopUp .serachBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    padding-bottom: 25px;
}

.modal-dialog.posterDirectoryTablePopUp .serachBar input[type='text'] {
    width: 92%;
    resize: none;
    padding: 4px 10px 4px 10px;
    border: 1px solid #dce0e4 !important;
    border-radius: 0.25rem;
    outline: 0;
    font-size: 13px !important;
    color: #888f96 !important;
    font-family: 'SegoeUI-Semibold' !important;
    line-height: 20px;
}

.modal-dialog.posterDirectoryTablePopUp .serachBar img {
    max-width: 100%;
    height: 30px;
}

.modal-dialog.posterDirectoryTablePopUp h5.modal-title {
    font-size: 18px;
}

.modal-dialog.posterDirectoryTablePopUp button.close {
    padding: 5px 15px !important;
}

.modal-dialog.posterDirectoryTablePopUp .MuiTable-root {
    border: 1px solid #dce0e4 !important;
    border-radius: 10px !important;
}

.modal-dialog.posterDirectoryTablePopUp .MuiTableRow-root {
    vertical-align: sub !important;
}

.modal-dialog.posterDirectoryTablePopUp .MuiTableContainer-root {
    width: 100%;
    overflow-x: auto;
    min-height: 165px;
    max-height: 310px;
    overflow-y: auto;
}

.modal-dialog.posterDirectoryTablePopUp .MuiTableContainer-root::-webkit-scrollbar {
    width: 5px;
    height: 4px;
    border-radius: 50px;
    background-color: #e5e5e5;
    height: 0;
    border-radius: 16px;
    width: 5px;
}

.modal-dialog.posterDirectoryTablePopUp .MuiTableContainer-root::-webkit-scrollbar-thumb {
    background: #a0a0a0;
    height: 0;
    border-radius: 50px;
    width: 5px;
}

.modal-dialog.posterDirectoryTablePopUp .modal-dialog.posterDirectoryTablePopUp button.close {
    padding: 5px 18px !important;
}

.modal-dialog.posterDirectoryTablePopUp {
    transform: translate(-50%, -50%) !important;
    position: absolute;
    left: 50%;
    top: 50%;
}

.modal-dialog.posterDirectoryTablePopUp .MuiTableContainer-root {
    border-radius: 7px !important;
}

th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignLeft {
    border-bottom: 0px;
    border-top: 0px;
    padding: 0 !important;
    vertical-align: middle;
}

.modal-dialog.posterDirectoryTablePopUp .modal-content {
    min-height: 200px;
    max-height: 475px;
    background: #fff;
    border: none !important;
}

@media (max-width: 1440px) {
    .modal-dialog.posterDirectoryTablePopUp .modal-content {
        height: auto;
        background: #fff;
    }
}

@media screen and (max-width: 992px), (max-height: 550px) {
    .modal-dialog.posterDirectoryTablePopUp {
        max-width: 95%;
        height: auto;
    }

    .modal-dialog.posterDirectoryTablePopUp table.MuiTable-root.posterDirectorTable {
        width: 646px;
    }

    .modal-dialog.posterDirectoryTablePopUp {
        min-width: 620px;
    }
}

@media (max-width: 640px) {
    .modal-dialog.posterDirectoryTablePopUp {
        left: 48.5%;
    }

    .modal-dialog.posterDirectoryTablePopUp {
        min-width: 92%;
        height: auto;
    }

    h5.modal-title {
        line-height: 20px !important;
    }
}

@media (max-width: 520px) {
    .modal-dialog.knowledge-contetnt-popup.knowledge-in-detail.discussion-hr-popup.votingPopup {
        max-width: 95% !important;
    }

    .modal-dialog.knowledge-contetnt-popup.knowledge-in-detail.discussion-hr-popup.votingPopup .title {
        width: 100% !important;
        flex: 0 0 100% !important;
        padding-bottom: 10px;
    }

    .modal-dialog.knowledge-contetnt-popup.knowledge-in-detail.discussion-hr-popup.votingPopup .author-input {
        width: 100% !important;
        max-width: 100% !important;
        flex: 0 0 100% !important;
        padding-bottom: 0px;
    }

    .modal-dialog.knowledge-contetnt-popup.knowledge-in-detail.discussion-hr-popup.votingPopup h5.modal-title {
        display: flex !important;
        justify-content: space-between;
        width: 100%;
        flex-wrap: wrap;
    }

    .modal-dialog.knowledge-contetnt-popup.knowledge-in-detail.discussion-hr-popup.votingPopup .title-wrapper {
        justify-content: flex-start !important;
        flex-wrap: wrap;
        padding-bottom: 10px !important;
    }

    .modal-dialog.knowledge-contetnt-popup.knowledge-in-detail.discussion-hr-popup.votingPopup .edited-post-wrapper {
        padding-bottom: 0px !important;
        flex-wrap: wrap;
    }

    .discussion-hr-popup .author-input label {
        padding-left: 0px !important;
    }

    .discussion-hr-popup .author-input span {
        margin-left: 0px !important;
    }

    svg#SvgjsSvg1076 {
        width: 300px !important;
        max-width: 100% !important;
    }

    .apexcharts-text tspan {
        font-family: inherit;
        font-size: 10px !important;
    }

    .modal-dialog.posterDirectoryTablePopUp .modal-content {
        height: auto;
        background: #fff;
    }
}

@media (max-width: 414px) {
    .knowledgeContetntPopup .btnWrappwer {
        justify-content: center !important;
    }

    button.saveBtn.profileBtn.btn.btn-secondary {
        margin-left: 12px;
    }
}

@media (max-width: 375px) {

    .voteRemoveBtn,
    .ChangeVoteBtn {
        width: 100%;
    }

    .voteButtonWrapper {
        flex-direction: column;
    }

    .voteKeepBtn {
        margin-left: 0;
        width: 100%;
        margin-top: 10px;
    }
}

@media (max-width: 480px) {
    svg#SvgjsSvg1451 {
        overflow: initial !important;
    }
}

@media (max-width: 1300px) {
    table.MuiTable-root.posterDirectorTable {
        width: 800px !important;
    }
}

.modal-dialog.posterDirectoryTablePopUp tr.MuiTableRow-root.MuiTableRow-head {
    position: sticky;
    top: 0px;
    z-index: 0;
}

.modal-dialog.posterDirectoryTablePopUp .MuiTableContainer-root table {
    border: 1px solid #dce0e4;
    border-top: 0px;
    border-bottom: 0px;
    margin-bottom: 0px;
}

.table-avatar-icon {
    float: left;
    height: 100%;
    width: 22px;
    margin-right: 6px;
}

.poster-member-icon {
    float: left;
    margin-right: 6px;
    height: 22px;
    width: 22px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.poster-member-initials {
    font-family: 'SegoeUI-Semibold';
    font-size: 10px;
    cursor: pointer;
}