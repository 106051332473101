.expandable-radio-button {
    margin-bottom: 0.5rem;

    .Mui-checked {
        color: #535bd6 !important;
    }

    .MuiRadio-root:hover {
        background-color: transparent !important;
    }

    label {
        font-size: 14px;
        margin-bottom: 0;
    }

    .MuiFormControlLabel-label {
        font-size: 14px;
        font-family: 'SegoeUI';
    }
}
