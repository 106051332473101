.my-wallet {
    th.sortable {
        cursor: pointer;
    }

    .credit-info {
        margin: 15px 0;
    }

    .total-credits div {
        margin-left: 10px;
    }

    .money-cell {
        float: right;
    }

    .link {
        cursor: pointer;
        color: #545cd8;
        text-decoration: underline;
    }

    .react-bootstrap-table {
        margin-top: 10px;
        overflow: auto;

        .tooltip-content {
            opacity: 1;
        }

        .gift-card-tooltip {
            padding: 1.5em
        }

        .info-tooltip .tooltip-content {

            ul,
            ol {
                font-size: 13px;
                font-family: 'SegoeUI';
                color: #525a64;
                margin-left: -15px;
                margin-bottom: 0;
            }

            p {
                margin-bottom: 0.1em;
            }

            li {
                line-height: initial;
            }
        }
    }

    .react-bootstrap-table-pagination {
        flex-wrap: nowrap !important;
    }

    .font-semibold {
        font-family: 'SegoeUI-Semibold';
    }

    .credit-amount {
        font-family: 'SegoeUI-Semibold';
    }

    .credit-type-name {
        min-width: 150px;
        display: inline-block;
    }

    tbody .selected {
        background-color: #e6e8ff;
    }

    .wallet-nf {
        position: absolute;
        right: -1.5em;
        top: -1em;

        span {
            display: inline-block;
        }
    }

    .relative-container {
        position: relative;
    }
}

@media (max-width: 768px) {
    .my-wallet {
        .card-header {
            display: flex;
            text-align: start;
        }
    }
}