.btnOuterLine.noBorder {
    max-width: 80%;
    padding-right: 0;
    background: transparent !important;
    padding-top: 0;
    margin-top: -2px;
    height: 40px;
}

.disable {
    pointer-events: none;
}

.selected-content {
    background: #f0fff1;
}

.content-loader {
    min-height: 60px;
}

.content-tile {
    border: 1px solid #dce0e4;
    padding: 15px 13px 9px 19px;
    border-radius: 0.25rem;
    margin-bottom: 30px;
    width: 358px !important;
    margin-left: 15px;
    margin-right: 9px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .intro-text-readonly {
        color: #888f96 !important;
    }

    .item_even {
        margin-bottom: 0px;
    }

    .productNameImageWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .productNameImageWrapper h6 {
        font-size: 18px !important;
        margin-bottom: 0 !important;
    }

    .productNameImageWrapper.modalWrapper {
        display: flex;
        align-items: center;
    }

    p.date_author {
        margin-bottom: 0;
        font-size: 14px;
        color: #7b7b7b;
        font-family: 'SegoeUI';
        font-weight: 400;
    }

    p.text-desc {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        color: #000;
        display: -webkit-box;
        font-family: SegoeUI;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5em;
        margin-bottom: 0 !important;
        max-height: 3em;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .badgeButtonwrapper {
        display: flex;
        align-items: center;
        position: relative;
    }

    .badgeCollections.badgewrapper {
        margin-top: 30px;
    }

    .badgeCollections.badgewrapper span.productBadge.badge.bg-secondary {
        padding: 2px 4.71px 0px 4.71px;
        height: 16px;
    }

    .badgeCollections span.productBadge.badge.bg-secondary {
        padding: 2px 4px;
        height: 16px;
        border-top: 2px solid #aaaaaa;
        background: transparent !important;
    }

    .badgeCollections {
        width: 60%;
        min-height: 40px;
        padding-top: 5px;
    }

    .productBadge {
        margin-top: 34px;
        background: transparent;
        color: #000000;
        border: 1px solid #000000;
        font-size: 8.6px;
        padding: 3px 4px 2px 4px;
        margin-right: 5px;
        margin-top: 0 !important;
        font-family: 'SegoeUI-Bold';
    }

    .saveExtra .saveMySelfBtn span {
        display: block;
        line-height: 16px;
        font-size: 14px;
    }

    .saveExtra {
        width: 40%;
        display: flex;
        align-content: space-between;
        flex-direction: column-reverse;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .saveExtra.remove {
        margin-top: 5px;
    }

    .disabled-saveMySelfBtn {
        background-color: #919191 !important;

        button {
            background-color: #919191 !important;
        }
    }

    .saveMySelfBtn {
        border: 1px solid #888f96 !important;
        font-size: 14px !important;
        padding: 1px 10px 5px 6px !important;
        text-align: right;
        max-width: 100%;
        width: 100%;
        background: #535bd6 !important;
        margin-top: 0px;
        line-height: 17px;
        font-size: 600 !important;
        font-family: 'SegoeUI-Semibold';
    }

    .btnOuterLine.noBorder {
        max-width: 80%;
        padding-right: 0;
        background: transparent !important;
        padding-top: 0;
        margin-top: -2px;
    }

    .btnOuterLine.noBorder button.saveMySelfBtn.btn.btn-secondary {
        background: transparent !important;
        color: #535bd6 !important;
        border: 0 !important;
        padding: 0 !important;
    }

    .btnOuterLine {
        background: #535bd6;
        padding: 5px;
        border-radius: 6px;
        max-width: 88px;
        width: 100%;
        height: 50px;
        border-radius: 0.25rem;
        margin-left: auto;
        margin-top: -2px;
    }

    span.saveExtraText {
        margin-bottom: -4px;
    }

    .saveExtraText {
        font-size: 14px;
        font-family: 'SegoeUI';
        font-weight: 400;
        line-height: 26.64px;
        color: #000000;
        margin-left: auto;
        display: table;
    }

    .file-icon {
        color: #545cd8;
        height: 25px;
        width: 20px;
    }

    a.open-valuation-product {
        color: #535bd6;
        font-size: 14px;
        font-style: italic;
        font-family: 'SegoeUI';
        font-weight: 400;
        text-decoration: none;
    }

    .action-button-wrapper {
        display: flex;
        padding-left: 5px;

        .content-level {
            margin-left: 0.5em;
            margin-right: 0.3em;
            align-self: center;
        }

        .select-button {
            height: 25px;
            border: none;
            padding: 1px 5px 5px 5px;
            background: #535bd6;
        }

        :not(:first-child) {
            margin-left: 5px;
        }
    }

    .group-order-nf {
        position: absolute;
        right: -0.5em;
        top: -0.5em;
    }

    @media (max-width: 1700px) {
        .badgeButtonwrapper {
            margin-top: 0;
            position: relative;
        }

        .badgeCollections {
            width: 50%;
        }

        .productBadge {
            margin-top: 0;
        }

        .saveExtra {
            width: 50%;
            margin-top: -10px;
        }
    }

    @media (max-width: 1600px) {
        .open-valuation-product {
            font-size: 12px !important;
        }

        .productNameImageWrapper h6 {
            font-size: 16px !important;
        }

        .date_author {
            font-size: 12px !important;
        }

        .date_author {
            font-size: 11px !important;
        }

        .text-desc {
            font-size: 14px !important;
        }

        .saveMySelfBtn {
            font-size: 12px !important;
            padding: 1px 7px 3px 3px !important;
        }

        .saveMySelfBtn span {
            font-size: 12px !important;
        }

        .btnOuterLine {
            height: 45px;
            max-width: 70px;
            padding: 3px !important;
        }

        .saveExtraText {
            font-size: 12px !important;
        }
    }

    @media (max-width: 1366px) {
        .content-tile {
            padding: 10px 10px 9px 10px;
            margin-bottom: 15px;
            width: 290px !important;
            margin-left: 5px;
            margin-right: 5px;
        }

        .open-valuation-product {
            font-size: 11px !important;
        }

        .productNameImageWrapper h6 {
            font-size: 14px !important;
        }

        p.text-desc {
            font-size: 12px !important;
        }

        .badgeCollections {
            min-height: 35px;
        }

        .saveMySelfBtn {
            font-size: 12px !important;
            padding: 0px 10px 0px 3px !important;
        }

        .saveMySelfBtn span {
            font-size: 11px !important;
        }

        .btnOuterLine {
            height: 40px;
            max-width: 70px;
            padding: 3px !important;
        }

        .saveExtraText {
            font-size: 11px !important;
        }
    }
}

@media (max-width: 1366px) {
    .content-tile {
        padding: 10px 10px 9px 10px;
        margin-bottom: 15px;
        width: 290px !important;
        margin-left: 5px;
        margin-right: 5px;
    }
}
