.payment-dialog {
    .rodal-dialog {
        max-width: 600px !important;
    }

    .loader-container-inner {
        padding-bottom: 1rem;
    }

    h1 {
        color: #32325d;
        font-weight: 400;
        line-height: 50px;
        font-size: 40px;
        margin: 20px 0;
        padding: 0;
    }

    .right-align {
        justify-content: flex-end !important;
        margin-right: 0;
    }

    .label {
        font-family: 'SegoeUI-Semibold';
        font-size: 14px;
    }

    .checkbox-label {
        font-family: 'SegoeUI-Semibold';
        font-size: 14px;
        margin-left: 15px;
        margin-bottom: 13px !important;
        cursor: pointer;
        margin-bottom: 0;
    }

    .payment-footer {
        width: 100%;
        padding: 10px 16px 0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h6 {
            text-transform: capitalize;
        }
    }

    .highlight-row {
        background-color: #f8f9fa;
        border-bottom: 1px solid #dee2e6;
        margin-left: -15px;
        margin-right: -15px;
    }

    .amount-input-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;

        input {
            max-width: 300px;
            border: 1px solid #dce0e4 !important;
        }
    }

    .payment-dialog-header {
        color: black;
        margin-right: 5px;
    }

    .payment-dialog-header + b {
        color: #535bd6;
    }

    .modal-title {
        font-family: 'SegoeUI-Bold' !important;
        margin-bottom: 0 !important;
    }

    .wallet-text-ref {
        display: flex;

        .info-tooltip {
            align-self: center;
        }
    }

    .Checkout {
        margin: 0 auto;
        max-width: 800px;
        box-sizing: border-box;
        padding: 0 5px;
    }

    button {
        white-space: nowrap;
        border: 0;
        display: inline-block;
        height: 30px;
        line-height: 28px;
        padding: 0 10px;
        color: #fff;
        border-radius: 4px;
        font-size: 15px;
        text-decoration: none;
        text-transform: capitalize;

        i {
            margin-right: 5px;
        }
    }

    .checkbox-input {
        margin-left: -18px;
        margin-top: 2px;
        cursor: pointer;
        accent-color: #535bd6;
    }

    .saved-payment-info {
        display: flex;
        justify-content: space-between;
        border: 1px solid #dcdcdc !important;
        width: 100%;
        border-radius: 5px;
        margin: 0 0 17px -5px;
        padding: 3px;
        padding: 0 3px 4px 3px;

        .svg-inline--fa {
            align-self: center;
            padding-right: 15px;
        }
    }

    .saved-payment-info.selected {
        border: 2px solid #3ac47d !important;
    }

    .close-button {
        border: 1px solid;
        border-color: gray;
        background-color: white;
        color: gray;
    }

    .name-input,
    .StripeElement {
        display: block;
        margin: 8px 0 15px 0;
        max-width: 500px;
        font-size: 1em;
        border: 1px solid #dce0e4;
        border-radius: 0.25rem;
        height: 30px;
        padding: 7px 10px 7px 10px;
    }

    input::placeholder {
        color: #aab7c4;
    }

    .modal-footer {
        padding: 0 1rem 1rem 1rem;
        border: none;
        background-color: inherit;
    }

    .card-header-1 {
        display: flex;
        align-items: center;
        border-bottom-width: 1px;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 1.625rem;
        height: 3.5rem;
        justify-content: center;
        background-color: lightgray;
    }

    .credit-card-img {
        justify-content: center;
        margin-bottom: 1rem;
    }

    input::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgb(131, 124, 124);
        opacity: 1; /* Firefox */
    }

    input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgb(131, 124, 124);
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgb(131, 124, 124);
    }

    .discounted {
        margin: 0 15px;
        font-size: 28px;
        color: #d4b50f;
    }
    .checkout-modal {
        margin-top: 50px;
    }
    .wallet-option {
        display: flex;
        flex: 1;
        padding: 20px 0;
        margin-top: -16px;
        background: #3ac47d;
    }
    .wallet-text {
        margin-left: 30px;
        margin-top: 7px;
        color: white;
        font-weight: 600;
    }

    .default-div-image {
        display: flex;
        justify-content: flex-end;

        .default-image {
            max-width: 100px;
            margin: 3px 4px 5px 0px;
        }
    }

    .rebates-row {
        border-top: 1px solid #dee2e6;
        padding-top: 16px;

        .rebates-section {
            margin-left: 16px;
            width: 55%;

            > :not(:first-child) {
                margin-top: 5px;
            }
        }

        .rebates-item {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }

        .rebate-value {
            color: #3bc47e;
        }

        .rebate-label {
            width: 210px;
            display: flex;
            justify-content: space-between;
        }
    }

    .payment-due-title {
        display: flex;
        justify-content: space-between;
        min-width: 55%;
        font-family: 'SegoeUI-Bold';
    }

    .deposit-taken-title {
        font-family: 'SegoeUI';
        margin-left: 16px;
    }

    .deposit-taken-discount {
        font-family: 'SegoeUI';
    }

    .spacer {
        border-bottom: 1px solid #dee2e6;
        padding: 1px;
    }

    @media (max-width: 767px) {
        .row {
            justify-content: initial !important;
        }
    }

    @media (max-width: 715px) {
        .rebates-section {
            width: 100% !important;
            margin-right: 16px;
        }
    }

    @media (max-width: 650px) {
        .default-div-image {
            .default-image {
                margin: 3px 0px 5px 0px;
            }
        }

        .wallet-text-ref {
            .info-tooltip {
                margin-right: 15px;
            }
        }
        input {
            margin-right: 5px;
        }

        .checkbox-buttons {
            display: flex;
            justify-content: flex-end;

            :nth-child(2) {
                margin-right: 0 !important;
            }
        }

        .payment-footer {
            flex-direction: column;
            align-items: normal;
        }
    }

    @media (max-width: 575px) {
        .modal-footer {
            justify-content: flex-end !important;
        }
    }
}
