.discussion-hr-popup {
    max-width: 514px !important;
    width: 100%;
    background: #fff;
    margin: 0px;
    transform: translate(-50%, -50%) !important;
    position: absolute;
    left: 50%;
    top: 50%;

    .close {
        font-size: 30px;
    }

    .hidden-element {
        display: none !important;
    }

    width: 100% !important;

    .hover-tooltip {
        position: relative;
    }

    .modal-header {
        padding: 11px 16px 0px 0 !important;
        background: #fff;
        border-bottom: 0;

        h5.modal-title {
            display: none !important;
        }

        button.close {
            padding: 6px 6px 0px 0 !important;
        }
    }
}

.edit-height {
    .modal-content {
        height: 298px;
    }
}

@media (max-width: 514px) {
    .discussion-hr-popup {
        max-width: 95% !important;

        .tile-edit-btn-wrapper {
            flex-direction: column;
            align-items: flex-start;
        }

        .hover-tooltip {
            position: relative;
        }

        .click-wish-removal #tooltip .tooltip-label {
            max-width: 168px !important;
            font-size: 11px;
        }

        .click-wish-removal #tooltip.top .tooltip-arrow {
            left: 78%;
        }
    }
}

@media (max-width: 457px) {
    .discussion-hr-popup {
        .click-wish-removal #tooltip .tooltip-label {
            max-width: 140px !important;
            font-size: 11px;
        }

        .click-wish-removal #tooltip.top {
            margin-left: -76px;
            margin-top: -59px;
        }

        .click-wish-removal #tooltip.top .tooltip-arrow {
            left: 63%;
        }
    }
}

@media (max-width: 514px) {
    .discussion-hr-popup {
        max-width: 90% !important;
    }

    .edit-height {
        .modal-dialog {
            height: auto;
        }

        .modal-content {
            height: auto;
        }
    }
}
