.link-component-with-notification-flag {
    display: flex !important;

    .notification-flag {
        position: relative;
        top: -0.5em;
        right: 0.2em;
    }

}

.metismenu-container {
    .active {
        color: #909296 !important;
        font-family: 'SegoeUI' !important;
        font-size: 16px !important;
        font-weight: normal !important;
    }
}

.app-sidebar__heading {
    .active {
        color: #343a40 !important;
        font-family: 'SegoeUI-Bold' !important;
        font-size: 14px !important;
    }
}