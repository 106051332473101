.editable-table {
    fieldset {
        border: none;
    }

    @supports (-moz-appearance: none) {
        .MuiDataGrid-virtualScrollerContent {
            margin-bottom: 2px;
        }

        .MuiDataGrid-scrollbar {
            scrollbar-width: thin;
            scrollbar-color: #a0a0a0 #e5e5e5;
        }
    }

    .numeric-cell-edit {
        padding: 0 15px;
    }

    .MuiDataGrid-cell--editing {
        input[type='date'] {
            padding-left: 16px !important;
        }
    }

    .MuiDataGrid-row {
        min-height: 34px !important;
    }

    .MuiDataGrid-cell--editing {
        padding-top: 6px !important;
        padding-bottom: 6px !important;
        align-items: flex-start !important;
    }

    .MuiDataGrid-cellContent {
        min-height: 20px !important;
        white-space: wrap;
        line-height: normal;
    }

    .MuiDataGrid-cell {
        display: flex;
        align-items: center !important;
        line-height: normal !important;
    }

    .cell-text-input {
        padding-top: 4px;

        .Mui-error {
            background-image: URL("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right 16px top 2px;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

            textarea {
                padding-right: 24px !important;
            }
        }
    }

    .date-picker.MuiDataGrid-cell--editing {
        padding-right: 0;

        .Mui-error {
            background-image: URL("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right 16px top 4px !important;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

            input {
                padding-right: 0;
            }
        }
    }

    .MuiDataGrid-scrollbar {
        &::-webkit-scrollbar {
            height: 6px;
            background-color: #e5e5e5;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #a0a0a0 !important;
            height: 4px;
            width: 3px;
            border-radius: 5px;
        }

        &::-webkit-scrollbar {
            cursor: pointer;
            height: 6px;
            width: 6px;
            background-color: #e5e5e5;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #a0a0a0 !important;
            height: 6px;
            width: 6px;
            border-radius: 5px;
        }
    }

    .MuiDataGrid-virtualScroller {
        overflow: auto;
        margin-left: -2px;
    }

    .MuiSelect-select {
        padding-top: 0;
        padding-bottom: 0;
    }

    .expanded-text {
        padding-top: 7px;
    }

    .switcher-cell {
        align-items: center !important;
    }

    .MuiDataGrid-root {
        overflow: hidden;
    }

    .MuiDataGrid-footerContainer {
        font-family: 'SegoeUI-Semibold';
        color: #888f96;
        .MuiTablePagination-root {
            .MuiTablePagination-toolbar {
                .MuiTablePagination-selectLabel {
                    color: #888f96;
                    font-family: 'SegoeUI-Semibold';
                    margin: 0;
                }

                .MuiTablePagination-select {
                    font-size: 14px !important;
                }

                .MuiTablePagination-displayedRows {
                    font-size: 14px;
                    color: #888f96;
                    font-family: 'SegoeUI-Semibold';
                    margin: 0;
                }

                .MuiInputBase-root {
                    margin-right: 15px;
                }

                .MuiTablePagination-actions {
                    margin-left: 10px;
                }
            }
        }
    }
}
