.date-answer-field {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 10px 0 0 10px;

    .input-group-prepend {
        margin: 0 !important;

        .input-group-text {
            border: none;
            border-left: 1px solid #ced4da !important;
            border-radius: 0 5px 5px 0 !important;
        }
    }

    .input-group-text {
        cursor: pointer;
    }

    .date-picker-container {
        position: relative;
        float: left;
        padding-bottom: 0px !important;
        border: 1px solid #ced4da !important;
        border-radius: 5px !important;
        background: white;
    }

    .date-picker-container[aria-disabled='true'] {
        background: #e9ecef;
    }

    .date-input {
        height: 30px;
        width: 40px;
        visibility: hidden;
    }

    .react-datepicker__triangle {
        display: none;
    }

    .react-datepicker-wrapper {
        z-index: 0;

        .react-datepicker__input-container {
            input {
                border: none;
                width: 13ch;
                background: white;
                padding: 0 0.75rem;
            }

            input:disabled {
                background: #e9ecef;
            }

            input:focus-visible {
                outline: none !important;
            }
        }
    }

    .react-datepicker-popper {
        .react-datepicker__aria-live {
            display: none;
        }
    }
}
