.notification-flag {
    padding-inline: 0.25em;
    width: 20px;

    .notification-flag-badge {
        height: 20px !important;
        width: auto !important;
        border-radius: 20px;
        background-color: red;
        color: white;
        cursor: default;
        font-family: 'SegoeUI-Semibold';
    }
}

.notification-flag-placeholder {
    width: 1.75em;
}