.engagement-timeline{
    .body_inner_content{
        padding: 15px 0 0 0 !important;
    }

    .RRT__tab{
        font-size: 0.75rem;
    }

    .RRT__tab.RRT__tab--first.RRT__tab--selected:before {
        height: 3px;
    }
}