.referral-and-invitees {
    th.sortable {
        cursor: pointer;
    }

    .table thead tr {
        background-color: #dce0e4;
        color: #525a64;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
    }

    .table tbody td {
        color: #888f96;
        font-size: 13px;
        font-family: 'SegoeUI-Semibold';
    }

    .money-cell {
        float: right;
    }

    .react-bootstrap-table {
        margin-top: 10px;
        overflow: auto;

        span {
            display: initial;
            margin-bottom: auto;
        }

        span.cell-with-direct {
            display: flex;
            align-items: center;

            .direct-button {
                margin-left: 0.3rem;
            }
        }
    }

    .react-bootstrap-table-pagination {
        flex-wrap: nowrap !important;
    }

    .rp-ref-party-nf {
        position: absolute;
        right: -1.5em;
        top: -1em;

        span {
            display: inline-block;
        }
    }

    .relative-container {
        position: relative;
        width: fit-content;
    }
}

@media (max-width: 768px) {
    .referral-and-invitees {
        .card-header {
            display: flex;
            text-align: start;
        }
    }
}

@media (max-width: 767px) {
    .referal .row {
        flex-direction: column;

        .col-7 {
            max-width: 100%;
        }
    }
}
