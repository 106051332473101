.credit-explained-modal {
    .rodal-dialog {
        top: 20%;
    }

    .credit-explained-modal-body {
        padding-left: 20px;
    }

    .cancel-btn,
    .cancel-btn:hover {
        font-family: 'SegoeUI-Semibold';
        font-size: 15px !important;
        color: #919191;
        width: 70px;
        height: 29px;
        border: 1px solid #919191;
        background-color: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .cancel-btn {
        margin-bottom: 1em;
        margin-right: 1em !important;
    }

    button.cancel-btn.btn.btn-secondary {
        margin-left: auto;
    }
}
