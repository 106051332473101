// BOOTSTRAP 4 VARIABLES

@import 'components/bootstrap4/functions';
@import 'components/bootstrap4/variables';
@import 'components/bootstrap4/mixins';
@import '../../src/components/react-bootstrap-table-next/react-bootstrap-table2-filter/style/react-bootstrap-table2-filter.scss';
@import '../../src/components/react-bootstrap-table-next/react-bootstrap-table2-paginator/style/react-bootstrap-table2-paginator.scss';
// ArchitectUI Themes Variables

@import 'themes/layout-variables';

// // BOOTSTRAP 4 IMPORTS

@import 'components/bootstrap4/root';
@import 'components/bootstrap4/reboot';
@import 'components/bootstrap4/type';
@import 'components/bootstrap4/images';
@import 'components/bootstrap4/code';
@import 'components/bootstrap4/grid';
@import 'components/bootstrap4/tables';
@import 'components/bootstrap4/forms';
@import 'components/bootstrap4/buttons';
@import 'components/bootstrap4/transitions';
@import 'components/bootstrap4/dropdown';
@import 'components/bootstrap4/button-group';
@import 'components/bootstrap4/input-group';
@import 'components/bootstrap4/custom-forms';
@import 'components/bootstrap4/nav';
@import 'components/bootstrap4/navbar';
@import 'components/bootstrap4/card';
@import 'components/bootstrap4/breadcrumb';
@import 'components/bootstrap4/pagination';
@import 'components/bootstrap4/badge';
@import 'components/bootstrap4/jumbotron';
@import 'components/bootstrap4/alert';
@import 'components/bootstrap4/progress';
@import 'components/bootstrap4/media';
@import 'components/bootstrap4/list-group';
@import 'components/bootstrap4/close';
@import 'components/bootstrap4/toasts';
@import 'components/bootstrap4/modal';
@import 'components/bootstrap4/tooltip';
@import 'components/bootstrap4/popover';
@import 'components/bootstrap4/carousel';
@import 'components/bootstrap4/spinners';
@import 'components/bootstrap4/utilities';
@import 'components/bootstrap4/print';
// // LAYOUT

@import 'layout/layout';

// // UTILS

@import 'utils/helpers';
@import 'utils/backgrounds';
@import 'utils/animate';
@import 'utils/comps-animations';

// // ELEMENTS

@import 'elements/buttons';
@import 'elements/dropdown';
@import 'elements/navs';
@import 'elements/badges';
@import 'elements/cards';
@import 'elements/tabs';
@import 'elements/accordions';
@import 'elements/modals';
@import 'elements/loaders';
@import 'elements/progressbar';
@import 'elements/timeline';
@import 'elements/listgroup';
@import 'elements/forms';
@import 'elements/pagination';
@import 'elements/chat';
@import 'elements/forms-wizard';

// // DASHBOARD BOXES

@import 'widgets/chart-boxes/chart-boxes';
@import 'widgets/content-boxes/content-boxes';
@import 'widgets/profile-boxes/profile-boxes';

// // PAGES

@import 'pages/userpages';

// // Applications

@import 'applications/applications-base';

// // COMPONENTS

// // Perfect Scrollbar
@import 'components/perfect-scrollbar/perfect-scrollbar';

// // RC Tabs
@import 'components/rc-tabs/rc-tabs';

// // Loading Buttons
@import 'components/loading-buttons/loading-buttons';

// // Hamburger Button
@import 'components/hamburgers/hamburgers';

// Drawer
@import 'components/drawer/drawer';

// // Notifications
@import 'components/notifications/sweetalerts';
@import 'components/notifications/toastify';

// // Modals
@import 'components/modals/rodal';

// // Progress Bar
@import 'components/progress-bar/progress-bar';

// // Slick Carousel
@import 'components/slick-carousel/slick/slick';

// // Block loading
@import 'components/block-loading/block-loading';

// // Popovers & Tooltips
@import 'components/popovers-tooltips/popovers-tooltips';

// // Sticky Elements
@import 'components/sticky-elements/sticky-elements';

// // Calendar
@import 'components/calendar/calendar';

// // Splitters
@import 'components/splitters/splitters';

// // Tree View
// @import "components/tree-view/tree-view";

// // Tables
@import 'components/tables/tables';

// // Icons
@import './components/icons/_linearicons.css';
@import '../../node_modules/pe7-icon/dist/dist/pe-icon-7-stroke.min.css';

// // FORMS

@import 'components/forms/datepicker/datepicker';
@import 'components/forms/rangeslider/rangeslider';
@import 'components/forms/multiselect/multiselect';
@import 'components/forms/wysiwyg/wysiwyg';
@import 'components/forms/toggle-switch/switch';
@import 'components/forms/dropzone/dropzone';
@import 'components/forms/typeahead/typeahead';

// DEMO

@import 'demo-ui/demo';

// Responsive

@import 'layout/responsive/responsive-base';

@import 'components/checkout-form/checkout-form';

// SCSS FROM APP FOLDER
@import './fonts/style.css';
@import '../components/Subscriptions/Subscriptions.scss';
@import '../components/NdaModal/NdaModal.scss';
@import '../components/Upload-Modal/modal.scss';
@import './view-report/view-report.scss';
@import './paid-report/paid-report.scss';
@import './free-report/free-report.scss';
@import '../components/DynamicControl/DynamicControl.scss';
@import '../components/FormComponents/CurrencyInput/CurrencyInput.scss';
@import '../components/DynamicControlWrapper/DynamicControlWrapper.scss';
@import '../components/ReportCard/ReportCard.scss';
@import '../components/ReportsLayout/ReportHeader/ReportHeader.scss';
@import '../components/ReportsLayout/ReportHeader-new/ReportHeader-new';
@import '../components/NegativeValueModal/NegativeValueModal.scss';
@import '../components/ComingSoon/ComingSoon.scss';
@import '../components/UserOnboarding/UserOnboarding.scss';
@import '../components/CreditCard/CreditCard.scss';
@import '../components/TableHeader/TableHeader.scss';
@import '../components/Modal/Modal.scss';
@import './pages/admin-control.scss';
@import '../containers/Dashboard/AdminDashboard/AdminDashboard.scss';
@import '../components/DynamicForm/DynamicForm.scss';
@import '../containers/UserPages/Login/Modals/RegisterPracticeWizardModal';
@import '../containers/Associates/Associates.scss';
@import '../components/SideBar/SideBar';
@import '../components/Layout/AppNav/VerticalNavWrapper.scss';
@import '../containers/Practices/Practice.scss';
@import '../containers/UserPages/Profile/Profile.scss';
@import '../components/ReferralDocStep/ReferralDocStep.scss';
@import '../containers/Admin/Pricing/PricingComponent.scss';
@import '../containers/Dashboard/PrimaryUserDashboard/Practices/PrimaryUserPractises.scss';
@import '../components/FreemiumReportNegativeEBITDAModal/FreemiumReportNegativeEBITDAModal.scss';
@import '../components/FreemiumReportNegativeValuationModal/FreemiumReportNegativeValuationModal.scss';
@import '../containers/Referal/Referal.scss';
@import '../containers/PracticeManagementGroups/PracticeManagement.scss';
@import '../containers/PracticeManagementGroups/PracticeManagementPMG/PracticeManagementPMG.scss';
@import '../containers/UserHome/UserHome.scss';
@import '../containers/PracticeBrokerageServices/PracticeBrokerageServices.scss';
@import '../containers/PracticeValuationReports/PracticeValuationReports.scss';
@import '../containers/MyGroups/MyGroups.scss';
@import '../assets/custom-style.scss';
@import '../containers/UserPages/Register/RegisterationForm/Register.scss';
@import "react-widgets/styles.css";