.charity-view-popup {
    .rodal-close {
        cursor: pointer;
        height: 16px;
        position: absolute;
        right: 16px;
        top: 16px;
        width: 16px;
    }

    .donor-name-span {
        color: #888f96;
        font-size: 13px;
    }

    .col-md-6:not(:last-child),
    .col-sm-6:not(:last-child) {
        padding-right: 0 !important;
    }

    .summary {
        .card {
            box-shadow: 0 -1.46875rem 2.1875rem rgba(8, 10, 37, 0.03),
                0 0.9375rem 1.40625rem rgba(8, 10, 37, 0.03),
                0 0.25rem 0.53125rem rgba(8, 10, 37, 0.05),
                0 0.125rem 0.1875rem rgba(8, 10, 37, 0.03);
        }

        .card-body {
            min-height: 260px;
        }
    }

    .logo-container {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
        margin-top: -1rem;
        margin-right: -1rem;
        margin-left: -1rem;

        max-width: 1000px;
        max-height: 231px;

        .logo-image {
            width: 100%;
            max-width: 1000px;
            max-height: 231px;
            object-fit: contain;
        }
    }

    .link-text {
        color: #545cd8;
        cursor: pointer;
    }

    .form-group {
        margin-bottom: 1rem !important;
    }

    .card-header {
        text-align: initial;
        color: black;
    }

    .info-label {
        font-family: SegoeUI-Bold;
    }

    .info-row {
        text-align: start;
        font-size: 16px;
        margin-bottom: 1.7rem;

        .contact-charity-admin {
            max-width: 50%;
            display: flex;
            align-items: center;
            margin-top: -0.5rem;

            span {
                max-width: 130%;
            }

            .direct-button {
                margin-left: 0.4rem;
            }
        }
    }

    .total-donation-information {
        text-align: start;

        .field-label {
            font-size: 16px;
            display: flex;
            justify-content: space-between;
        }

        .field-value {
            display: flex;
            font-size: 16px;
            color: #545cd8;
            font-family: SegoeUI-Bold;
        }

        @media (max-width: 767px) {
            .donation-info-field {
                display: flex;

                .field-label {
                    width: 50%;
                    display: initial;
                }

                .field-value {
                    padding-left: 15px;
                }
            }
        }
    }

    @media (max-width: 575px) {
        .contact-charity-admin-wrapper {
            margin-top: 0.8rem;
        }
    }

    @media (max-width: 767px) {
        .summary-section:not(:first-child) {
            margin-top: 1rem;
        }

        .card-body {
            min-height: initial;
        }
    }

    @media (max-width: 1366px) {
        .rodal-dialog {
            left: 10%;
        }
    }

    .accounts-column {
        display: flex;
        flex-direction: column;
    }
}