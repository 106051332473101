.uploaded-file-tile {
    font-family: 'SegoeUI-Semibold';
    font-size: 14px;
    color: #888f96;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .file-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 250px;
    }

    .delete-button {
        padding: 0 0.5rem;
        cursor: pointer;
    }

    .file-info {
        display: flex;
        gap: 0.5rem;
    }
}
