.modal-invite-new-buyer {
    .rodal-dialog {
        top: 15%;
    }

    .btn-cancel {
        background-color: #fff;
        color: #919191;
        border-color: #919191;
        margin-right: 1rem;
    }

    .modal-header {
        font-size: 18px;
        font-family: 'SegoeUI-SemiBold';
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 17px;
        margin-bottom: 0;
        line-height: 1.5;
    }

    .form-group {
        margin-bottom: 0;
    }

    .field-label {
        font-size: 14px;
        font-family: 'SegoeUI-Semibold';
    }

    .button-container {
        text-align: end;
    }

    select {
        box-shadow: none !important;
        color: #495057;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;
        font-family: 'SegoeUI';
        padding-left: 8px;
    }

    .row {
        margin-bottom: 20px;
    }
}
