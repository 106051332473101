.close {
    float: right;
    @include font-size($close-font-size);
    font-weight: $close-font-weight;
    line-height: 1;
    color: $close-color;
    text-shadow: $close-text-shadow;
    opacity: 0.5;

    @include hover {
        color: $close-color;
        text-decoration: none;
    }

    &:not(:disabled):not(.disabled) {
        @include hover-focus {
            opacity: 0.75;
        }
    }
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    appearance: none;
}

a.close.disabled {
    pointer-events: none;
}