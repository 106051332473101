.app-create-new-discussion {
    transform: translate(-50%, -50%) !important;
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 498px !important;
    width: 100% !important;
    height: auto;
    background-color: #ffffff;

    textarea::-webkit-scrollbar {
        width: 6px;
        background-color: #c1c1c157;
    }

    textarea::-webkit-scrollbar-thumb {
        background-color: #a0a0a0;
        border-radius: 5px;
    }

    h5.modal-title {
        text-transform: none !important;
        font-size: 20px !important;
        font-family: 'SegoeUI-Semibold' !important;
        display: inline-block;
        color: #000000;
        text-align: center !important;
        padding-top: 9px;
        width: 100%;
    }

    .modal-header {
        background: #fff;
        border-bottom: 0;
        padding-top: 11px;
    }

    button.close {
        font-family: 'SegoeUI-Semibold';
        font-size: 30px;
        padding: 2px 6px 0px 0 !important;
    }

    label {
        color: #525a64 !important;
        font-size: 13px !important;
        font-family: 'SegoeUI-Semibold';
        margin-bottom: 6px;
    }

    .create-new-discussion-body {
        padding: 7px 28px 30.3px 28px;
    }

    .author-posted-wrapper input {
        width: 100%;
        border: 1px solid #dce0e4 !important;
        border-radius: 0.25rem;
        outline: 0;
        padding: 2px 11px 4px 10px !important;
        height: 30px !important;
        font-size: 13px !important;
        color: #888f96 !important;
        margin-bottom: 0px;
        font-family: 'SegoeUI-Semibold' !important;
    }

    .author-input {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }

    .originally-based-col {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-top: 5px;

        textarea {
            resize: none;
            padding: 8px 10px;
            border: 1px solid #dce0e4 !important;
            border-radius: 0.25rem;
            outline: 0;
            font-size: 13px !important;
            color: #888f96 !important;
            font-family: 'SegoeUI-Semibold' !important;
            height: 42px !important;
        }
    }

    .text-count {
        text-align: right;
        padding-bottom: 9px;
        font-size: 13px !important;
        color: #888f96 !important;
        font-family: 'SegoeUI-Semibold' !important;
        padding-top: 1px;
    }

    .btn-wrapper {
        text-align: center;
        padding-top: 22px;
        display: flex;
        justify-content: center;
        width: 100%;

        .cancel-btn {
            color: #919191;
            height: 29px;
            border: 1px solid #919191;
            background-color: transparent;
            max-width: 70px;
            width: 100%;
            padding: 2px 5px 5px 5px;
            margin-right: 10px;
        }

        .discussion-btn {
            background: #535bd6 !important;
            border-color: #535bd6 !important;
            color: #fff;
            height: 29px;
            padding: 2px 5px 5px 5px;
            width: 141px;
        }
    }

    .modal-content {
        border-bottom: 0;
    }

    .form-group {
        width: 75%;
        margin-bottom: 0 !important;
    }

    .form-control:focus {
        box-shadow: none !important;
    }

    .form-control.is-invalid {
        border: 1px solid red !important;
        border-radius: 0.25rem;
        border-color: #dc3545;
        padding-right: calc(1.5em + 0.75rem);
        background-image: URL("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: center right 5px;
        background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    .invalid-feedback {
        display: none !important;
    }
}