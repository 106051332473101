.request-content-popup {
    background: #0000007d;

    .requested-content-list {
        .body_white_box {
            margin-bottom: 0;
            box-shadow: none;
        }

        .modal-title {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        button {
            padding: 1px 10px 3px 10px;
            height: 27px;
            margin-right: 30px;
            background: #535bd6 !important;
            border-color: #535bd6 !important;
        }
    }

    .rodal-dialog {
        top: 25%;
    }

    .request-content-body.modal-body {
        padding-bottom: 28px;
        padding-right: 22px;
    }

    label {
        color: #525a64 !important;
        font-family: SegoeUI-Semibold;
        font-size: 13px;
    }
}

.new-request-mode {
    @media (max-width: 1199px) {
        .rodal-dialog {
            width: 500px !important;
        }
    }

    @media (max-width: 800px) {
        .rodal-dialog {
            width: 80% !important;
        }
    }
}
